import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tiara_minor() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>газопылевая туманность</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "imgs": [{ "short": "tiara_minor_500", "big": "tiara_minor" }, {
            "short": "bs_wiki_republic_of_tiara_minor_map_ru",
            "big": "bs_wiki_oecumene_map_ru"
        }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <a data-fancybox="true" data-caption="" href={imgs["tiara_minor_2"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Туманность Малой короны" src={imgs["tiara_minor_2_500"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify"><span
                    className="strong">Туманность Малой короны</span> – она же Актэф Кубарра с <TextInlineLink
                        name={"bs_lang_alamarsie"} text={"языка аламарси"} /> (буквально – Плечи
                    великана или Корона великана, зависит от контекста). Заселена относительно недавно,
                    уже после открытия световых перелетов. Здесь повсюду правит агатонская модель
                    демократии (да-да, их больше одной) и почти вся туманность – часть сектора <TextInlineLink
                        name={"bs_space_vikoveera"} text={"Виковира"} /> со столицей на Агатоне.
                    Большинство миров тут полунезависимые планеты-сателлиты Агатона.</p>

                <blockquote><p className="card-text text-justify">«А где же Туманность <span
                    className="strong">Большой</span> короны?», - спросишь ты. «Без понятия», - отвечу
                    тебе я. Уточни у аламарси, это ведь они ее так назвали.</p><small><TextInlineLink
                        name={"bs_char_ursula_adams"} />, <TextInlineLink
                            name={"bs_universe_bird_of_passage_guide_to_milky_way"} /></small></blockquote>
            </>
    }
}
