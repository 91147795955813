import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Jasmine_haervany() {
    return {
        "is_not_ready": false,
        "name": "Ясмин Харвани",
        "fullname": <span>Ясмин Харвани</span>,
        "born": <span>37 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>36</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"} /> в <TextInlineLink
            name={"bs_space_arthuri_belt"} text={"Поясе Артура"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианка"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span><TextInlineLink name={"bs_state_cydonian_adimensional_communications"} /></span>,
        "imgs": [
            { "short": "bs_char_jasmine_haervany_1_jpg_500", "big": "bs_char_jasmine_haervany_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "resume":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">О героине</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ясмин</span> родилась и провела детство на Киренаике, не покидая родную
                    планету ни на минуту. Здесь она получила специальность младшего корабельного инженера и поступила
                    на работу в КНК. В первую же вахту девушку направили в командировку на другой конец страны,
                    на <TextInlineLink name={"bs_planet_valoria"} text={"Валорию"} />.
                </p>
                <p className="card-text text-justify">
                    Родители были против такого назначения, справедливо считая, что <TextInlineLink
                        name={"bs_space_free_fire_zone"} /> - чересчур опасное место для юной особы и подобная работа
                    может плохо закончиться. Ясмин, всю жизнь мечтавшая о звездах, не послушала их. К тому же, ей
                    нравилось копаться в железках и чинить старинный хлам, которого на улицах Киренаики болталось будь
                    здоров.
                </p>
                <p className="card-text text-justify">
                    Иронично, что работая в КНК и занимаясь фактически обслуживанием <TextInlineLink
                        name={"bs_tech_grn"} text={"ИнтерСети"} />, девушка не имела ни малейшего опыта в работе с
                    с  <TextInlineLink name={"bs_tech_abspace"} text={"Надпространством"} /> и технологиями доступа к нему.
                </p>

                <h3 className="title-bg">Занимательные факты</h3>
                <p className="card-text text-justify">
                    Изначально вместо Ясмин планировалось аж три (3!) героя.
                    Ради спасения читательской психики двумя пришлось пожертвовать, как и правдоподобностью сцены,
                    в которой происходит знакомство Ивара и Ясмин. Но об этом уже после выхода Осени.
                </p>
            </>
    }
}
