import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Browdy_adam() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Адам Броуди",
        "fullname": <span>Адам <small>«Спрут», «Этот кочевник»</small> Броуди</span>,
        "born": <span>46 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>45</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"улей «Анираадха»"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>не любит о них говорить</span>,
        "relatives": <span>живых не осталось</span>,
        "work": <span>частный рекламатор, <strike>пират</strike>, <strike>контрабандист</strike></span>,
        "imgs": [
            { "short": "bs_char_browdy_adam_1_png_500", "big": "bs_char_browdy_adam_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <p>(однорукий пилот)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="text-justify"><span className="strong">Адам</span>, как и большинство аламарси,
                    родился на <TextInlineLink name={"bs_tech_hiveship"} text={"корабле-улье"} />. Первые годы
                    его жизни прошли в тесных полутемных коридорах нижних палуб, где обитали низшие слои общества
                    «Анираадхи» (с аламарси: «Идущий без препятстий»). Мальчик провел бы там большую часть жизни, но
                    ему повезло: тесты ДНК показали, что у него склонности к пилотированию и точным наукам.
                    Благодаря этому Адама определили в пилоты, хотя он и родился среди камал базу (с аламарси:
                    «обитающие снизу»), в семье механиков (отец тоже когда-то проходил обучение на пилота, но не
                    сдал экзамены), и не мог претендовать на такое важное и ответственное занятие. Пусть в обществе
                    аламарси и царит истинный социализм, нехватка ресурсов все-таки создает на большинстве ульев
                    нечто вроде кастовой или клановой системы, где часть людей с рождения лишены шансов улучшить
                    свое положение.</p>
                <p className="card-text text-justify">Долгое, местами крайне трудное обучение и постоянные инъекции
                    ноотропов с раннего детства (на этапе формирования нервной системы) сделали из Адама одного из
                    «тех самых» аламарси: пилота с превосходной реакцией, способного орудовать штурвалом быстрее,
                    чем иные люди думают, предсказывать действия противника даже лучше, чем он сам и рассчитывать
                    траектории полета как живой компьютер.</p>
                <p className="card-text text-justify">Но стать героем своего флота ему было не суждено: Адаму
                    стукнуло девять, когда «Анираадха» попал под массированный налет синдиката <TextInlineLink
                        name={"bs_comp_feathered_serpent"} text={"Пернатый змей"} />. Весь их флот или
                    разбили, или взяли на абордаж, а большая часть сопротивлявшихся была убита, в том числе его
                    мать. Сам Адам вместе с отцом попал в плен, но так как пиратов интересовали лишь драгоценности
                    командного состава, ресурсы и редкие артефакты (вроде бумажных дневников древних
                    капитанов-аламарси), то обычным трудягам ничего не грозило. Поэтому Адам мог переждать абордаж и
                    вернуться к своему обучению, но случилось самое неожиданное и страшное событие в его жизни: отец
                    рассказал пиратам о талантах Адама в обмен на какие-то гроши.</p>
                <p className="card-text text-justify"><TextInlineLink name={"bs_people_samborians"}
                    text={"Самборианцы"} /> забрали девятилетку и
                    вырастили его
                    на <TextInlineLink name={"bs_planet_khal_vernon"} text={"Хал-Верноне"} />. Он стал частью большой
                    пиратской шайки барона Ирата Данталиана, главая Вернонского крыла <TextInlineLink
                        name={"bs_comp_free_trade_league"} text={"Лиги свободной торговли"} />. Там ему и
                    еще нескольким детям-аламарси продолжили давать ноотропы, похищенные с «Анираадхи» и завершили
                    их обучение пилотированию, инженерному делу и точным наукам.</p>
                <p className="card-text text-justify">К моменту совершеннолетия Адам уже был матерым
                    контрабандистом, участвовал в десятке пиратских налетов на торговые пути агатонцев и
                    неоднократно попадал за решетку в самых разных уголках галактики. Он был должен немалую сумму
                    Лиге свободной торговли за свое обучение и безбедное (в разумных пределах) детство на
                    Хал-Верноне, поэтому потребовалось немало времени, чтобы отбить эти вложения и заработать
                    свободу. Примерно к 30 он получил вольную грамоту и стал полноценным гражданином <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Свободных миров"} /> (иронично, что
                    Свободные же миры и были местом его рабского труда).</p>
                <p className="card-text text-justify">После этого Адам решил завязать с пиратством и вернуться к
                    «честному» по его мнению труду: возить запрещенные товары через границы разных стран. В какой-то
                    момент он свернул не туда и решил помочь старым знакомым из Самборы наделать дыр в системах
                    жизнеобеспечения корабля <TextInlineLink name={"bs_char_lenisaad_victor"}
                        text={"Виктора Ленисаада"} />. Ему дали специальный ИИ,
                    который совершил взлом, когда корабль Адама влетел в
                    ангар Виктора с партией оружия. Но выяснилось, что платить Броуди никто не собирался, и алгоритм
                    специально раскрыл незадачливого пилота, когда тот еще не успел убраться подальше.</p>
                <p className="card-text text-justify">Адама схватили и собирались казнить, но пока решали, как
                    именно это сделать, аламарси успел взломать наручники, угнать свой же корабль и уйти от погони.
                    Гостивший в это время у Ленисаада <TextInlineLink name={"bs_char_de_karma_ivar"}
                        text={"Ивар де Карма"} /> высоко оценил таланты
                    молодого пилота. Он сумел выследить парня и предложил ему
                    работу, чем сделал правильный выбор: Адам не только множество раз спасал кидонианца, но и
                    фактически не дал событиям «Кидонианки» угробить героев.</p>
                <p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span></p>
                <p className="card-text text-justify">Находится в поразительном состоянии: объявлен в розыск как
                    международный преступник, но благодаря дипломатическому иммунитету Республики Акулы не может
                    быть арестован.</p>
                <h3 className="title-bg">Занимательные факты</h3>
                <p className="card-text text-justify">Адам в раннем детстве прошел курс ноотропов, которые разогнали
                    его рефлексы и скорость мышечного отклика до нечеловеческих показателей. Он редко демонстрирует
                    это, резонно полагая, что чем меньше людей знает о его способностях, тем проще будет устроить
                    врагу неприятный сюрприз.</p>
                <p className="card-text text-justify">Носит на груди татуировку в виде кривовато исполненного
                    черепа, один глаз которого является соском.</p>
                <p className="card-text text-justify">В первый раз сел за штурвал в шесть лет (хотя всем говорит,
                    что еще раньше).</p>
                <p className="card-text text-justify">Имя Адама при рождении - Артур, в честь <TextInlineLink
                    name={"bs_char_arthur_the_founder"} text={"Артура Основателя"} /> (заложившего
                    основы жизни флотов аламарси). Но Броуди сменил его, потому что «так ничего и не основал».</p>
                <h3 className="title-bg">Эволюция героя</h3>
                <p className="card-text text-justify"><span className="strong">Адам</span> всегда был незадачливым
                    пилотом и другом де Кармы. В этом плане никакой эволюции у него и не было. Единственное, что
                    менялось от версии к версии, это биография: изначально он был михъельмцем, затем землянином и
                    только потом превратился в аламарси.</p>
                <p className="card-text text-justify">Рассказать здесь, по сути, нечего, потому что характер и
                    отношение к жизни в финальной версии у него идентичны изначальной.</p>
                <p className="card-text text-justify"><span className="strong">Фрагмент диалога Адама и Тора на теперь уже удаленной из сюжета Сцинтилле (подробнее в <TextInlineLink
                    name={"bs_unenclosed"} text={"«Удаленные герои»"} />) :</span></p>
                <blockquote>–Не бойся, ведь мы умрем красиво! – заверил товарища Адам.<br />– Ага, мне от этого ой как
                    полегчало, – Тор выглянул из-за укрытия и тут же вернулся обратно. – Если ты у нас такой эстет,
                    обещаю собрать твои останки в баночку, когда преставишься
                </blockquote>
            </>
    }
}
