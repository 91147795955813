import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Lazarus() {
    return {
        "science": <span>биохимия</span>,
        "use": <span>спецслужбы, армия</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">«Лазарь»</span> – экспериментальный
                    препарат, способный защитить нервную систему высокоразвитого гуманоида от электромагнитного
                    оружия. Изначально разрабатывался <TextInlineLink name={"bs_comp_cms"}
                        text={"агатонской разведкой"} /> где-то в
                    мирах <TextInlineLink name={"bs_space_ardaline_network_planets"} text={"Ардалиннской сети"} /> и
                    предназначался только для спецназа <TextInlineLink name={"bs_state_republic_of_tiara_minor"}
                        text={"Республик"} />. Но очень быстро
                    появился на черном рынке по всей галактике. Стоимость - астрономическая и шанс нарваться на
                    подделку довольно высок (особенно в <TextInlineLink name={"bs_state_free_worlds"} text={"Самборе"} />).
                </p>
            </>
    }
}
