import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Arthur_the_founder() {
    return {
        "name": "Артур Калибурн",
        "fullname": <span>Артур <small>«Основатель»</small>Калибурн</span>,
        "born": <span>~4900 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>неизвестно</span>,
        "homeworld": <span>неизвестный <TextInlineLink name={"bs_tech_hiveship"} text={"корабль-улей"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "status": <span className={"text-danger"}>пропал без вести</span>,
        "imgs": [
            { "short": "bs_char_arthur_the_founder_1_jpg_500", "big": "bs_char_arthur_the_founder_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Историческая персоналия</h3>
                <p className="card-text text-justify">
                    Артур – полулегендарный капитан аламарси, живший примерно за 4900 лет до
                    событий «Кидонианского солнца». Факт существования Артура подтверждается множеством независимых
                    источников: журналами
                    земных колоний того времени и дневниками капитанов, найденными в обломках утерянных кораблей. Их
                    накопилось настолько много, что даже самый скептически настроенный ученый не может отрицать как
                    минимум
                    реальность Основателя в качестве исторической фигуры.
                </p>
                <p className="card-text text-justify">
                    Однако обширный список деяний, приписываемый ему, все еще подлежит обсуждению.
                    Если верить дневникам современников, Артур исчез при очень таинственных обстоятельствах в районе
                    системы
                    звезды <TextInlineLink name={"bs_star_hedwig"} />. Это случилось вскоре после объединения аламарси в
                    единый флот и сделало <TextInlineLink name={"bs_universe_religion_arthurians"}
                        text={"его культ"} /> еще
                    популярнее: люди любят загадочные истории.
                </p>

            </>
    }

}