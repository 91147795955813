import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hellesian_raid() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>область космоса</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"} /></span>,
        "people": <span>кидонианцы, хеллесианцы (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_hellesian_raid_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Хеллесианский рейд</span> – область между
                    системами Мираж и Хельм, самая населенная территория в стране.
                    По сути ее можно назвать одной большой <TextInlineLink name={"bs_universe_planet_types"}
                        text={"метрополией"} />,
                    существующей
                    в режиме полного самообеспечения: здесь есть не только жилые и развлекательные станции, но и
                    целые искусственные луны, усеянные станциями гидропоники и мясными фермами. Кроме этого по
                    региону проложены короткие гипертрассы, рассчитанные на небольшие частные катера.</p>
                <p className="card-text text-justify">Рейд укреплен множеством оборонных сооружений, военных верфей и
                    оружейных фабрик: в случае нападения на Приоритет здесь можно в считанные минуты развернуть
                    полноценный военно-промышленный комплекс, способный оборонять столицу и несколько соседних
                    систем.</p><p className="card-text text-justify">Самые важные оборонные объекты здесь - это
                        штаб <TextInlineLink name={"bs_comp_esquirete"} text={"Эсквариата"} /> («Объект №33 «Паллада») в
                    системе <TextInlineLink name={"bs_star_mirage"} text={"Мираж"} /> и <TextInlineLink
                        name={"bs_state_cydonian_priority_science"}
                        text={"Хеллесианская академия торгового и военного флота имени Бриэнны де Эстрада"} /> в
                    системе <TextInlineLink name={"bs_star_hela_gemini"} /></p>
            </>
    }
}

