import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hela_gemini() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>белый карлик и красный гигант</span>,
        "stars": <span>2 (Хельм и Ульмигард)</span>,
        "planets": <span>34</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"} /></span>,
        "area": <span>Хеллесианский рейд</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span>кидонианцы, хеллесианцы (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "population": <span>162 млрд.</span>,
        "imgs": [{ "short": "bs_wiki_hela_gemini_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Хела-Джемини</span> – очень необычная
                    звездная пара. Хельм - красный гигант, доживающий свои дни, в то время как Ульмигард - белый
                    карлик, пожирающий своего брата: от Хельма к нему тянется огромное облако газа, видимое с Хелы
                    как яркая белая полоса в небе. Из-за особенностей орбиты она проявляется в основном в сумерках
                    примерно 3 месяца в году (в остальное время лишь частично и не всегда). Кроме этого, Ульмигард
                    обзавлся аккреционным диском, захватив вещество, выброшенное во время вспышек на Хельме.</p><p
                        className='card-text text-justify'>Большая часть системы - это газопылевое облако и обломки планет,
                    уничтоженных нестабильным Хельмом, в том числе во время расширения. Облако очень радиоактивно: оно
                    нарушает связь и мешает навигации по всей системе, делая большую ее часть непригодной для любой
                    осмысленной деятельности, кроме научной.</p><p className='card-text text-justify'>Несмотря на это,
                        Хела­-Джемини - полноценная метрополия, лишь слегка менее населенная, чем соседняя система
                        звезды <TextInlineLink name={"bs_star_mirage"} text={"Мираж"} />. Здесь находится множество
                    производственных
                    комплексов, станций гидропоники и военных объектов, в том числе научных центров, исследующих
                    возможности ведения боя в условиях радиоактивной туманности.</p>
            </>
    }
}

