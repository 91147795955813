import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import { CrumbsBlackSunOrganizations } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Serpentara_emplumara } from "./serpentara_emplumara";
import { Scs } from "./scs";
import { Privateer } from "./privateer";
import { Priumviratus } from "./priumviratus";
import { Gray_market } from "./gray_market";
import { Galactic_security } from "./galactic_security";
import { Free_trade_league } from "./free_trade_league";
import { Free_navy } from "./free_navy";
import { Esquirete } from "./esquirete";
import { Cydonian_adimensional_communications } from "./cydonian_adimensional_communications";
import { Cms } from "./cms";
import { Bs_org_Bonecollectors } from "./bonecollectors";
import { Vikoveera_mineralworks } from "./vikoveera_mineralworks";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "bonecollectors": Bs_org_Bonecollectors,
    "tiara-security-service": Cms,
    "cydonian-adimensional-communications": Cydonian_adimensional_communications,
    "esquirete": Esquirete,
    "free-navy": Free_navy,
    "free-trade-league": Free_trade_league,
    "galactic-security": Galactic_security,
    "gray-market": Gray_market,
    "priumviratus": Priumviratus,
    "privateer": Privateer,
    "collective-security-council": Scs,
    "serpentara-emplumara": Serpentara_emplumara,
    "vikoveera-mineralworks": Vikoveera_mineralworks
}

/*
return {
        "top": "",
        "text":
            <>
            </>
    }
 */

export function Organization(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_organizations"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Кидонианское солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunOrganizations name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h1>{name}</h1></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            {data["top"]}
                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}



































