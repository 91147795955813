import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Tajbennae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "moons": <span>нет</span>,
        "habitats": <span><s>1</s>0</span>,
        "g": <span>1.07 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Кортасар</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"} /></span>,
        "type": <span>землеподобная</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [{ "short": "bs_wiki_tajbennae_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Тайбенна</span> – официально
                    незаселенная планета из Резервного фонда терраформированных миров Приоритета. Массовая
                    колонизация никогда не проводилась - Тайбенну держали подальше от промышленников и урбанистов,
                    на случай, если по какой-то причине потребуется расселить жителей других миров.</p><p
                        className='card-text text-justify'><span className='strong'>Последствия «Похитителей маяков»:</span>
                </p><p className='card-text text-justify'>На планете была обнаружена база неизвестных разумных существ,
                    временно названных <TextInlineLink name={"bs_spec_vitruvianus"} text={"«морковными гуманоидами»"} />.
                    Существа вели себя агрессивно: похищали людей в других мирах и проводили над ними изуверские
                    эксперименты. <TextInlineLink name={"bs_char_skarsgard_oliver"} /> положил
                    этому конец, наведя орудия 77-го погранфлота Приоритета прямо на базу тварей - это не только
                    уничтожило аванпост пришельцев, но и нанесло колоссальный ущерб экологии Тайбенны.</p>
            </>
    }
}

