import { CrumbsBlackSunGallery } from "../../../../components/Bread_Crumbs";
import { GalleryCard } from "../Gallery";
import wiki from "../../../../components/cms/wiki.json";


export function Gallery_worlds() {
    document.title = "Галерея Кидонианского солнца"
    return (<>
        <div className="row" key="crumbs">
            <CrumbsBlackSunGallery name={"Галерея миров"} />
        </div>
        <h3 className="title-bg">Миры Кидонианского солнца</h3>
        <div className="row">
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"avvaline_500"} big={"avvaline"} link={wiki["bs_planet_avvaline"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_avvaline"].link}>Аввалин</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"tamanrasett_500"} big={"tamanrasett"} link={wiki["bs_planet_tamanrasett"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_tamanrasett"].link}>Таманрасетт</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"cydonia_sm"} big={"cydonia"} link={wiki["bs_planet_cydonia"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_cydonia"].link}>Кидония</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"new_havana_500"} big={"new_havana"} link={wiki["bs_planet_new_havana"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_new_havana"].link}>Новая Гавана</a>
                    </h5>} />
            </div>
        </div>
        <div className="row">
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"valoria_500"} big={"valoria"} link={wiki["bs_planet_valoria"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_valoria"].link}>Валория</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">

                <GalleryCard small={"arville_500"} big={"arville"} link={wiki["bs_planet_arville"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_planet_arville"].link}>Арвилль</a>
                    </h5>} />
            </div>
        </div>
    </>)
}
