import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Njorun() {
    return {
        "date": <span>-</span>,
        "type": <span>красный гигант</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>0</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"Ночи"} /></span>,
        "owned": <span>под вопросом</span>,
        "people": <span>-</span>,
        "population": <span>-</span>,
        "imgs": [{ "short": "bs_wiki_njorun_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Ньёрун или Ночь</span> – красный
                    гигант в секторе Ночи. Вопреки традиции, его назвали в честь одной из своих планет – Угольной
                    Ньерун – а не наоборот.</p><p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span>
                </p><p className="card-text text-justify">Система, как и весь сектор, остается в полном хаосе и пока
                    даже не ясно, кто и чем здесь управляет.</p>
            </>
    }
}

