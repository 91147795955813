import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Nazil() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Назиль Кэори",
        "fullname": <span>Назиль  <small>«Назиль»(ага)</small> Кэори</span>,
        "born": <span>59 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>58</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"улей «Томирида»"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "status": <><span className={"text-danger"}>мертв</span> (1 год ДТ)</>,
        "work": <span><TextInlineLink name={"bs_comp_cms"}
            text={"1-е диверсионное управление"} /> СБК</span>,
        "imgs": [
            { "short": "bs_char_kaory_nazil_1_jpg_500", "big": "bs_char_kaory_nazil_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О герое</h3>
                <p className="card-text text-justify">
                    <span className="strong">Назиль</span> родился и вырос на корабле-улье. Его родители были пилотами
                    по праву рождения и Назиль унаследовал необходимые гены - только с нужными комбинациями аламарси
                    берут в
                    пилоты. Он получил образование в области инженерии, <TextInlineLink
                        name={"bs_science_astromechanics"} text={"астромеханики"} /> и других точных наук, которые сделали
                    его блестящим "летуном" и техником.
                </p>
                <p className="card-text text-justify">
                    Однако прозябать в качестве контрабандиста или тем более пирата в полулегальном мигрирующем флоте
                    парень не хотел. Еще до совершеннолетия он напросился в торговую экспедицию на <TextInlineLink
                        name={"bs_planet_agathon"} /> и там сбежал
                    от товарищей. Бедняга несколько недель провел на улицах мегаполиса: растерянный, не готовый к жизни
                    на планете аламарси в незнакомой среде. А затем наткнулся на рекламу агатонского флота.
                </p>
                <p className="card-text text-justify">
                    Оборванный и голодный, Назиль нашел пункт вербовки и рассказал о своем положении. Офицеры редко
                    встречали аламарси и не сразу поверили, что этот кочевник и правда заявился добровольно, да еще
                    и с благими намерениями. Но после первого же теста на тренажере приняли в летную академию без
                    экзаменов, игнорируя тот факт, что вербовать должны были матросов, а не пилотов.
                </p>
                <div className="iteresting-fact">
                    <p className="card-text text-justify">
                        Интересный факт: сам Назиль говорит, что его якобы изгнали
                        из клана. Мало кто на самом деле знает, что аламарси сбежал сам. Причина в том, что для аламарси
                        добровольно покинуть флот - худший позор. Тот, кого выгнали за проступки, может вернуться,
                        если докажет, что исправился. Тот же, кто сбежал сам, никогда больше не получит права ступать на
                        борт
                        улья. Любого улья аламарси в галактике.
                    </p>
                </div>
                <p className="card-text text-justify">
                    Прокачанный <TextPopup
                        name={"bs_popups_tech_nootrop"} text={"ноотропами"} /> мозг Назиля без труда осилил большую часть
                    тренировок и показал отличные результаты. Еще до окончания академии его завербовали с СБК, где
                    направили в 1-е диверсионное управление. Там, в одной из многочисленных командировок, он
                    познакомился
                    с <TextInlineLink name={"bs_char_esora_eve"} text={"Евой Эсорой"} /> и несколько лет был ее
                    напарником.
                </p>
                <p className="card-text text-justify">
                    Концом карьеры Назиля стали события <TextInlineLink name={"book_cydonian_girl"}
                        text={"«Кидонианки»"} />: их с Евой отправили
                    на <TextInlineLink name={"bs_planet_michelm"} text={"Михъельм"} /> для помощи мятежникам. Назиль
                    практически не принимал участия в этом задании, работая в качетсве "запасного пилота" и
                    помощника <TextInlineLink name={"bs_char_salibar_kardenos"} text={"полковника Карденоса"} />. А за 13
                    месяцев
                    до
                    Таманрасетт он был застрелен <TextInlineLink name={"bs_char_de_karma_ivar"}
                        text={"Иваром де Кармой"} /> на <TextInlineLink
                        name={"bs_planet_earth"} text={"Земле"} />, в последние дни существования <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Великой Монархии"} />, к распаду которой частично
                    приложил руку.
                </p>
            </>
    }
}
