import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Grime() {
    return {
        "is_a_ship": false,
        "is_a_type": true,
        "is_an_article": false,
        "developed": <span><TextInlineLink name={"bs_state_the_great_monarchy"} /> («Верфи Проциона» и «Машинные технологии Тау Кита»)</span>,
        "class": <span>тяжелый <TextInlineLink name={"bs_ships_ship_classes"} text={"линейный корабль"} /></span>,
        "size": <span>4800x1200x1200м</span>,
        "conflicts": <span>Пережили <TextInlineLink name={"bs_timeline_galactic_war"}
            text={"Галактическую"} />: <TextInlineLink
                name={"bs_ships_nyorun_dreams"} text={"«Грёзы Ньёрун»"} />, «Медвежий король», «Ловец снов», «Кащей», «Амарок» и еще 6 кораблей</span>,
        "status": <span>производство остановлено после <TextInlineLink name={"bs_timeline_galactic_war"}
            text={"Галактической"} /></span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"рельсотронные батареи"} /> (от 60 до 500 калибра), управляемые и неуправляемые ракетные батареи, <TextInlineLink
            name={"bs_weaponry_magnetic_cannon"}
            text={"магнетроны"} /> всех калибров, системы радиоэлектронной борьбы</span>,
        "defence": <span>многослойная броня из <TextInlineLink name={"bs_tech_space_steel"} text={"корабельной стали"} /> с 32% <TextInlineLink
            name={"bs_tech_anzuritum"} text={"анзурита"} />, эмиссионные противоракетные заграждения, системы РЭБ (против наведения и навигации), активная ракетная и рельсовая защита</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">«Морок»</span> – тип линейных
                    кораблей, разрабатывался и производился в Монархии до <TextInlineLink
                        name={"bs_timeline_galactic_war"} text={"Первой Галактической"} />. После войны
                    военные бюджеты резко урезали, из-за чего производство и модернизация остановились.</p><p
                        className="card-text text-justify">Многие машины этого типа, пережившие Галактическую, были
                    уничтожены во время <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Восстания Акулы"} />.
                </p>
            </>
    }
}
