import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function De_karma_ivar() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "is_not_ready": false,
        "name": "Ивар де Карма",
        "fullname": <span>Ивар Кайден <small>«Кочевник»</small> де Карма</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианец"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>Даврон и Мария де Карма</span>,
        "parents_adopt": <span>Камилла Скошгард</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Оливер"} /> и <TextInlineLink
            name={"bs_char_skarsgard_vivien"} />, Равенна и Карлайл де Карма</span>,
        "daughter_adopt": <span><TextInlineLink name={"bs_char_de_levine_rosalie"} /></span>,
        "work": <span>частный рекламатор, <strike>Кидонианский военный флот</strike></span>,
        "imgs": [
            { "short": "bs_char_de_karma_ivar_1_png_500", "big": "bs_char_de_karma_ivar_1_jpg_full" },
            { "short": "bs_char_de_karma_ivar_2_jpg_200", "big": "bs_char_de_karma_ivar_2_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <p>(генератор крутых идей)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h4 className="title-bg">Юность и Первая Галактическая</h4>
                <p className="card-text text-justify">
                    <span className="strong">Ивар</span> родился за 73 года до
                    Таманрасетт, на Кидонии, в семье Даврона и Марии де Карма, военного
                    аристократа и ученой. Мать взяла фамилию отца по старой кидонианской традиции,
                    потому
                    что до брака ее социальное положение было не очень высоким, а фамилия будущего мужа
                    могла открыть многие двери.
                </p>
                <p className="card-text text-justify">
                    Она трагически погибла вскоре после рождения
                    младших брата и сестры Ивара, близнецов Равенны и Карлайла. Им было всего два года,
                    когда Мария отправилась в очередную археологическую экспедицию, искать потерянные
                    города древних людей на <TextInlineLink name={"bs_planet_tamanrasett"}
                        text={"Таманрасетт"} />. Там
                    на нее и еще
                    нескольких ученых обрушились своды пещеры.
                </p>
                <p className="card-text text-justify">
                    Вскоре после этого Даврон женился на Камилле
                    Скошгард, вдове другого уважаемого офицера. У нее было двое детей от прошлого брака:
                    Оливер и Вивьен, с которыми Ивар быстро нашел общий язык. Все они переехали в поместье де Карма на Кидонии, где Ивар помогал воспитывать детей в духе семьи де Карма и вдохновил на
                    поступление в военную академию вслед за отцом.
                </p>
                <p className="card-text text-justify">
                    Сам он пошел в Академию флота в 25, сразу после
                    окончания школы. Вопреки ожиданиям, результаты теоретической подготовки Ивара были
                    не
                    такими уж и впечатляющими, хотя на курсе он отличился многими практическими
                    талантами, которые в будущем сделали де Карму легендой мира наемников. Во время учебы у Ивара был
                    роман с <TextInlineLink name={"bs_char_van_gloria_adel"}
                        text={"Адель ван Глорией"} />,
                    с которой он познакомился еще в школьные дни.
                </p>
                <p className="card-text text-justify">
                    В <TextPopup text={"45 он потерял и отца"}
                        popup={"В первой версии «Кидонианки» была неточность: указывался возраст 35 вместо 45"} />:
                    тот погиб на полях сражений
                    в секторе <TextInlineLink name={"bs_space_sunrise_sector"} text={"Рассвета"} />.
                    Почти
                    сразу после его смерти Ивар тоже пошел на фронт. До
                    конца <TextInlineLink
                        name={"bs_timeline_galactic_war"} text={"Галактической"} /> оставалось
                    меньше года, но этого времени хватило с головой, чтобы де Карма осознал, какое зло
                    несет война и понял всю глупость людской борьбы за влияние.
                </p>
                <p className="card-text text-justify">
                    За этот год он успел показать себя неординарным
                    стратегом, способным понимать, как враг поведет себя в той или иной ситуации. Ивар
                    быстро взлетел по карьерной лестнице и уже через 12 лет службы стал одним из самых
                    молодых адмиралов в истории кидонианского военного флота. А еще через год лишился
                    всех званий и наград за катастрофу на <TextInlineLink name={"bs_planet_tiora"}
                        text={"Тиоре"} />:
                    вину Ивар взял на себя, хотя
                    сам находился на орбите. После трибунала он покинул страну и отправился искать
                    применение своим навыкам на вольных хлебах.
                </p>
                <h4 className="title-bg">Карьера вольного стрелка</h4>
                <p className="card-text text-justify">
                    Почти сразу судьба свела его с <TextInlineLink
                        name={"bs_char_lenisaad_victor"} text={"Виктором Ленисаадом"} />,
                    который тогда торговал только контрабандным кидонианским оружием на территории
                    землян. Де Карма сначала нанялся к нему в аналитики и помог улучшить систему
                    поставок
                    (жить-то на что-то надо?), но быстро понял, что теневой бизнес не для него. Хотя
                    полезные связи на темной стороне галактики, разумеется, решил сохранить.
                </p>
                <p className="card-text text-justify">
                    Поэтому через какое-то время решил попробовать себя
                    не в работе на пиратов, а в борьбе с ними. Он брал контракты на охрану груза у
                    правительств мелких окраинных планет и быстро разобрался в том, как звездные бродяги
                    (читай - пираты) мыслят. А потом вдруг оказалось, что за спасение уже украденного
                    груза платят больше, чем за его охрану! Разумеется, если речь о чем-то уникальном и
                    крайне дорогом.
                </p>
                <p className="card-text text-justify">
                    Тогда де Карма и решил стать рекламатором. Но даже
                    не ради денег (доступ к семейным счетам у него еще был), а ради сложных задач,
                    которых не найти даже в военном флоте. Ведь солдаты в первую очередь стреляют во
                    всех
                    пиратов подряд, а потом уже идут на хитрости, если выстрелы не помогают. А вот
                    рекламатор должен сначала разобраться, кто похитил груз и куда его перевез, затем
                    найти негодяя, взять живьем и допросить - работа куда сложнее, чем можно
                    представить.
                </p>
                <p className="card-text text-justify">
                    Почти сразу после этого Ивар нанял <TextInlineLink
                        name={"bs_char_browdy_adam"} text={"Адама"} /> в качестве пилота, а еще спустя пару
                    лет – <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэри"} /> в качесте
                    дуболома. С тех пор прошло немало лет, но в команде неизменно оставались только эти
                    двое. За это время они успели выполнить больше сотни заказов как для частных
                    бизнесменов, так и для высокпоставленных чиновников (среди которых
                    была <TextInlineLink
                        name={"bs_comp_cms"} text={"Служба безопасности Короны"} /> и
                    принцесса <TextInlineLink name={"bs_char_javet_eijenora"} text={"Эльсинора Явет"} />).
                </p>
                <h4 className="title-bg">Революция в <TextInlineLink name={"bs_state_the_great_monarchy"}
                    text={"Монархии"} /> (<TextInlineLink
                        name={"book_cydonian_girl"} />)</h4>

                <p className="card-text text-justify">
                    Примерно за 14 месяцев до Таманрасетт Ивара нанял <TextInlineLink
                        name={"bs_char_tom_torvalds"} text={"Томас Торвальдс"} />, тогдашний губернатор
                    михъельмских
                    территорий
                    Великой Монархии (ныне несуществующей). Все началось с помощи в побеге из тюрьмы, а
                    привело в итоге
                    к полноценному участию команды Ивара в <TextInlineLink
                        name={"bs_timeline_the_shark_revolt"}
                        text={"Михъельмском восстании"} />.
                </p>
                <p className="card-text text-justify">
                    Тогда же Ивар встретил <TextInlineLink
                        name={"bs_char_de_levine_rosalie"} text={"Розали де Левин"} />. Во то время он
                    собирался купить в
                    системе <TextInlineLink name={"bs_space_sleeping_suns"}
                        text={"Спящих солнц"} /> оборудование для
                    помощи Торвальдсу.
                    Но самборианцы, доставившие груз, предали де Карму и попытались захватить в плен,
                    чтобы продать
                    врагам в Приоритете - после
                    Тиорийского инцидента у Ивара их было очень много.
                </p>
                <p className="card-text text-justify">
                    Именно Розали спасла де Карму от этой участи и затем присоединилась к отряду.
                    Несмотря на полный
                    непрофессионализм девушки, Ивар высоко оценил ее таланты и даже помог с поисками
                    родителей. А
                    уж когда выяснил, кем были эти родители... тут-то все и завертелось.
                </p>
                <p className="card-text text-justify">
                    Кроме этого, Ивар познакомился с <TextInlineLink name={"bs_char_esora_eve"}
                        text={"Евой Эсорой"} />,
                    агрессивной, но очень обаятельной агатонкой и закрутил
                    с ней некое подобие служебного романа. В конце <TextInlineLink
                        name={"book_cydonian_girl"} text={"«Кидонианки»"} /> он застрелил двух ее напарников,
                    когда те
                    попытались
                    передать сведения об истинной природе Розали агатонской разведке. После этого пути
                    Ивара и Евы на
                    какое-то время разошлись.
                </p>
                <p className="card-text text-justify">
                    В конечном итоге Ивар помог михъельмцам свергнуть Монарха и смог выяснить
                    внешность <TextInlineLink
                        name={"bs_char_the_whip"} text={"отца"} /> девушки.
                    Дальше
                    он намеревался разыскать его и задать пару вопросов - этим закончилась первая часть
                    цикла <TextInlineLink
                        name={"bs_series"} text={"«Кидонианское солнце»"} />.
                </p>

                <h4 className="title-bg">Занимательные факты</h4>
                <p className="card-text text-justify">
                    Однажды на <TextInlineLink name={"bs_planet_new_havana"}
                        text={"Новой Гаване"} /> Ивар выпил
                    шнапс с
                    местной плесенью и несколько часов танцевал на столе. Прошло уже 30 лет, но в <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Свободных мирах"} /> до сих пор
                    гуляет видео с его участием.
                </p>
                <h4 className="title-bg">Эволюция героя</h4>
                <p className="card-text text-justify">
                    <span className="strong">Ивар</span> начинал как
                    капитан корабля, на котором Розали отправлялась в путешествие. Он должен был просто
                    сопровождать ее и помогать в приключениях. В главного героя он эволюционировал много
                    позже: в какой-то момент стало ясно, что мир недостаточно проработан и в нем не
                    хватает народов с разными культурами. Вся галактика была похожа на одно большое село,
                    из-за чего утрачивалось ощущение масштаба окружающего героев мира. Было сложно
                    поверить, что в той версии мира могли годами пропадать целые флоты и никто бы не
                    знал.
                </p>
                <p className="card-text text-justify">
                    Затем на арену вышли кидонианцы, как первый и
                    центральный с точки зрения повествования народ. В сюжете появились упоминания Кидонии
                    и нужно было добавить в окружение Розали героя, который бы представлял собой живую
                    отсылку к этой части вселенной. Ивар стал отличным кандидатом и постепенно оброс
                    сложной биографией и тяжелыми поворотами судьбы.
                </p>
                <p className="card-text text-justify">
                    Он стал военным советником при Михъельме, который
                    помогал Торвальдсу и Бьерне организовать мятеж против землян. При этом версий его
                    прибытия на Поместье Спящих солнц стало сразу две. <span
                        className="strong">По одной</span> Ивар отправился туда с инспекцией, чтобы
                    проверить расположенный в Холдрейге тренировочный лагерь. А согласно <span
                        className="strong">второй</span> де Карма попал туда случайно, когда его крейсер
                    повредили айлири (на тот момент между Монархией и Айлирэном планировался затяжной
                    конфликт). В обоих случаях он узнавал о Розали случайно.
                </p>
                <p className="card-text text-justify">
                    Затем де Карме оказалось тесно и в этой роли. В тот
                    момент стало ясно, что он может быть кем-то большим, чем просто марионеткой королей и
                    ему требовалось «сакральное знание», некая черта, которая сделает его незаменимым для
                    сюжета. Поэтому он получил роль наставника Розали, но изначально должен был
                    специально ее выслеживать.
                </p>
                <p className="card-text text-justify">
                    В той версии себя Ивар в молодости встречался с
                    таким же как она полукровкой, и с тех пор не щадил средств и сил для поиска подобных
                    Розали. Но в конце концов стало ясно, что это делает его корыстным и не очень
                    приятным персонажем: выслеживать и использовать девушку как оружие может только
                    негодяй, а делать Ивара таковым не было желания. Поэтому пришлось остановиться на
                    версии, где он совершенно случайно наткнулся на нее в почти заброшенном мире.
                </p>
            </>
    }
}
