import {
    SidebarBSGallery, SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSUnenclosed, SidebarBSWiki
} from "../../../../components/Sidebar"
import { TextInlineLink } from "../../../../components/cms/wiki_functions";
import { CrumbsBlackSunBook } from "../../../../components/Bread_Crumbs";
import wiki from "../../../../components/cms/wiki.json";
import imgs from '../../../../components/cms/images_repo.json';


export function BlackSunWorldMap() {
    document.title = "Карта мира Кидонианки и Кидонианское солнца"
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={"Карта мира"} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-8">
                    <div className="row"><h1 className="title-bg">Карта мира и отдельных территорий</h1></div>
                    <div className="row">
                        <div className="text-center col-lg-4">
                            <h5 className="ordinary"><TextInlineLink name={"bs_maps_oecumene"} text={"Ойкумена Homo sapiens"} /><br />до Таманрасетт</h5>
                            <a href={wiki["bs_maps_oecumene"].link}>
                                <div className="img-thumbnail-v2" style={{ "maxWidth": "250px", "margin": "auto" }}>
                                    <img className="bg-image" style={{ "maxWidth": "240px" }} alt="Ойкумена Homo sapiens до Таманрасетт"
                                        src={imgs["bs_wiki_galaxy_v2_250x250_map_ru"].link} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="well">
                        <SidebarBSWiki />
                        <hr />
                        <SidebarBSMap />
                        <hr />
                        <SidebarBSTimeline />
                        <hr />
                        <SidebarBSGallery />
                        <hr />
                        <SidebarBSUnenclosed />
                    </div>
                </div>
            </div>


        </div>
    )
}
