import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Democratic_space() {
    return {
        "date": <span>-</span>,
        "type": <span>регион галактики</span>,

        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонцы"} />, <TextInlineLink
            name={"bs_people_aethurians"} text={"этурианцы"} /> и др.</span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонский терос"} /></span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><strong>Демократический космос</strong> или <strong>ДРК</strong> –
                    традиционно так называют сектора под контролем демократов, будь то агатонцы или любые другие
                    любители власти народа. Большая его часть сосредоточена по направлению к ядру галактики
                    от <TextInlineLink name={"bs_planet_earth"} text={"Земли"} />.</p>
            </>
    }
}

