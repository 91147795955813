import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Brienne_de_estrada() {
    return {
        "name": "Бриэнна де Эстрада",
        "fullname": <span>Бриэнна де Эстрада</span>,
        "born": <span>~5500 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>93</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянка"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>корабельный инженер</span>,
        "status": <span className={"text-danger"}>нет в живых</span>,
        "imgs": [
            { "short": "bs_char_brienne_de_estrada_1_jpg_500", "big": "bs_char_brienne_de_estrada_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Историческая персоналия</h3>
                <p className="card-text text-justify">
                    <span className="strong">Бриэнна</span> родилась и выросла на Земле, примерно за 5 с половиной
                    тысяч лет до событий «Кидонианского солнца». Если верить ее дневникам, девушка всегда мечтала улететь из дома и
                    посмотреть другие миры. Поэтому получила специальность инженера навигационных систем и в 23 года
                    покинула планету на колониальном корабле «Юпитер-12».
                </p>
                <p className="card-text text-justify">
                    Машина летела с Земли на <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонию"} /> и была одной
                    из первых, которые использовали метод крио-сна полного
                    цикла: экипаж и колонисты смогли провести в анабиозе большую часть пути. Однако за 7 лет до прибытия
                    часть команды была разбужена бортовым компьютером для починки поврежденных систем. Когда неполадки
                    были устранены, выяснилось, что скачки напряжения сожгли важные узлы, отвечающие за автоматику
                    крио-капсул. Гарантий, что даже после ремонта получится разбудить людей в нужный момент не было -
                    корабль мог застрять
                    в пункте назначения на тысячелетия, так и не вернув население к жизни.
                </p>
                <p className="card-text text-justify">
                    Было решено оставить бодрствовать двух инженеров, способных вручную запустить процесс пробуждения
                    по прилету на Кидонию. Бриэнна и еще один человек, оставшийся безвестным, стали волонтерами в этой
                    миссии и провели годы в компании друг друга и искусственного интеллекта. Машина читала людям
                    лекции и учила пилотировать на тренажерах, на всякий случай.
                </p>
                <p className="card-text text-justify">
                    В знак благодарности за то, что инженеры отдали целых 7 лет своей жизни ради всего экипажа,
                    капитан разрешил им самим довести корабль до нужной точки на орбите новой колонии. Это нарушает
                    базовые правила космоперелетов, но люди были готовы рискнуть ради красивого жеста.
                </p>
                <p className="card-text text-justify">
                    Момент, когда Бриэнна отдала кораблю команды «Стоп» и «Держать орбиту» над нужной частью
                    кидонианской поверхности, считается основанием колонии на Кидонии. Жаль, что ее коллегу история не
                    запомнила.
                </p>
                <p className="card-text text-justify">
                    В честь Бриэнны назван <TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} />.
                </p>
            </>
    }

}