export function Railgun() {
    return {
        "type": <span>кинетическое оружие</span>,
        "use": <span>любые цели</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">«Рельса» (рельсотрон, рельсовая пушка)</span> –
                    две токопроводящие рельсы и стальная болванка («шнек») между ними. Выстреливает куском металла со
                    сверхзвуковой скоростью, поэтому убойная сила снаряда просто огромна. Ручные и корабельные
                    «рельсы» работают по одинаковому принципу и, если шмальнуть достаточно большой пушкой в
                    атмосфере, можно закипятить воздух вокруг или испарить кусочек океана. Ну а ударной волной
                    превратить средних размеров городок в сломанную карточную пирамидку.</p><p>Калибр рельсового
                        орудия обычно означает радиус снаряда в сантиметрах в самой широкой его части.</p>
            </>
    }
}
