import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function The_night() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><span class="strike"><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>, под вопросом</span>,
        "habitats": <span>-</span>,
        "people": <span>-</span>,
        "moons": <span>нет</span>,
        "mother": <span></span>,
        "g": <span>1.02 земной</span>,
        "atmosphere": <span className='badge bg-danger'>атмосфера загрязнена, дыхание смертельно опасно</span>,
        "biosphere": <span>нет</span>,
        "system": <span><TextInlineLink name={"bs_star_njorun"} text={"Ньёрун"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"Ночи"} /></span>,
        "type": <span>землеподобная, стратегического назначения</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "area": <span></span>,
        "imgs": [{ "short": "bs_wiki_the_night_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Ночь (Угольная Ньёрун)</span> –
                    мертвый, выгоревший дотла мир, бывшая планета-сад в системе звезды Ньерун. Открыта в начале
                    световых перелетов и сразу же привлекла на свою поверхность огромное количество ученых и
                    поселенцев, многие из которых погибли из-за несовершенства тогдашних человеческих технологий и
                    суровых условий на планете. Названа в честь древнескандинавской богини Ньерун и послужила
                    источником вдохновения огромного количества произведений искусства и мифологических рассказов.
                    Один из самых загадочных и страшных населенных миров.</p><p
                        className="card-text text-justify">Известна как неприступная база земных лоялистов – Эшелона
                    Пятых.</p><p className="card-text text-justify"><span
                        className="strong">Последствия «Кидонианки»:</span></p><p className="card-text text-justify">Во
                            время <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"осады"} /> Пауки уничтожили все
                    оборонительные сооружения на орбите и как следует потрепали наземные базы, положение которых удалось
                    вычислить, взломав компьютеры в штабе Сони
                    Мергелис. Сейчас планета юридически принадлежит Монархии, но де-факто никем не
                    контролируется.</p>
            </>
    }
}

