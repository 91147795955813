import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function De_silgoni_ursula() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_autumn"} text={"«Кидонианская осень»"} />,
        "name": "Урсула де Сильони",
        "born": <span>71 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"} /> в <TextInlineLink
            name={"bs_space_arthuri_belt"} text={"Поясе Артура"} /></span>,
        "fullname": <span>Урсула де Сильони</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>председатель <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
            text={"Приумвирата"} /></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_de_silgoni_ursula_1_jpg_500", "big": "bs_char_de_silgoni_ursula_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О героине</h3>
                <p className="card-text text-justify">
                    <span className="strong">Урсула</span> – эпизодический персонаж. На момент <TextInlineLink
                        name={"book_cydonian_autumn"} text={"«Кидонианской осени»"} /> она возглавляет <TextInlineLink
                        name={"bs_state_cydonian_priority_priumviratas"} text={"Приумвират"} />.
                </p>
                <p className="card-text text-justify">
                    У нее личные счеты с <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорией"} />,
                    поскольку они некогда учились на одном факультете и часто
                    конфликтовали за должности в студенческом совете (в <TextInlineLink
                        name={"bs_state_cydonian_priority"} text={"Приоритете"} /> это не шутка – там совет имеет
                    реальную власть, сопоставимую с политической). Когда Адель выдвинули на должность королевы,
                    Урсула приложила немало усилий, чтобы подпортить репутацию ван Глории. Она едва избежала уголовного
                    дела за агитацию, которая в Приоритете запрещена, если совершается в СМИ или от имени должностного
                    лица.
                </p>
                <p className="card-text text-justify">
                    После избрания ван Глории королевой Урсула возглавила блок, открыто выступающий против Адель. Под
                    ее началом работали такие деятели, как <TextInlineLink name={"bs_char_colbert_michel"} />, <
                        TextInlineLink name={"bs_char_scalligan_lloyd"} /> и даже целая <TextPopup
                        name={"bs_popups_priority_politics_4p"} text={"4П"} />.
                </p>
            </>
    }
}
