import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bird_of_passage_guide_to_milky_way() {
    return {
        "top": <>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Автор: </strong> <TextInlineLink
                            name={"bs_char_ursula_adams"} /></li>
                        <li><i className="fas fa-chevron-right"></i> <strong>Изданий: </strong> 4</li>

                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Дата написания:</strong>
                            <span>21 - 11 годы <TextInlineLink
                                name={"bs_timeline"} text={"ДТ"} /></span></li>
                        <li><i className="fas fa-chevron-right"></i> <strong>Альтернативные названия:</strong>Путеводитель
                            для автостопщиков, Галактический гид для бомжей
                            (услышав это название, Урсула даже хотела переименовать книгу, но издатель запретил)
                        </li>
                    </ul>
                </div>
            </div>
        </>,
        "text": <>
            <h5>Предисловие к коллекционному изданию книги, написанное Юлианом Харари в 223 г. <TextInlineLink
                name={"bs_timeline"} text={"ПТ"} /></h5>
            <p className="card-text text-justify">
                Еще до выпуска своего знаменитого «Путеводителя» <span className="strong">Урсула Адамс</span> была
                авторитетным исследователем нашей галактики. Как и многие соратники,
                она начинала с обычного блога в ИнтерСети, где рассказывала о тонкостях тех или иных
                культур и находила интересные места, часто игнорируемые туристами. Вместе с этим она работала на
                несколько агатонских институтов, участвовала в археологических экспедициях и даже спасала людей
                из-под завалов во время войны между агатонцами и землянами.
            </p>
            <p className="card-text text-justify">
                Ее биография пестрит опасными поворотами и коварными гипертрассами. С самой юности ее затянул
                водоворот безумных приключений: собиратели костей, вольные торговцы, самборианские картели и даже
                сам <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатый змей"} /> - это неполный
                список людей, с которыми Урсуле довелось пересечься.
            </p>
            <p className="card-text text-justify">
                После долгих лет путешествий по Демкосмосу,
                бросив карьеру блогера и высокооплачиаемую работу на родине, она ринулась без оглядки в пучину
                космических перестрелок и на много лет пропала из публичного поля зрения. Вернулась уже
                с первым изданием «Путеводителя» и разорвала
                ИнтерСеть заметками о путешествиях по местам, в которые даже вооруженный исследователь не всегда
                решался залететь.
            </p>
            <p className="card-text text-justify">
                Многие отказываются верить в достоверность описанных там событий (как и я, если честно). Тем не
                менее, если
                отбросить инсинуации вокруг сюжета и сосредоточиться сугубо на полезных деталях, то мы получим
                невероятно исчерпывающий справочник о том, чем жил Млечный Путь всего за несколько лет до
                ужасных событий у <TextInlineLink name={"book_tamanrasett_contact"} text={"Таманрасетт"} />.
                Благодаря Урсуле
                сохранились описания культур и миров, бесследно исчезнувших в жерле галактической катастрофы.
            </p>
            <p className="card-text text-justify">
                Вы можете не верить в безумные приключения этой девушки и считать, что она специально напустила
                пафоса и загадочности в свою жизнь... но узнать о том, что же творилось в загадочном мире наших
                предков до Войны, вы обязаны!
            </p>
        </>
    }
}










