import cats from "../../../../../components/cms/wiki_cats.json";
import wiki from "../../../../../components/cms/wiki.json";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { CrumbsBlackSunCharacter } from "../../../../../components/Bread_Crumbs";
import { PNGLink } from "../../../../../components/links/mediaLink";
import { TheWhip } from "./the_whip";
import { GetQuotes } from "../../../../../components/Quotes";
import { AdamsUrsula } from "./adams_ursula";
import { Shimmering_devil } from "./shimmering_devil";
import { Scalligan_lloyd } from "./scalligan_lloyd";
import { Montgomery_lorena } from "./montgomery_lorena";
import { De_vaal_leon } from "./de_vaal_leon";
import { De_levine_clara } from "./de_levine_clara";
import { CanBeucanGaereas } from "./can_beucan_gaereas";
import { Lenisaad_victor } from "./lenisaad_victor";
import { Skarsgard_vivien } from "./skarsgard_vivien";
import { Hartrey_kellays } from "./hartrey_kellays";
import { Browdy_adam } from "./browdy_adam";
import { Van_gloria_adel } from "./van_gloria_adel";
import { Javet_bejsenaer } from "./javet_bejsenaer";
import { Kato_aemilius } from "./kato_aemilius";
import { Arthur_the_founder } from "./arthur_the_founder";
import { Brienne_de_estrada } from "./brienne_de_estrada";
import { Hector_salinger } from "./hector_salinger";
import { EsoraEve } from "./esora_eve";
import { De_karma_ivar } from "./de_karma_ivar";
import { Kindrace } from "./kindrace";
import { Xavier_kardenos } from "./xavier_kardenos";
import { Colbert_michel } from "./colbert_michel";
import { Nazil } from "./nazil";
import { Skarsgard_oliver } from "./skarsgard_oliver";
import { Otton_bjorne } from "./otton_bjorne";
import { Javet_pauline } from "./javet_pauline";
import { Verner_rita } from "./verner_rita";
import { DeLevineRosalie } from "./de_levine_rosalie";
import { Salibar_kardenos } from "./salibar_kardenos";
import { Alkanarra_siel } from "./alkanarra_siel";
import { Colbert_simone } from "./colbert_simone";
import { Mereglis_sonya } from "./mereglis_sonya";
import { Torvalds_tomas } from "./torvalds_tomas";
import { Alkanarra_tristan } from "./alkanarra_tristan";
import { De_silgoni_ursula } from "./de_silgoni_ursula";
import { Javet_eijenora } from "./javet_eijenora";
import { Javet_erbees } from "./javet_erbees";
import { Jasmine_haervany } from "./jasmine_haervany";
import { GetFancyBoxImg100, GetFancyBoxImgStarsBackground500 } from "../../../../../components/cms/wiki_img_functions";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "hartrey-kellays": Hartrey_kellays,
    "browdy-adam": Browdy_adam,
    "van-gloria-adel": Van_gloria_adel,
    "kato-aemilius": Kato_aemilius,
    "arthur-the-founder": Arthur_the_founder,
    "javet-bejsenaer": Javet_bejsenaer,
    "the-whip": TheWhip,
    "de-estrada-brienne": Brienne_de_estrada,
    "skarsgard-vivien": Skarsgard_vivien,
    "lenisaad-victor": Lenisaad_victor,
    "hector-salinger": Hector_salinger,
    "can-beucan-gaereas": CanBeucanGaereas,
    "esora-eve": EsoraEve,
    "de-karma-ivar": De_karma_ivar,
    "kindrace-callisto": Kindrace,
    "de-levine-clara": De_levine_clara,
    "kardenos-xavier": Xavier_kardenos,
    "de-vaal-leon": De_vaal_leon,
    "scalligan-lloyd": Scalligan_lloyd,
    "montgomery-lorena": Montgomery_lorena,
    "shimmering-devil": Shimmering_devil,
    "colbert-michel": Colbert_michel,
    "kaory-nazil": Nazil,
    "skarsgard-oliver": Skarsgard_oliver,
    "bjorne-otton": Otton_bjorne,
    "javet-pauline": Javet_pauline,
    "werner-rita": Verner_rita,
    "de-levine-rosalie": DeLevineRosalie,
    "kardenos-salibar": Salibar_kardenos,
    "alkanarra-siel": Alkanarra_siel,
    "colbert-simone": Colbert_simone,
    "mereglis-sonya": Mereglis_sonya,
    "torvalds-tomas": Torvalds_tomas,
    "alkanarra-tristan": Alkanarra_tristan,
    "adams-ursula": AdamsUrsula,
    "de-siglioni-ursula": De_silgoni_ursula,
    "javet-eijenora": Javet_eijenora,
    "javet-erbees": Javet_erbees,
    "jasmine-harvany": Jasmine_haervany,
}

/*
return {
        "has_spoilers":false,
        "is_not_ready":false,
        "name":"",
        "fullname":<span></span>,
        "born":<span></span>,
        "age":<span></span>,
        "homeworld":<span></span>,
        "nation":<span></span>,
        "species":<span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents":<span></span>,
        "parents_adopt":<span></span>,
        "dad":<span></span>,
        "dad_adopt":<span></span>,
        "relatives":<span></span>,
        "children":<span></span>,
        "daughter":<span></span>,
        "daughter_adopt":<span></span>,
        "sister":<span></span>,
        "brother":<span></span>,
        "cousins":<span></span>,
        "uncle":<span></span>,
        "daughter":<span></span>,
        "niece":<span></span>,
        "status":<span></span>,
        "work":<span></span>,
        "img_trans":false,
        "imgs": [""],
        "farts": [""],
        "pint": "",
        "imgur": "",
        "pgns": [""],
        "appears":
            <>
            </>,
        "resume":
            <>
            </>,
        "text":
            <>
            </>
    }
 */


export function Characters(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_characters"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Кидонианское солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunCharacter name={data["name"]} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about" className="">
                <div className="about-me container">
                    <div className="section-title"><h2>{data["fullname"]}</h2></div>
                    <div className="row">
                        <div className="col-lg-3 text-center" data-aos="fade-right">
                            <GetFancyBoxImgStarsBackground500 short={data["imgs"][0].short} big={data["imgs"][0].big} />
                            {data["imgs"][1] ?
                                <GetFancyBoxImg100 short={data["imgs"][1].short}
                                    big={data["imgs"][1].big} />
                                : <></>}
                            {data["imgs"][2] ?
                                <GetFancyBoxImg100 short={data["imgs"][2].short}
                                    big={data["imgs"][2].big} />
                                : <></>}
                            {data["imgs"][3] ?
                                <GetFancyBoxImg100 short={data["imgs"][3].short}
                                    big={data["imgs"][3].big} />
                                : <></>}
                            {data["imgs"][4] ?
                                <GetFancyBoxImg100 short={data["imgs"][4].short}
                                    big={data["imgs"][4].big} />
                                : <></>}
                            {data["imgs"][5] ?
                                <GetFancyBoxImg100 short={data["imgs"][5].short}
                                    big={data["imgs"][5].big} />
                                : <></>}
                            {data["imgs"][6] ?
                                <GetFancyBoxImg100 short={data["imgs"][6].short}
                                    big={data["imgs"][6].big} />
                                : <></>}
                            {data["imgs"][7] ?
                                <GetFancyBoxImg100 short={data["imgs"][7].short}
                                    big={data["imgs"][7].big} />
                                : <></>}
                            {data["imgs"][8] ?
                                <GetFancyBoxImg100 short={data["imgs"][8].short}
                                    big={data["imgs"][8].big} />
                                : <></>}
                            {data["imgs"][9] ?
                                <GetFancyBoxImg100 short={data["imgs"][9].short}
                                    big={data["imgs"][9].big} />
                                : <></>}
                            {data["imgs"][10] ?
                                <GetFancyBoxImg100 short={data["imgs"][10].short}
                                    big={data["imgs"][10].big} />
                                : <></>}
                            {data["imgs"][11] ?
                                <GetFancyBoxImg100 short={data["imgs"][11].short}
                                    big={data["imgs"][11].big} />
                                : <></>}
                            {data["imgs"][12] ?
                                <GetFancyBoxImg100 short={data["imgs"][12].short}
                                    big={data["imgs"][12].big} />
                                : <></>}
                        </div>
                        <div className="col-lg-9 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                {data["is_not_ready"] === true ?
                                    <div className="alert alert-danger"><p>Статья в процессе написания</p>
                                    </div> : <></>}
                                <div className="col-lg-6">
                                    <ul>
                                        {data["born"] ? <li><i className="fas fa-chevron-right"></i> <strong>Год
                                            рождения: </strong>{data["born"]}</li> : <></>}
                                        {data["age"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Возраст:</strong>{data["age"]}</li> : <></>}
                                        {data["homeworld"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Родина:</strong>{data["homeworld"]}</li> : <></>}
                                        {data["nation"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Национальность:</strong>{data["nation"]}</li> : <></>}
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        {data["species"] ? <li><i className="fas fa-chevron-right"></i> <strong>Биологический
                                            вид:</strong>{data["species"]}</li> : <></>}
                                        {data["parents"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Родители:</strong>{data["parents"]}</li> : <></>}
                                        {data["parents_adopt"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Приемные:</strong>{data["parents_adopt"]}</li> : <></>}
                                        {data["dad"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Отец:</strong>{data["dad"]}</li> : <></>}
                                        {data["dad_adopt"] ? <li><i className="fas fa-chevron-right"></i> <strong>Приемный
                                            отец:</strong>{data["dad_adopt"]}</li> : <></>}
                                        {data["relatives"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Родственники:</strong>{data["relatives"]}</li> : <></>}
                                        {data["uncle"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Дядя:</strong>{data["uncle"]}</li> : <></>}
                                        {data["children"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Дети:</strong>{data["children"]}</li> : <></>}
                                        {data["daughter"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Дочь:</strong>{data["daughter"]}</li> : <></>}
                                        {data["sister"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Сестра:</strong>{data["sister"]}</li> : <></>}
                                        {data["brother"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Брат:</strong>{data["brother"]}</li> : <></>}
                                        {data["cousins"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Кузены:</strong>{data["cousins"]}</li> : <></>}
                                        {data["niece"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Племянница:</strong>{data["niece"]}</li> : <></>}
                                        {data["daughter_adopt"] ? <li><i className="fas fa-chevron-right"></i> <strong>Приемная
                                            дочь:</strong>{data["daughter_adopt"]}</li> : <></>}
                                        {data["status"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Статус:</strong>{data["status"]}</li> : <></>}
                                    </ul>
                                </div>
                                {data["work"] ? <div className="col-lg-12">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i> <strong>Место
                                            работы:</strong> {data["work"]}</li>
                                    </ul>
                                </div> : <></>}
                            </div>
                            <div className="row">
                                <ul className="characters-bar">
                                    {
                                        data["pgns"]
                                            ?
                                            <>
                                                {data["pgns"][0] ? <li><PNGLink pic={data["pgns"][0]} /></li> : <></>}
                                                {data["pgns"][1] ? <li><PNGLink pic={data["pgns"][1]} /></li> : <></>}
                                                {data["pgns"][2] ? <li><PNGLink pic={data["pgns"][2]} /></li> : <></>}
                                                {data["pgns"][3] ? <li><PNGLink pic={data["pgns"][3]} /></li> : <></>}
                                                {data["pgns"][4] ? <li><PNGLink pic={data["pgns"][4]} /></li> : <></>}
                                            </>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>

                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                            {
                                data["appears"] ?
                                    <div className="appears container">
                                        <div className="section-title"><h3>Появления <small>({data["appears"].length})</small></h3>
                                        </div>
                                        <div className="row">
                                            {data["appears"]}
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {data["resume"] ?
                <section id="resume" className="resume section-show">
                    <div className="container">
                        {data["resume"]}
                    </div>
                </section>
                : <></>
            }

            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            {data["text"]}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}