import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function EsoraEve() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Ева Эсора",
        "fullname": <span>Ева Мария <small>«Лира»</small> Эсора</span>,
        "born": <span>41 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>40</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонка"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>Григориус и Серена Эсора</span>,
        "relatives": <span>Тамира и Алек Эсора</span>,
        "work": <span><strike><TextInlineLink name={"bs_comp_cms"}
            text={"1-е диверсионное управление"} /></strike></span>,
        "imgs": [
            { "short": "bs_char_esora_eve_1_jpg_500", "big": "bs_char_esora_eve_1_jpg_full" },
            { "short": "bs_char_esora_eve_2_jpg_200", "big": "bs_char_esora_eve_2_jpg_full" },
            { "short": "bs_char_esora_eve_3_jpg_200", "big": "bs_char_esora_eve_3_jpg_full" },
            { "short": "bs_char_esora_eve_4_jpg_200", "big": "bs_char_esora_eve_4_jpg_full" },
            { "short": "bs_char_esora_eve_5_jpg_200", "big": "bs_char_esora_eve_5_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <p>(раздраженная агатонка)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Кратко о герое</h3>
                        <div className="timeline-item pb-0"><p className={"text-justify"}><em>Ева - главная
                            героиня <TextInlineLink name={"book_cydonian_girl"} text={"Кидонианки"} />, <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Кидонианской осени"} /> и
                            нескольких последующих романов. Агатонская коммандос, прошедшая через все горячие
                            точки <TextInlineLink name={"bs_state_free_worlds"} text={"Свободных миров"} />. Дочь видного
                            агатонского
                            политика, она разорвала все связи с семьей, чтобы посвятить себя работе. Ева первой
                            заметила шаблон в похищениях людей <TextInlineLink name={"bs_spec_vitruvianus"}
                                text={"витрувианами"} /> и забила тревогу,
                            несмотря на насмешки коллег. По иронии, именно преданность
                            делу сгубила карьеру девушки и вынудила пойти на конфликт со своей страной ради спасения
                            всего человечества.
                        </em></p>
                        </div>
                        <h3 className="title-bg">Образование</h3>
                        <div className="timeline-item"><h4>Академия госбезопасности ОРМК</h4><h5>21 - 13
                            лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p className={"text-justify"}><em>[ДАННЫЕ УДАЛЕНЫ], туманность <TextInlineLink
                                name={"bs_space_ardaline_network_planets"} text={"Ардалиннская сеть"} /></em></p>
                            <p className={"text-justify"}>
                                Оборонная доктрина <TextInlineLink name={"bs_state_urtm"}
                                    text={"Объединенных республик Малой короны"} /> подразумевает
                                совместную армию и спецслужбы. И хотя Ева училась именно как будущий агатонский офицер,
                                в одной аудитории с ней побывало множество людей из других государств <TextInlineLink
                                    name={"bs_space_vikoveera"} text={"сектора Виковира"} />.
                                Там она встретила немало единомышленников и впервые в жизни почувствовала себя не
                                одинокой
                                белой вороной, а частью чего-то большего и по-настоящему важного.
                            </p>
                        </div>
                        <div className="timeline-item"><h4>Высшая школа</h4><h5>35 - 21 лет <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_agathon"} /></em></p>
                            <p className={"text-justify"}>
                                Ева закончила частную школу в агатонском Парящем квартале, крайне дорогую даже по меркам
                                столицы. Пребывание там ее совершенно не впечатлило, скорее наоборот: девушка
                                едва выносила окружавших снобов с аристократичными замашками. Проблемы с поведением
                                были для нее обычным делом.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроника</h3>
                        <div className="timeline-item">
                            <h5>14-12 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"} /> (<TextInlineLink
                                name={"book_cydonian_girl"} />)</h5>
                            <p><em><TextInlineLink name={"bs_state_the_great_monarchy"} />, <TextInlineLink
                                name={"bs_state_republic_of_tiara_minor"} /></em></p>
                            <ul>
                                <li>Возвращение на <TextInlineLink name={"bs_planet_agathon"} />, трибунал и приговор к
                                    40 годам каторги, ссылка на <TextInlineLink name={"bs_planet_alkiviad"} /></li>
                                <li>Перестрелка с <TextInlineLink name={"bs_char_de_karma_ivar"}
                                    text={"Иваром"} /> на <TextInlineLink
                                        name={"bs_planet_earth"} text={"Земле"} />, которая привела к смерти товарищей
                                </li>
                                <li>Участие в вооруженном мятеже (<TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                    text={"Михъельмское восстание"} />)
                                </li>
                                <li>Знакомство с <TextInlineLink name={"bs_char_de_karma_ivar"}
                                    text={"Иваром де Кармой"} />, <TextInlineLink
                                        name={"bs_char_browdy_adam"} text={"Адамом"} />, <TextInlineLink
                                        name={"bs_char_can_beucan_gaereas"} text={"Гэри"} /> и <TextInlineLink
                                        name={"bs_char_de_levine_rosalie"} text={"Розали"} /></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>13 лет - 14 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p><em><TextInlineLink name={"bs_planet_agathon"} />, <TextInlineLink
                                name={"bs_state_free_worlds"} /></em></p>
                            <ul>
                                <li>Командировки во множество областей <TextInlineLink name={"bs_space_samborae_sector"}
                                    text={"сектора Самбора"} /> (
                                    <TextInlineLink name={"bs_planet_macktobykhar"} />, <TextInlineLink
                                        name={"bs_planet_samborae"} text={"метрополия Хнаасор"} />, <TextInlineLink
                                        name={"bs_planet_khal_vernon"} />, <TextInlineLink
                                        name={"bs_space_vernon_void"} />, <TextInlineLink name={"bs_planet_new_havana"} />)
                                </li>
                                <li>Служба в 1-м диверсионном управлении СБК</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>21 - 13 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p><em>[ДАННЫЕ УДАЛЕНЫ]</em></p>
                            <ul>
                                <li>Учеба в Академии госбезопасности ОРМК</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>41 - 21 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p><em><TextInlineLink name={"bs_planet_agathon"} /></em></p>
                            <ul>
                                <li>Разрыв отношений с семьей</li>
                                <li>Прожила до 20 лет на Агатоне</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h4 className="title-bg">Юность и карьера в СБК</h4>
                <p className="card-text text-justify">
                    История <span className="strong">Эсоры</span> не
                    похожа на историю большинства сверстниц, особенно из богатых и уважаемых семей. По
                    праву рождения она была наследницей значительного состояния и благодаря авторитету
                    семьи могла без малейших усилий стать кем угодно: от хозяйки крупного бизнеса до
                    чиновника высшего ранга. Юность девушка провела в тепличных условиях, в окружении
                    влиятельных людей и их распущенных детей. Хотя к удивлению последних выбирала не
                    вечеринки со сверстниками, а очень активные и местами опасные развлечения, которые
                    часто приводили ее больницу.
                </p>
                <p className="card-text text-justify">
                    Пресная жизнь изнеженной аристократии вгоняла Еву в
                    уныние. Она быстро поняла, что не выдержит и года кабинетной работы и потому выбрала
                    для себя карьеру офицера <TextInlineLink name={"bs_comp_cms"} text={"госбезопасности"} /> Агатона.
                    Родители
                    мольбами и угрозами пытались вернуть девушку на пусть истинный, но едва закончив
                    школу, она тут же покинула родной дом и ступила на опасный путь сотрудника Первого
                    диверсионного управления.
                </p>
                <p className="card-text text-justify">
                    Причем еще до своего первого задания Ева умудрилась
                    рассориться с семьей окончательно: в ответ на оскорбления со стороны младшей сестры
                    не удержала кулаки в карманах и обезобразила ее лицо несколькими хорошо поставленными
                    ударами. С того момента путь в отчий дом был заказан, Еву полностью вычеркнули из
                    жизни семьи Эсора.
                </p>
                <p className="card-text text-justify">
                    Она в долгу не осталась и никогда даже не
                    вспоминала о родственниках, вместо этого посвящая все время войне с пиратами на
                    окраинах цивилизации. За двенадцать лет Ева дослужилась до капитана диверсионных
                    войск и заработала уважение коллег благодаря своим навыкам, а не фамилии.
                </p>
                <h4 className="title-bg">Революция в <TextInlineLink name={"bs_state_the_great_monarchy"}
                    text={"Монархии"} /> (<TextInlineLink
                        name={"book_cydonian_girl"} />)</h4>
                <p className="card-text text-justify">
                    Незадолго до событий «Кидонианки» она
                    обнаружила
                    взаимосвязь между случаями массового исчезновения людей в разных регионах галактики и
                    начала бить тревогу. Ева посчитала их делом рук не человеческих, но к девушке
                    предсказуемо никто не прислушался. Пришлось искать помощь за пределами Службы
                    безопасности.</p>
                <p className="card-text text-justify">В это же время ее отправили в командировку на Михъельм,
                    где Ева встретила <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивара де Карму"} />.
                    Тот впечатлил ее своими тактическими
                    способностями и Эсора даже решилась не небольшую романтическую связь со своенравным
                    кидонианцем.
                </p>
                <p className="card-text text-justify">
                    Там же она познакомилась с <TextInlineLink name={"bs_char_de_levine_rosalie"} />, юной кидонианкой,
                    лишь наполовину
                    являвшейся человеком. Эсора и де Карма решили, что эта девушка - ключ не только
                    к поискам таинственных похитителей, но и к будущему человечесва. Способности Розали
                    могли бы продвинуть генетику и медицину на много веков вперед. Кроме этого общение с де
                    Левин пролило свет на некоторые детали происходящего в галактике и эти детали
                    испугали как бравую коммандос, так и бывшего адмирала.
                </p>
                <p className="card-text text-justify">
                    Несмотря на незаурядные способности, Розали следовало защитить от хищных лап Службы безопасности
                    Короны и
                    других организаций, которые с легкостью использовали бы кидонианку для создания оружия.
                    Поэтому, когда коллеги Эсоры узнали о странностях де Левин, Ивар без колебаний застрелил их на
                    месте.
                </p>
                <p className="card-text text-justify">
                    Следуя присяге, Ева должна была отомстить Ивару, но сделала другой выбор. Несмотря на
                    противоречивые чувства, она согласилась с мыслью,
                    что о Розали не должны знать такие организации как СБК. Поэтому Эсора не стала защищать
                    коллег и вознамерилась вернуться на Агатон, чтобы, используя полученную во время
                    восстания информацию, предупредить агатонцев о грядущем вторжении пришельцев.
                </p>
                <p className="card-text text-justify">
                    Несмотря на удачный исход <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                        text={"Михъельмского восстания"} />, для Евы все
                    закончилось не очень
                    радужно: коллеги догадались, что Эсора причастна к смерти своего начальника и отправили девушку
                    под трибунал.
                </p>
                <p className="card-text text-justify">Еву осудили на
                    40 лет каторги, лишили всех званий и наград. В конце «Кидонианки» Эсора
                    отбыла в исправительную колонию
                    «Алкива» на <TextInlineLink name={"bs_planet_alkiviad"} text={"Алкивиаде-4"} />.</p>
                <h4 className="title-bg">Занимательные факты</h4>
                <p className="card-text text-justify">До встречи с Розали никогда в жизни не пробовала зеленый чай.</p>
                <p className="card-text text-justify">В изначальной версии текста у Евы был целый отряд
                    коммандос в подчинении, их звали «Кошки Велимора». Но за ненадобностью он был вырезан
                    (как и планета Велимор), остался лишь персонаж по имени «Ап» – его мы встретим в
                    <TextInlineLink name={"book_cydonian_autumn"} text={"«Кидонианской осени»"} />.</p>
                <h4 className="title-bg">Эволюция героя</h4>
                <p className="card-text text-justify">
                    <span className="strong">Эсора</span> вообще не
                    должна была появиться в сюжете. Просто в какой-то момент стало ясно, что нужно
                    сделать компанию главной героини пестрее, чтобы разнообразить и ее, и читательский
                    опыт. На сцену вышли агатонцы, которые были как раз под рукой. В сюжет пришлось
                    сначала ввести Еву, а затем и целый отряд коммандос, прозванный «Кошки Велимора». В
                    той версии на Велиморе готовили диверсантов для переброски в стан землян, чтобы они
                    расшатали ситуацию в стране и Еве полагалось стать главным острием этой атаки.
                </p>
                <p className="card-text text-justify">
                    Затем стало ясно, что вводить целый отряд – это
                    перебор, ведь каждого нужно было представить читателю. Поэтому в <TextInlineLink
                        name={"book_cydonian_girl"} text={"«Кидонианке»"} /> остался
                    только <TextInlineLink name={"bs_char_kaory_nazil"} text={"Назиль"} />, который перестал
                    быть ее подчиненным и превратился в напарника.
                </p>
                <p className="card-text text-justify">
                    В какой-то момент была вырезана и предыстория с
                    Велимором, т.к. сюжетная линия и без того грозила превратиться в классический
                    неподъемный трехтомник. В тот же момент из повествования исчез и ее наставник,
                    полковник Серж Барр, прожженный вояка, получивший имя в честь моего старого
                    друга.
                </p>
                <p className="card-text text-justify">
                    В отряд к де Карме Ева попала не сразу. Поначалу
                    у нее была своя сюжетная линия, а с основными героями она пересекалась с разной
                    периодичностью. Она должна была выполнять тайные задания канцлера, который искал
                    оставленные <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /> капсулы
                    времени, в которых хранились чертежи давно забытого оружия. Затем такой сюжетный
                    ход показался мне чересчур банальным: погибшая цивилизация и древние артефакты в
                    музеях, способные погубить вселенную. Чем-то знакомым попахивает, да?
                </p>
                <p className="card-text text-justify">
                    В той же версии сюжета <TextInlineLink name={"bs_char_the_whip"}
                        text={"Бича"} /> первой
                    встретила именно Ева,
                    а не <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розали"} />: он настиг
                    ее и отобрал капсулу времени. А вместе с этим хорошенько избил, оставив неизгладимые
                    впечатления от первого знакомства.
                </p>
                <p className="card-text text-justify">
                    Затем стало ясно, что не только капсулы времени
                    выглядят скучно, но и политические интриги канцлера. Мир оказался перегружен,
                    повествование топталось на месте из-за необходимости обслуживать все линии, часть из
                    которых вообще толком ничего полезного не несла.
                </p>
                <p className="card-text text-justify">Поэтому Еву было решено перебросить к <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"де Карме"} /> и лишить собственной
                    истории. Так бедняжка стала из незаменимого персонажа просто ворчливой агатонкой в
                    рации. Но ничего, дальше она это исправит, обещаю.
                </p>
            </>
    }
}
