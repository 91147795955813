import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Alkanarra_siel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Сиель Алканарра",
        "born": <span>53 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>52</span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "fullname": <span>Сиель Алканарра</span>,
        "work": <span>осужденный военный преступник, <s>Монарх <TextInlineLink
            name={"bs_state_the_great_monarchy"} text={"Великой Монархии"} /></s></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_alkanarra_siel_1_jpg_500", "big": "bs_char_alkanarra_siel_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О герое</h3>
                <p className="card-text text-justify">
                    <span className="strong">Сиель</span> – наследный правитель <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Великой Монархии"} />, потомок <TextInlineLink
                        name={"bs_char_alkanarra_tristan"} text={"Тристана Завоевателя"} /> последний
                    из рода Алканарра. Его отец начал <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Первую Галактическую"} />, а затем погиб в
                    одном
                    из ее сражений.
                </p>
                <p className="card-text text-justify">
                    На Сиеля, которого растили в строгости и дисциплине, это оказало плохое
                    впечатление. С самого детства парень был уверен, что отец едва ли не всемогущ и способен на любые
                    поступки без малейших последствий. Когда стало ясно, что Монарх - обычный человек, которого
                    почему-то
                    просто все слушаются... это был отрезвлающим опытом.
                </p>
                <p className="card-text text-justify">
                    По этой причине Сиель решил править империей мягко, без лишней драмы. Однако его обучение и
                    воспитание
                    не были завершены: слабохарактерный и ленивый от рождения, он остался без присмотра в самый опасный
                    период существования страны. Парень не понимал, в какие сложные времена живет и самоустранился от
                    руководства Монархией. Большую часть решений (если не все) принимали разного рода чиновники - это
                    само по себе хорошо, поскольку институты всегда лучше единоличного управления. К сожалению качество
                    этих чиновников было крайне низким, так как новоиспеченный Монарх не имел понятия о том, как
                    отличить
                    хорошего управленца от бездаря и коррупционера.
                </p>
                <p className="card-text text-justify">
                    Это привело к тому, что послевоенные десятилетия прошли для империи под знаком разложения, упадка
                    и эксплуатации окраинных регионов. Для удержания недовольных миров под контролем использовались
                    остатки силового аппарата, а не мудрая экономическая политика или хотя бы ослабление давления на и
                    без того бедные планеты.
                </p>
                <p className="card-text text-justify">
                    Монарх же в это время страдал ерундой во дворце и не имел понятия, что происходит на окраинах.
                    Поэтому когда недовольные долгими годами упадка михъельмцы собрали по всей стране союзников и
                    подняли <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"вооруженный мятеж"} />, это
                    стало для Сиеля сюрпризом.

                </p>
                <p className="card-text text-justify">
                    А тот факт, что он не поддерживал в надлежащем состоянии даже тот силовой блок, на котором держался
                    трон, привел к быстрому и безоговорочному поражению правительственных войск.
                </p>
                <p className="card-text text-justify">
                    После случившегося во время <TextInlineLink name={"book_cydonian_girl"}
                        text={"«Кидонианки»"} /> восстания
                    Сиель был пленен михъельцами на Земле. Он подписал ряд документов, которые
                    закрепили право на самоопределение всех миров в составе Монархии, а династия Алканарра официально
                    оказалась от верховной власти и передала ее парламенту. Сам парламент, до того служивший просто
                    совещательным органом, за считанные недели добил Монархию, выдавая независимость дюжине
                    миров в день.
                </p>
                <p className="card-text text-justify">
                    После этого Сиеля отправили под трибунал как военного преступника, так как во время восстания
                    вскрылись его приказы использовать оружие массового поражения на мятежных планетах. Последний из
                    Алканарра был осужден на пожизненное заключение, отбывать которое остался на Земле.
                </p>
            </>
    }
}
