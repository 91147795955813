import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vernon_void() {
    return {
        "date": <span>официально не заселена</span>,
        "type": <span>территория</span>,
        "owned": <span><TextInlineLink name={"bs_state_free_worlds"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"} /></span>,
        "imgs": [{ "short": "bs_wiki_vernon_void_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Вернонская пустота</span> – скопление
                    терраформированных, но практически незаселенных миров на окраине сектора Самборы. Почти все
                    землеподобные планеты здесь доведены до стандартов <TextInlineLink name={"bs_planet_earth"}
                        text={"матушки-Земли"} /> несколько
                    тысячелетий назад, на заре
                    световых перелетов. Предки планировали заселить их через пять-шесть веков после отправки зондов, но
                    политическая нестабильность и череда войн в <TextInlineLink name={"bs_space_the_old_space"}
                        text={"Старом космосе"} /> положили конец
                    грандиозным планам по экспансии.</p>
                <p className="card-text text-justify">Сейчас почти все планеты здесь предоставлены сами себе и уже
                    успели обзавестись уникальными биосферами. Но хватает и миров, подконтрольных криминальным
                    синдикатам: после потери <TextInlineLink name={"bs_space_samborian_traverse"}
                        text={"Самборианского траверса"} /> в неравной битве
                    с <TextInlineLink name={"bs_people_cydonians"} text={"кидонианцами"} />, <TextInlineLink
                        name={"bs_comp_feathered_serpent"} text={"Пернатый змей"} /> переместил в эти места
                    множество своих тайников и секретных баз.</p>
            </>
    }
}
