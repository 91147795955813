import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { TextInlineLink } from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";

function Book_Free_traders() {
    document.title = "Кидонианское солнце - Вольные торговцы"

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book" />  Вольные торговцы</span>} />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_free_traders_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Вольные торговцы"
                            src={imgs["book_free_traders_jpg_ru"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Вольные торговцы<small><br />или Путеводитель по Млечному пути для
                        перелетных птиц (часть II)</small></h1></div>
                    <div className="row">
                        <blockquote>
                            <p>Ближе к публикации здесь будут цитаты, а пока лишь скрип исполинских сосен нарушает
                                тишину...</p>
                            <small><TextInlineLink name={"bs_char_the_whip"} /></small>
                        </blockquote>
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Рассказ пока не готов
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="15" aria-valuemin="0"
                                    aria-valuemax="100" style={{ "width": "15%;" }}>5%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt" /> <h6>Статус:</h6> <strong><span
                                className="text-info">готовится к работе</span></strong></li>
                            <li><i className="far fa-list-alt" /> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Кидонианское солнце #7"} /></li>
                            <li><i className="fas fa-angle-double-left" /> <h6>Предыдущая:</h6> <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Контакт у Таманрасетт"} /></li>
                            <li><i className="fas fa-angle-double-right" /> <h6>Следующая:</h6> <TextInlineLink
                                name={"book_black_sun"} text={"Черное солнце"} /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className=" well">
                        <p className="card-text text-justify">Рано для спойлеров 😬</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li>Галерея пока пуста</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { Book_Free_traders };
