import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tiora() {
    return {
        "date": <span>2018 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>22 млрд. (18 на поверхности)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"} />, тиорийцы (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.06 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную, испорчена</span>,
        "system": <span>Тиорийский протекторат</span>,
        "sector": <span><TextInlineLink name={"bs_space_fiorella_sector"} text={"Флёр"} /></span>,
        "type": <span>землеподобная, <TextInlineLink name={"bs_universe_planet_types"} text={"промышленная"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [{ "short": "bs_wiki_tiora_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Тиора</span> – малозначительная
                    ассоциированная (частично независимая) планета в составе Приоритета. Знаменита в первую очередь
                    тем, что местные радикальные сторонниики независимости едва не устроили геноцид кидонианцев: они
                    раздобыли антиматериальную ракету и угрожали запустить ее на <TextInlineLink
                        name={"bs_planet_cydonia"} text={"столицу страны"} />. В ходе операции,
                    санкционированной <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорией"} /> и
                    спланированной <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Иваром де Кармой"} />, все
                    террористы погибли, но вместе с ними и множество гражданских.</p>
            </>
    }
}

