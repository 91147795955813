import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Sun_zero() {
    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>2</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Нулевой"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, неизвестно</span>,
        "people": <span>земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>281 млрд.</span>,
        "imgs": [{ "short": "bs_wiki_sun_zero_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Нулевое</span> или <span
                        className="strong">Земное солнце</span> – родная звезда человечества. От нее традиционно считают
                    галактические расстояния во все стороны: сто световых лет к ядру / к рукаву такому-то / к
                    скоплению такому-то и так далее. Хотя, когда человечество расползлось уже на много тысяч лет от
                    дома, такая система чертовски неэффективна. Можно сказать, это дань традиции.
                </p>
                <p className="card-text text-justify">
                    Говорят, после того, что случилось в <TextInlineLink
                        name={"book_cydonian_girl"} text={"«Кидонианке»"} />, Марс тоже подумывает о независимости... вот так
                    дела.
                </p>
            </>
    }
}

