import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Earthlings() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} />,
        "state": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_alkanarra_siel_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_mereglis_sonya_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_brienne_de_estrada_1_jpg_200"} /></li>
        </ul>,
        "langs": <TextInlineLink name={"bs_lang_earth"} text={"земной"} />,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Земляне</span> – уроженцы не только
                    родины человечества, но и соседних территорий.
                    До <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактической"} /> многие жители Великой
                    Монархии считали, что земная культура, будучи самой древней из человеческих, должна доминировать
                    в галактике. Земля всегда претендовала на лидерство среди стран с монархическим укладом власти и
                    даже пыталась финансировать государственные перевороты в республиках. В конечном итоге это
                    привело к глобальному конфликту, который показал истинную обстановку в галактике: никому нет
                    дела до землян и их претензий на лидерство над бывшими колониями. Более того: многие миры готовы
                    взяться за оружие, лишь бы не быть частью сферы земного влияния.</p>
                <h3 id={"language"} className="title-bg">Язык</h3>
                <p className="card-text text-justify"><span
                    className="strong">Земной</span> – традиционный человеческий язык, пронесенный людьми сквозь все
                    тысячелетия расселения. Во времена досветовых перелетов был популярен в основном в
                    мирах <TextInlineLink name={"bs_space_the_old_space"} text={"Старого космоса"} /> и в молодых
                    колониях землян,
                    но из-за слабой коммуникации между планетами неизбежно мутировал и в каждом мире появлялся свой
                    уникальный диалект. После распространения сверхсветовых путешествий и смешения культур,
                    необходимость общения на одном языке выросла настолько, что оригинальный земной вытеснил почти все
                    остальные. Корни современного галактического языка уходят в английский и корейский, сочетая в себе
                    черты обоих сразу: как фонетические, так и грамматические. Например, многие слова и термины земляне
                    могут написать в виде иероглифа, а могут и словом.</p>
            </>
    }
}
