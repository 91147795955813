import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Raasal_ajlyrien_dominion() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>желтые карлики и красный гигант</span>,
        "stars": <span>3</span>,
        "planets": <span>21</span>,
        "habitable": <span>2</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"} /></span>,
        "sector": <span>Травалсар</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "people": <span>айлири (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "area": <span>Акронская небула</span>,
        "population": <span>255 млрд.</span>,
        "imgs": [{ "short": "bs_wiki_ajliraen_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            <>
            </>,
        "text":
            <>
                <p class="card-text text-justify"><span class="strong">Раасал, Айлирэн и Доминион</span> – система из
                    двух желтых карликов-побратимов Земного солнца и
                    одного красного почти гиганта. Дом для Айлирэна и еще двух десятков планет разного размера.</p>
            </>
    }
}

