import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ternerian_end() {
    return {
        "date": <span>-</span>,
        "type": <span>территория</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "people": <span>агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_tamanrasett_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Тернерианский предел</span> – один из
                    рубежей
                    человеческой экспансии. Удобно расположился на конце крупной галактической <TextInlineLink
                        name={"bs_tech_hyperrun"} text={"гипертрассы"} />, у кромки Объединенных
                    республик. Традиционно тут селятся агатонцы и остальные демократы, но и выходцев из других мест
                    тоже приветствуют: главное – это мечта покорять новые рубежи. Многие миры еще даже не начинали
                    терраформировать, но готовых к заселению уже после <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Галактической"} /> было
                    больше сотни.</p><p
                        className="card-text text-justify">При этом здесь довольно слабая <TextInlineLink name={"bs_tech_grn"}
                            text={"ИнтерСеть"} /> и
                    полно белых пятен, поэтому пропажи
                    кораблей и целых флотов не редкость. Эдакий взаправдашний Бермудский треугольник. Как факт, все
                    колонисты подписывают договор, одним из пунктов которого признают ненулевую вероятность кануть без
                    следа в пучине космоса по дороге к новому дому и снимают ответственность за это с
                    правительства <TextInlineLink name={"bs_planet_agathon"} text={"Агатона"} />.</p>
            </>
    }
}

