import { Tag } from "antd";
import wiki from "./wiki.json";

export function CydonianGirlTag() {
    return <Tag color="red">{wiki["book_cydonian_girl"].name}</Tag>
}
export function BeaconThievesTag() {
    return <Tag color="orange">{wiki["book_hyperbeacons"].name}</Tag>
}
export function CydonianAutumnTag() {
    return <Tag color="green">{wiki["book_cydonian_autumn"].name}</Tag>
}
export function BonecollectorsTag() {
    return <Tag color="volcano">{wiki["book_bonecollectors"].name}</Tag>
}
export function FreeTradersTag() {
    return <Tag color="purple">{wiki["book_the_free_traders"].name}</Tag>
}
export function TamanrasettContactTag() {
    return <Tag color="lime">{wiki["book_tamanrasett_contact"].name}</Tag>
}
export function HelleonColdTag() {
    return <Tag color="cyan">{wiki["book_helleon_cold"].name}</Tag>
}
export function BlackSunTag() {
    return <Tag color="#cd201f">{wiki["book_black_sun"].name}</Tag>
}





