import { CrumbsBlackSunBook } from "../../../../components/Bread_Crumbs";
import {
    TextInlineLink, TextInlineLinkCustomClass
} from '../../../../components/cms/wiki_functions';
import wiki from "../../../../components/cms/wiki.json";
import imgs from "../../../../components/cms/images_repo.json";
import parse from 'html-react-parser';

export function GlossaryPictureItem(props) {
    let descr = parse(wiki[props.repo].popup)
    if (props.descr !== undefined) {
        descr = props.descr
    }

    let img = imgs["general_empty_500_500"].link
    if (props.img !== undefined) {
        img = props.img
    }

    return <div className="card mb-3">
        <div className="row g-0">
            <div className="col-md-4">
                <a href={wiki[props.repo].link}>
                    <div className=" card-img-top" style={{ "width": "100%", "height": "100%", "margin": "auto" }}>
                        <div className="bg-image" style={{
                            "maxWidth": "100%",
                            "height": "100%",
                            "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                        }}>
                            <img style={{ "width": "100%" }} src={img} alt={props.name} />
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-8">
                <div className="card-body">
                    <a href={wiki[props.repo].link} className="a-black"><h5
                        className="card-title">{wiki[props.repo].name}</h5></a>
                    <p className="card-text">{descr}</p>
                </div>
            </div>
        </div>
    </div>
}

export function Glossary() {
    document.title = "Кидонианка и Кидонианское солнце"

    return (
        <div>
            <CrumbsBlackSunBook name={<span><i className="fa-solid fa-w" />  Энциклопедия</span>} />

            <div key="mainRow" className="row">
                <div className="col-lg-12 well" style={{ "paddingBottom": "1em" }}>
                    <p className="card-text text-justify">Здесь собрана вся каноничная информация о
                        мире <TextInlineLink name={"bs_series"} popup={""} text={"«Кидонианского солнца»"} />.
                    </p>
                </div>
            </div>
            <hr />
            <div key="mainRow" className="row ">
                <div className={"col-lg-12"}>
                    <p className="card-text text-justify">
                        Страницы разбиты на простые категории:
                    </p>
                </div>
            </div>
            <div key="mainRow" className="row ">
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_timeline"].link}><i className="fa-solid fa-clock-rotate-left" /> История</a>
                        </li>
                        <li><a href={wiki["bs_characters"].link}><i className="fa-solid fa-user-tie" /> Герои</a></li>
                        <li><a href={wiki["bs_places"].link}><i className="fa-solid fa-earth-asia" /> Места</a></li>
                        <li><a href={wiki["bs_organizations"].link}><i className="fa-regular fa-building" /> Организации</a></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_maps"].link}><i className="fa-solid fa-map-location-dot" /> Карты</a>
                        </li>
                        <li><a href={wiki["bs_universe"].link}><i className="fa-solid fa-scroll" /> Статьи о мире</a>
                        </li>
                        <li><a href={wiki["bs_technologies"].link}><i className="fa-solid fa-flask" /> Наука и
                            технологии</a></li>
                        <li><a href={wiki["bs_machinery"].link}><i className="fa-solid fa-satellite" /> Техника</a>
                        </li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><a href={wiki["bs_gallery"].link}><i className="fas fa-palette" /> Галерея</a></li>
                        <li><a href={wiki["bs_people"].link}><i className="fa-solid fa-user-group" /> Народы</a></li>
                        <li><a href={wiki["bs_creatures"].link}><i className="fa-solid fa-dragon" /> Существа</a></li>
                        <li><a href={wiki["bs_weapons_and_tech"].link}><i className="fa-solid fa-gun" /> Оружие</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row" style={{ "paddingTop": "1em" }}>
                <div className="d-grid gap-2">
                    <a className="btn btn-main" href={wiki["bs_wiki_all"].link}>ПОЛНЫЙ АЛФАВИТНЫЙ СПИСОК</a>
                    <a className="btn btn-main" href={"/search"}>ПОИСК</a>
                </div>
            </div>
            <h2 className="title-bg text-center" style={{ "marginTop": "1em" }}>САМОЕ ПОПУЛЯРНОЕ</h2>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_characters"].link}>ГЕРОИ</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_de_levine_rosalie_1_png_500_t"].link}
                        repo={"bs_char_de_levine_rosalie"}
                        descr={<span>Розали де Левин – дочь <TextInlineLink name={"bs_char_the_whip"}
                            text={"Бича"} popup={""} /> и Клары де Левин, та самая кидонианка из <TextInlineLink
                                name={"book_cydonian_girl"} text={"«Кидонианки»"} popup={""} /></span>} />
                    <GlossaryPictureItem img={imgs["bs_char_ursula_adams_1_jpg_500"].link} repo={"bs_char_ursula_adams"}
                        descr={<span>Урсула Адамс – автор «Путеводителя по Млечному Пути для перелетных птиц», героиня <TextInlineLink
                            name={"book_bonecollectors"} text={"«Собирателей костей»"}
                            popup={""} /> и <TextInlineLink name={"book_the_free_traders"}
                                text={"«Вольных торговцев»"}
                                popup={""} /></span>} />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_esora_eve_1_jpg_500"].link} repo={"bs_char_esora_eve"}
                        descr={<span>Ева Эсора – ворчливая и крайне опасная <TextInlineLink
                            name={"bs_people_agathonians"} text={"агатонка"}
                            popup={""} />, героиня  <TextInlineLink name={"book_cydonian_girl"}
                                text={"«Кидонианки»"}
                                popup={""} />,  <TextInlineLink
                                name={"book_cydonian_autumn"} text={"«Кидонианской осени»"} popup={""} /> и других романов</span>} />
                    <GlossaryPictureItem img={imgs["bs_char_the_whip_1_png_500"].link} repo={"bs_char_the_whip"}
                        descr={<span>Бич – пришелец-нудист, щуплый, но очень агрессивный, герой всех частей «Кидонианского солнца»</span>} />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_characters"} text={"Посмотреть всех"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_planets"].link}>МИРЫ</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["arville_500"].link} repo={"bs_planet_arville"} />
                    <GlossaryPictureItem img={imgs["cydonia_500"].link} repo={"bs_planet_cydonia"} />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["new_havana_500"].link} repo={"bs_planet_new_havana"} />
                    <GlossaryPictureItem img={imgs["tamanrasett_500"].link} repo={"bs_planet_tamanrasett"} />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_planets"} text={"Посмотреть все"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                    href={wiki["bs_people"].link}>НАРОДЫ</a>
                </h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_de_karma_ivar_1_png_500"].link}
                        repo={"bs_people_cydonians"} />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_char_browdy_adam_1_png_500"].link}
                        repo={"bs_people_alamarsie"} />
                </div>
                <div className="d-grid gap-2">
                    <TextInlineLinkCustomClass class={"btn btn-main"} name={"bs_people"} text={"Посмотреть все"} />
                </div>
            </div>

            <div key="mainRow" className="row well">
                <h4 style={{ "marginTop": "auto" }} className='text-center'>ДРУГОЕ</h4>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_cydonians_map_ru"].link}
                        repo={"bs_state_cydonian_priority"} />
                    <GlossaryPictureItem repo={"bs_weaponry_terminology"} />
                    <GlossaryPictureItem img={imgs["bs_wiki_serpentara_emplumara"].link} repo={"bs_comp_feathered_serpent"} />
                </div>

                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_religion_arthur_1"].link}
                        repo={"bs_universe_religion_arthurians"} />
                    <GlossaryPictureItem img={imgs["bs_char_ursula_adams_2_jpg_500"].link}
                        repo={"bs_universe_bird_of_passage_guide_to_milky_way"} />
                    <GlossaryPictureItem img={imgs["bs_wiki_northern_star_of_niradanhae"].link}
                        repo={"bs_tech_northern_star_of_niradanhae"} />
                </div>
            </div>
        </div>
    )
}