import { CrumbsBlackSunSpace } from "../../../../../components/Bread_Crumbs";
import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";
import wiki from "../../../../../components/cms/wiki.json";

export function Cydonian_priority() {
    document.title = "Кидонианский Приоритет || Кидонианка и Кидонианское солнце"
    return (
        <>
            <CrumbsBlackSunSpace name={"Кидонианский Приоритет"} />

            <div className="row">
                <div className="col-lg-12">
                    <div className="row ">
                        <div className="col-lg-10 well">
                            <blockquote>
                                <p className="card-text text-justify">В Приоритете официальная агитация – это вообще
                                    путевка в тюрьму.
                                </p>
                                <small><TextInlineLink name={"bs_char_esora_eve"} text={"Ева Эсора"} />, <TextInlineLink
                                    name={"book_cydonian_girl"} text={"«Кидонианка»"} /></small>
                            </blockquote>
                            <div className="row">
                                <ul className="characters-bar">
                                    <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                                </ul>
                            </div>
                            <h4>
                                Выдержки из <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                    text={"«Путеводителя по Млечному Пути для перелетных птиц»"} /> Урсулы
                                Адамс <small>(3 г. <TextInlineLink name={"bs_timeline"}
                                    text={"ДТ"} />)</small> и
                                «Homo sapiens: правдивая история выходцев со Старой Земли» Юлиана Харари, 265 г. ПТ
                            </h4>

                            <span id={"society"}></span>

                            <p className="card-text text-justify">Кидонианский Приоритет (кид. Kydonneá Praoritas или
                                сокращенно Kypra) – третья по размерам и населению (1.71 трлн. чел.)
                                страна в галактике и вторая в <TextInlineLink name={"bs_space_brienne_cluster"}
                                    text={"Кластере Бриэнн"} /> (после <TextInlineLink
                                    name={"bs_state_ajliraenian_domination"} text={"Доминации"} />).
                                Страна занимает 23 сектора космоса с бесчисленным количеством звездных систем и планет.
                                Населенных миров (от 100 жителей)
                                в ней 2 135, а <TextInlineLink name={"bs_universe_planet_types"}
                                    text={"метрополий"} /> (от 1 млрд.) - 311.
                                Все это добро делится на 532 полноправных региона и 135 т.н. малых регионов (максимально
                                зависимых от центрального правительства). В стране 27 автономных республик с крайне
                                широким самоуправлением, 16
                                протекторатов и 11 ассоциированных планет-государств, которые формально имеют свое
                                правительство,
                                но контроль за внешней политикой и обороной добровольно отдали в руки кидонианцев.
                            </p>
                            <p className="card-text text-justify">
                                Слово «Приоритет» – дань традиции, оставшееся с самой зари современной цивилизации. В
                                те времена страной правили деспотичные
                                кидонианские элиты, несколько веков подавлявшие права соседних планет на
                                самоопределение. Ничем хорошим это не закончилось,
                                поэтому кидонианцы быстро пришли к выводу, что строить содружество лучше, чем пытаться
                                удержать друзей силой.
                            </p>
                            <p className="card-text text-justify">Исторически принято разделять граждан и подданных
                                Приоритета: первые являются жителями кидонианских регионов и Малых территорий, вторые -
                                протекторатов, ассоциированных планет-государств или зависимых территорий, которых у
                                Кидонии достаточно много по всему кластеру. Подданство не налагает никаких
                                ограничений на права человека и служит лишь для декоративных целей.</p>
                            <p className="card-text text-justify">
                                Граничит Приоритет с <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                    text={"Айлирэнской Доминацией"} /> по рукаву Персея,
                                с <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархией"} />по
                                направлению
                                к ядру галактики (рукав Ориона), со <TextInlineLink name={"bs_state_free_worlds"}
                                    text={"Свободными мирами"} /> по
                                рукавам Внешнему и Персея, и с окраинными частями галактики по оставшейся территории.
                            </p>
                            <p className="card-text text-justify">
                                Столица Приоритета, <TextInlineLink name={"bs_planet_cydonia"}
                                    text={"Кидония"} /> (Kydon'á) (город Старозамковый),
                                считается жемчужиной всего кластера, его культурным, экономическим и политическим
                                центром.
                            </p>
                            <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ru"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Кидония"
                                    src={imgs["bs_wiki_cydonia_map_ru"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                Форма правления в Приоритете – высоколиберализированная конституционная монархия,
                                в которой индекс личной свободы превышает показатели любого демократического
                                государства-современника.
                                Причина сохранения монархического уклада власти в таких условиях: историческая ставка
                                кидонианцев
                                на общественные институты, зависящие от талантливых людей и умных алгоритмов больше, чем
                                от неподвижной системы правил.
                                По сути Приоритет – чистой воды меритократия, где власть вверяется в руки достойнейших,
                                но подкрепляется
                                и контролируется специально разработанными алгоритмами принятия и оценки
                                решений. И рейтингами, безумным количеством рейтингов и опросов, за каждым из которых
                                следит целая экспертная комиссия, состоящая в основном из выдающихся ученых, презирающих
                                вязкие болота бюрократии.
                            </p>
                            <p className="card-text text-justify">
                                Можно также сказать, что страной руководит набор искусственных интеллектов, которые
                                принимают или как минимум утверждают
                                множество общественных решений: от планирования городов, до расчета способов повышения
                                коэффициента счастья
                                от празднования <TextInlineLink name={"bs_universe_doab"}
                                    text={"Дня всех бомб"} /> (кидонианского дня
                                независимости). Насчет
                                последнего не шучу - кидонианцы всерьез озабочены индексом счастья и его формальным
                                повышением.
                            </p>
                            <p className="card-text text-justify">Алгоритмы также следят за целесообразностью
                                использования бюджета, подозрительными сделками и другими околокоррупционными
                                ситуациями. Разумеется, люди в этой системе принимают не меньше решений, чем роботы.
                                Биологические
                                и электронные мозги скорее дополняют друг друга, оценивая ситуацию по своим уникальным
                                критериям.
                                Сегодняшний лидер, королева <TextInlineLink name={"bs_char_van_gloria_adel"} />, активно
                                развивала этот подход на протяжении всего правления и нажила немало врагов, искореняя
                                разного рода кумовство и конфликты интересов.
                            </p>
                            <p className="card-text text-justify">
                                Грамотная система сдержек и противовесов сделала Приоритет на удивление устойчивым к
                                общественным волнениям, даже гражданским войнам, которых в его истории было немало.
                                Именно первые опустошающие братоубийственные конфликты поставили кидонианцев на путь
                                меритократии, высокой автономности регионов и
                                роботизации управления: было решено во что бы то ни стало предотвратить ущемление
                                интересов
                                даже самых противоположных по взглядам групп, чтобы разногласия больше никогда не
                                вылились в насилие.
                                С этой целью отдельные сектора получили широкую автономию от Кидонии, а локальные
                                звездные системы - от секторов.
                                Немало также планет, имеющих широкие полномочия по самоуправлению или даже не платящих
                                отчисления в общий бюджет.
                            </p>
                            <a data-fancybox="true" data-caption="Кидонианский ландшафт"
                                href={imgs["cydonian_landscape_1"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Кидония"
                                    src={imgs["cydonian_landscape_1"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                Несмотря на ярко выраженную федеративность системы, разделение властей в Приоритете
                                совершенно не классическое. Законодательный орган, однопалатный парламент Приумвират,
                                состоит из лордов, имеющих огромные законодательные и при этом исполнительные
                                полномочия.
                                Над ними стоит король, верховный лидер страны, не обладающий правом писать законы, зато
                                уполномоченный народом на самые безумные управленческие решения, если они сулят успех и
                                процветание стране. И за всем этим следит глобальный ИИ «Система доверия», который
                                проверяет
                                все аспекты жизни и работы чиновников (включая короля): от здоровья и распорядка дня,
                                до
                                эффективности рабочих активностей. В случае обнаружения проблем ИИ может инициировать
                                импичмент короля и любого другого чиновника, если его аргументы будут поддержаны народом
                                на прямом голосовании.
                            </p>
                            <p className="card-text text-justify">
                                Парламент и монарх часто находятся в серьезной оппозиции и даже открытом конфликте, что
                                всячески поощряется общественным укладом страны. Конкуренция в поисках лучших решений
                                и самых талантливых людей – норма жизни в Приоритете и считается источником
                                всякого развития. При этом лорды Приумвирата, несмотря на широту полномочий, вынуждены
                                постоянно оглядываться на опросы и оценки экспертных комиссий, а также расчеты,
                                проведенные
                                профильными научными институтами. Например, за сотни лет дискуссий, они так и не смогли
                                разрешить ношение оружия гражданскими: исследования ученых и модели, построенные
                                искусственным
                                интеллектом, показали повышение уровня насилия в стране в случае принятия подобных
                                поправок.
                                И ниакие идеологические или эмоциональные аргументы не могут стоять в Приоритете над
                                выводами
                                алгоритмов.
                            </p>
                            <p className="card-text text-justify">
                                Именно так и работает меритократия в кидонианском исполнении: лучшие из лучших,
                                конкурируя по объективно-проверяемым
                                управленческим успехам на местах, получают должности лордов и королей. Затем они же
                                сражаются
                                друг с другом, а также со множественными профильными учеными и алгоритмами, выискивая
                                самый лучший
                                способ управлять страной и обществом. Само общество контролирует работу чиновников всех
                                уровней, в любой момент получая информацию об их деятельности, расходах и уровне
                                эффективности.
                                А неэффективных в Приоритете не жалуют.
                            </p>
                            <h3 id={"education-and-science"} className="title-bg">Образование и наука</h3>
                            <a href={wiki["bs_char_hartrey_kellays"].link}>
                                <div className="" style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                    <div className="bg-image-sm"
                                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                        <img alt="header" src={imgs["banner_kellays"].link}
                                            style={{ "maxWidth": "100%" }} />
                                    </div>
                                </div>
                            </a>

                            <p className="card-text text-justify">
                                Наука в Приоритете не просто полезный инструмент - она считается краеугольным камнем
                                общества, а научный метод - одним из
                                величайших изобретений в истории. Слово «ученый» кидонианцы
                                используют в исключительных случаях и только по отношению к конкретным людям,
                                посвятившим жизнь умножению
                                суммы знаний человечества. Бросаться подобным эпитетом на ветер, тем более произносить
                                фразы
                                в духе "ученые выяснили" не принято и считается плохим тоном. Это вполне логично,
                                учитывая, что большую часть решений о будущем кидонианского общества принимают именно
                                ученые и разработанные ими алгоритмы.
                            </p>
                            <p className="card-text text-justify">
                                По этой причине образованию в Приоритете уделяется огромное внимание. Традиционная
                                система
                                начальных и средних школ переработана кидонианцами согласно их мировоззрению: вместо
                                обычного набора обязательных предметов, ребенок получает начальный курс всех возможных
                                наук и со временем начинает самостоятельно регулировать нагрузку по каждой из них внутри
                                минимального количества учебных часов на каждую неделю.
                            </p>
                            <p className="card-text text-justify">
                                В университетах и академиях этот принцип возведен в абсолют: помимо обычной очной
                                системы,
                                можно учиться совершенно не посещая лекционный зал. Учитывая, что навыки проверяет
                                ИИ, стоимость такого обучения очень невысока, зачастую даже нулевая, а количество мест
                                – не ограничено. При этом студенты не получают диплом какой-либо специальности, а
                                набирают
                                так называемые катры (от киндонианского ca-tre, троица). Катр – это от трех до
                                теоретически бесконечного числа навыков,
                                которые необходимы для работы на той или иной должности. При этом речь не о предметах в
                                широком понимании, а
                                именно об уровне знаний в конкретных темах. Кидонианцы максимально атомизировали все
                                накопленные человечеством знания и
                                каждой профессии присвоили определенный набор. Благодаря этому, в Приоритете можно
                                встретить людей,
                                прекрасно знающих ядерную физику, но ничего не смыслящих в биологии. Хотя, такие есть
                                везде, чего греха таить.
                                Разница только в том, что кидонианцы не пытаются насильно впихнуть в тебя знания по
                                предметам,
                                к которым у тебя нет предрасположенности. И не стыдят за незнание чего-либо, потому что
                                неосведомленность конкретных личностей перекрывается крайне высокой осведомленностью
                                профильных институтов, принимающих общественные решения.
                            </p>
                            <p className="card-text text-justify">
                                На собеседованиях, как правило, проверяются личные качества человека, а вовсе не
                                уровень навыков. Университеты позволяют в любое время и совершенно бесплатно
                                сдать экзамен на знание необходимых тем, а уровень доверия к системе образования
                                достаточно высок, чтобы большинство работодателей принимали сертификаты без
                                лишних вопросов.
                            </p>
                            <p className="card-text text-justify">
                                С самого детства каждый человек получает не только набор знаний, но и полезные привычки.
                                Система старается привить юному гражданину инициативность, уверенность в своих силах и
                                любовь к конкуренции. Разумеется, не каждый человек способен всю жизнь искать новые
                                вызовы
                                и пробивать путь на мистический "верх". Поэтому подобные навыки рассматриваются скорее
                                как
                                позитивное дополнение к личности, а вовсе не обязательная составляющая характера.
                                Общество Приоритета
                                принимает в себя человека с любыми взглядами и стремлениями, не навязывая
                                доминирующую точку зрения. Тем не менее, многие пути для тех, кто не готов к жестокой
                                конкуренции,
                                априори закрыты и жизнь в большинстве коллективов будет некомфортной.
                            </p>
                            <h6 className="title-bg">Самые престижные учебные заведения Приоритета:</h6>
                            <ul>
                                <li><strong>Бассианская Академия Содружества</strong> - ВУЗ, специализирующийся на
                                    экономике
                                    и политологии, место учебы <TextInlineLink name={"bs_char_van_gloria_adel"}
                                        text={"Адель ван Глории"} />.
                                    Большую часть корпусов Академии можно найти на Кидонии и <TextInlineLink
                                        name={"bs_planet_bassian"} text={"Бассиане"} />, малонаселенной луне в
                                    системе <TextInlineLink name={"bs_star_mirage"} text={"Мираж"} />.
                                </li>
                                <li><strong>Хеллесианская академия торгового и военного флота имени Бриэнны де
                                    Эстрада</strong> - ВУЗ,
                                    подготавливающий пилотов, инженеров и боевых офицеров, место учебы <TextInlineLink
                                        name={"bs_char_de_karma_ivar"} text={"Ивара де Кармы"} /> и <TextInlineLink
                                        name={"bs_char_skarsgard_oliver"} text={"Оливера Скошгарда"} />.
                                    Академия расположена в системе <TextInlineLink name={"bs_star_hela_gemini"}
                                        text={"Хела-Джемини"} /> и
                                    представляет собой небольшой
                                    искусственный планетоид в безопасной части системы. Здесь также можно найти центр
                                    подготовки офицеров <TextInlineLink name={"bs_comp_esquirete"}
                                        text={"Эсквариата"} /> «Халкида» (место боевой
                                    подготовки <TextInlineLink name={"bs_char_colbert_simone"} text={"Симоны Колберт"} />).
                                    ВУЗ получил имя в честь <TextInlineLink name={"bs_char_brienne_de_estrada"}
                                        text={"основательницы"} /> первого поселения
                                    на Кидонии.
                                </li>
                                <li><strong>Университет содружества</strong> - ВУЗ, который часто путают с
                                    вышеупомянутой Академией.
                                    Это многопрофильное заведение, в котором учат практически всем существующим в
                                    галактике
                                    специальностям. Главные кампусы расположены на Киренаике и <TextInlineLink
                                        name={"bs_planet_helena"} text={"Хелене-у-Тэллаусы"} />.
                                </li>
                            </ul>
                            <h3 id={"economic"} className="title-bg">Экономика</h3>
                            <p className="card-text text-justify">Валюта Приоритета, <TextInlineLink
                                name={"bs_currency_charmo"} text={"кидонианский шарм"} />,
                                одна из самых крепких в галактике. В шармах запасают деньги все: от самих кидонианцев,
                                до агатонских инвесторов, желающих диверсифицировать портфель. Основа такой крепости –
                                доверие к высокой эффективности кидонианского подхода к управлению государством и
                                бизнесом, который проводит страну через
                                любые кризисы, делая только сильнее. Термин «рецессия» для этих людей значит совсем
                                совсем не то, что и для остальной галактики.
                            </p>

                            <p className="card-text text-justify">
                                Само слово «шарм» происходит от кидонианского «чармо» («czármo»), что приблизительно
                                переводится как «очарование» или «очаровательное».
                                Когда эта валюта только появилась, в ходу еще были физические деньги и кидонианцы
                                стремились
                                сделать их максимально красивыми. Они выпускали каждый год новые виды купюр и монет, а
                                также
                                множество коллекционных образцов. Валюта разошлась по земным колониям именно благодаря
                                своей внешней привлекательности, так как на тот момент почти ничего не стоила за
                                пределами
                                узкой области вокруг Кидонии.
                            </p>
                            <p className="card-text text-justify">
                                Тем не менее, у экономики Приоритета есть существенные изъяны, которые делают ее
                                налоговую
                                систему достаточно дырявой, чтобы привлекать преступников и мошенников всех мастей.
                                Кидонианское общество, чересчур либеральное в отношении частной собственности и защиты
                                персональных данных, ставит палки в колеса любым попыткам внедрить хоть сколько-нибудь
                                серьезное наблюдение за частными средствами среднестатистического гражданина. И если
                                чиновник
                                фактически живет под прицелом камер и проверяющих ИИ, то рядовой кидонианец может вести
                                теневой
                                бизнес, получая оплату через серые кошельки, и платить минимальный налог, не боясь
                                расследования.
                            </p>
                            <p className="card-text text-justify">
                                По оценкам специалистов, примерно 36% кидонианцев имеют
                                долю теневых доходов и для 12% граждан такой доход основной. Около половины всех
                                бизнесов в стране начинались именно с работы через серые или даже черные схемы, лишь со
                                временем
                                переходя на белое налогообложение.
                            </p>
                            <p className="card-text text-justify">
                                И в этом еще один феномен кидонианского общества: несмотря на колоссальные потери из-за
                                подобной налоговой терпимости, ученые и алгоритмы упорно отказываются усложнять
                                законодательство
                                и развязывать руки фискалам. Причина проста: кидонианцы рассматривают государство в
                                первую очередь как площадку для развития общества. Они исходят из того, что финансовые
                                издержки
                                ничто в сравнении с постепенным и естественным повышением общественного самосознания.
                                Цель –
                                предоставить личности максимальную свободу действий и среди таких свободных личностей
                                выбирать именно тех, кто сам, добровольно, решает работать не только на благо себе, но и
                                на благо общества. Это отличный механизм отбора кадров.
                            </p>
                            <p className="card-text text-justify">
                                Поэтому вместо жестоких налоговых репрессий государство ввело в некоторых регионах так
                                называемую Единую пошлину (вышеупомянутый минимальный налог), которую могут платить те,
                                кто сознательно укрывает реальные доходы. У таких людей портится репутация и нет шансов
                                получить
                                хорошую должность в крупной компании (не говоря о государственной), отсутствуют
                                бесплатные медуслуги и некоторые другие общественные
                                блага. Зато есть полная экономическая свобода.
                            </p>
                            <p className="card-text text-justify">
                                Конечно, в реальности система куда сложнее, чем описано выше и есть
                                множество нюансов в каждой отдельной ситуации. Я лишь привожу общее описание
                                экономической системы
                                Приоритета, чтобы показать, насколько образ мышления кидонианцев отличается от
                                образа мышления многих других народов галактики.
                            </p>
                            <h6 className="title-bg">Самые крупные и влиятельные корпорации Приоритета:</h6>
                            <ul>
                                <li><strong>ПриоритетОборонПром</strong> – вопреки названию, это не оборонный концерн и
                                    уж точно не
                                    государственный. ОборонПром действительно начинал со стоительства вооружений, но
                                    сейчас
                                    военные корабли - лишь треть его продукци. Большую часть составляет тяжелая
                                    гражданская
                                    техника: грузовые колесные машины, промышленные
                                    роботы для аграрного и промышленного сектора, а также космические фрейтеры
                                    в основном тяжелого и сверхтяжелого класса (те, что могут перевезти в трюме
                                    небольшую луну).
                                    В этой нише у ОборонПрома очень мало достойных конкурентов.
                                </li>
                                <li><strong>Концерн де Брильони - Леусхоффер</strong> - крупнейший в <TextInlineLink
                                    name={"bs_space_brienne_cluster"} text={"Кластере Бриэнн"} /> добывающий
                                    концерн, у которого во владении больше миров, чем у некоторых государств. Знаменит
                                    не только
                                    экономическим успехом, но и забавной традицией именовать купленные планеты в честь
                                    себя.
                                    А учитывая, что концерн давно уже вышел на уровень покупки целых звездных систем,
                                    имена обычно выглядят в духе «Система Брильони–Леусхоффер-4, планета 7, луна 1» или
                                    «<TextInlineLink name={"bs_planet_briglioni_leushoffer_4_7"}
                                        text={"Брильони–Леусхоффер-4-7-1"} />».
                                    Правило начали потихоньку перенимать и другие крупные организации, но лично
                                    я считаю такую моду показушничеством. <s>Ну да, у тебя много планет, зато я умею
                                        варить вкуснейший кофе,
                                        о котором твои роботы и не мечтали.</s>
                                </li>
                            </ul>

                            <p id={"cac"} className="card-text text-justify">Отдельно стоит отметить <strong>Кидонианские
                                надпространственные коммуникации</strong>, известную на всю галактику обитель
                                технократов.
                            </p>
                            <p className="card-text text-justify"><span className="strong">КНК</span> – на 100%
                                независимая от государства корпорация, поддерживающая работу <TextInlineLink
                                    name={"bs_tech_grn"} text={"ИнтерСети"} /> в Приоритете и <TextInlineLink
                                    name={"bs_space_samborae_sector"} text={"Самборе"} />. Последнее
                                объясняется тем, что доверять Сеть <TextInlineLink name={"bs_people_samborians"}
                                    text={"самборианцам"} /> стал бы
                                только
                                безумец, так что, желая иметь доступ к галактическим центрам обмена данными, они
                                были вынуждены согласиться на работу с кидонианскими инженерами.</p>
                            <p className="card-text text-justify">В Приоритете настолько гордятся полной отстраненнотью
                                государства от управления гражданскими ретрансляторами, что даже выпустили специальный
                                закон, запрещающий близким родственникам чиновников работать в КНК, а также владеть хотя
                                бы одной акцией корпорации. Этот чрезвычайно хитрый и популистский ход не только повысил
                                уровень доверия граждан к местным мессенджерам, но и привлек немало иностранных
                                инвестиций в кидонианские IT-проекты. Более того: многие <TextInlineLink
                                    name={"bs_state_republic_of_tiara_minor"} text={"агатонские"} /> компании
                                держат в Приоритете свои центры обмена данными и резервные архивы, даже несмотря на
                                негативное отношение к этому своего правительства.</p>
                            <p className="card-text text-justify">Место работы <TextInlineLink
                                name={"bs_char_hartrey_kellays"} text={"Келлеса Хартри"} /> до
                                присоединения к научно-исследовательскому сектору <TextInlineLink
                                    name={"bs_comp_esquirete"} text={"Минобороны Приоритета"} />.</p>


                            <h3 id={"army"} className="title-bg">Армия</h3>
                            <a href={wiki["bs_char_skarsgard_oliver"].link}>
                                <div style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                    <div className="bg-image-sm"
                                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                        <img alt="header" src={imgs["banner_kalmeria"].link}
                                            style={{ "maxWidth": "100%" }} />
                                    </div>
                                </div>
                            </a>
                            <p className="card-text text-justify">
                                В Приоритете полностью контрактная армия, численность которой мало кому известна.
                                Официальное ее название – Кидонианский военный флот, он же КВФ, он же
                                (неофициально) <TextInlineLink name={"bs_creature_hydra"} text={"Стальная Гидра"} />
                                . Последнее произошло от древней привычки рисовать на бортах кораблей многоголовую
                                тварь, чтобы отпугивать пиратствующих аламарси. Сейчас никто уже не верит в Гидру, но
                                ее изображение стало частью культуры кидонианцев.
                            </p>
                            <p className="card-text text-justify">
                                Другая причина такого прозвища – это изобретенный кидонианцами военный прием «Клещи
                                Воронкова», подробности
                                которого мне, как человеку далекому от военного дела, будет сложно разъяснить. В общем,
                                это какие-то клещи. И когда армада выполняет их, то со стороны выглядит как некое
                                подобие
                                трех (или больше) головой твари, где каждая шея – это сотни боевых кораблей в
                                определенном порядке.
                                Были времена, когда некоторые кочевники всерьез принимали кидонианский флот за
                                космического
                                монстра, маскирующегося под боевой флот.
                            </p>
                            <p className="card-text text-justify">
                                Армия Приоритета разделена на две неравные
                                части,
                                которые могут действовать как вместе, так и полностью раздельно. Официально все
                                вооруженные силы страны подчиняются монарху как главнокомандующему, однако принцип
                                этого подчинения абсолютно разный.
                            </p>
                            <p className="card-text text-justify">
                                Большая их часть - это
                                регулярная армия, которая в мирное время вообще не может ничего делать, кроме
                                как проводить учения и "укреплять обороноспособность". Ей командует Генеральный штаб.
                                В него входят адмиралы разных секторов, военные и гражданские (при необходимости)
                                эксперты. Генштаб полностью автономен в рамках действующих законов и своего бюджета -
                                никто не может навязать ему политику управления войсками и ведения боевых действий.
                                Даже во время войны монарх имеет право лишь согласовывать тактику и стратегию, но не
                                может напрямую приказать осуществить ту или иную операцию. Кроме этого, адмиралы
                                Генштаба подотчетны правительству - Приумвират имеет право знать о любых процессах во
                                флоте и ходе боевых действий. И он же объявляет о начале и конце официальной войны (то
                                есть дает или отзывает у монарха право использовать войска).
                            </p>
                            <p className="card-text text-justify">
                                С другой стороны существуют <TextPopup
                                    name={"bs_org_cydonian_sof"}
                                    text={"Силы специальных операций"} /> Приоритета, которые вынесены за пределы регулярной
                                армии и не подчинены Генштабу. Ими руководит <TextInlineLink
                                    name={"bs_comp_esquirete"} text={"Эсквариат"} />, который в свою
                                очередь управляется монархном страны. Эсквариату и подчиненным ему войскам требуется
                                лишь приказ короля(-евы), поэтому в мирное время только ССО участвуют в операциях
                                на территории нейтрального космоса или в <TextInlineLink name={"bs_state_free_worlds"}
                                    text={"Свободных мирах"} />.
                            </p>
                            <p className="card-text text-justify">
                                Причина такого разделения, как всегда, лежит в конкуренции между всеми ветвями власти
                                в стране. Кидонианцы в прошлом неоднократно страдали из-за бездействия парламента,
                                неспособного договориться в условиях грозящей войны. Поэтому решение выделить
                                монарху собственные войска, способные начать действовать в любую секунду, показалось им
                                логичным.
                            </p>
                            <p className="card-text text-justify">
                                Основная особенность КВФ - это эсквайры. У них есть право брать под свой контроль
                                любое военное подразделение: то есть, они могут перевести под прямое управление
                                монарха даже целый военный флот. Это право можно реализовать в условиях военного
                                положения или прямой угрозы гражданам Приоритета на или за пределами его территории.
                                То есть несмотря на наличие формальных преград для вмешательства в ход боевых действий,
                                монарх способен повлиять на них через своих офицеров.
                            </p>
                            <p className="card-text text-justify">
                                Эсквайры - отлично подготовленные и закаленные в боях солдаты, которые проходят не одну
                                горячую точку, прежде чем попасть в это подразделение. Поэтому их присутствие на поле
                                боя и отсутствие бюрократии делает КВФ более гибкой структурой. Однако и приносит немало
                                проблем из-за человеческого фактора.
                            </p>
                            <p className="card-text text-justify">
                                В армии Приоритета, как и в армиях других стран, основной ударной силой являются флоты
                                под командованием адмиралов.
                                Полноценных сухопутных войск у кидонианцев нет - их заменяет <TextInlineLink
                                    name={"bs_weaponry_space_force"} text={"космопехота"} />, обученная
                                действовать как в вакууме,
                                так и на поверхности любой планеты.
                            </p>
                            <p className="card-text text-justify">
                                Флоты делятся
                                на <strong>ударные</strong>, <strong>пограничные</strong>, <strong>тактические</strong>, <strong>штурмовые</strong> и <strong>космопехотные</strong>.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Ударные</strong> состоят из тяжелых кораблей и предназначены в основном для
                                прорыва серьезной обороны врага
                                или затяжного боя с равным по силе или более серьезным противником.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Пограничный</strong> флот включает в себя корабли более лекого класса, чем
                                ударный. Такое соединение
                                предназначено для патрулирования границ, охраны торговых маршрутов и планет. В нем редко
                                можно встретить машину тяжелее <TextInlineLink name={"bs_ships_ship_classes"}
                                    text={"фрегата"} />, так как бой с
                                превосходящим противником в задачи пограничного флота не входит.
                                Если такой флот попадает в передрягу, он как правило запрашивает помощь у ближайшего
                                тактического флота.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Тактический</strong> включает в себя корабли самых разных классов. Как правило
                                такое подразделение
                                находится в постоянной точке базирования и реагирует на запросы других флотов. В
                                зависимости от ситуации,
                                командующий таким флотом выбирает количество и класс кораблей, которые будут
                                применяться.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Штурмовой</strong> флот предназначен для осады планет и космических
                                укрепрайонов. Его состав
                                зависит от самых разных факторов и как правило меняется в зависимости от ситуации на
                                фронте. Из-за
                                этого применять его в качестве ударного практически невозможно.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Космопехотный</strong> флот – это по большей части соединение больших
                                транспортных кораблей и машин прикрытия,
                                предназначенное для доставки космопехоты в нужную точку. Он редко включает себя нечто
                                тяжелее <TextInlineLink name={"bs_ships_ship_classes"} text={"корвета"} />.
                            </p>
                            <p className="card-text text-justify">
                                Сложные операции, включающие в себя взаимодействие нескольких флотов, как правило
                                проводят <TextInlineLink name={"bs_comp_esquirete"} text={"эсквайры Её Величества"} />,
                                избранные офицеры, отличившиеся незаурядными талантами в военном деле
                                и прошедшие специальную усиленную подготовку.
                            </p>
                            <p className="card-text text-justify">На сегодняшний день КВФ ведет непрекращающуюся войну с
                                пиратами и
                                криминальными синдикатами <TextInlineLink name={"bs_state_free_worlds"}
                                    text={"Самборы"} /> (например, <TextInlineLink
                                    name={"bs_comp_feathered_serpent"} text={"Пернатым змеем"} />), поэтому солдаты
                                Приоритета
                                непрестанно получают боевой опыт и тактически развиваются.
                            </p>

                            <h3 id={"priumviratas"} className="title-bg">Приумвират</h3>
                            <blockquote><p className="card-text text-justify">Сегодня Приумвират одобрил проведение
                                миротворческой операции на территории Монархии и разрешил госпоже <TextInlineLink
                                    name={"bs_char_van_gloria_adel"} text={"ван Глории"} /> применять
                                силу против <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                    text={"мятежного Михъельма"} />.</p><small><TextInlineLink
                                        name={"book_cydonian_girl"} text={"«Кидонианка»"} /></small></blockquote>
                            <a data-caption="Урсула де Сильони" data-fancybox="true"
                                href={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                                    alt="Урсула де Сильони" src={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}
                                    fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify"><span
                                className="strong">Приумвират</span> (Priumviratas) –
                                однопалатный парламент Приоритета. Заседает на <span><TextInlineLink
                                    name={"bs_planet_cydonia"} text={"Кидонии"} />, во Дворце Единства</span> города
                                Старозамковый.
                                Текущий председатель – <TextInlineLink name={"bs_char_de_silgoni_ursula"} />.

                            </p>
                            <p className="card-text text-justify">
                                Член парламента –
                                лорд, чья власть сопоставима с королевской, но ограничена одной территорией. Выборы
                                лордов проводятся раз в полвека и становясь во главе какой-то территории, человек
                                автоматически включается в Приумвират. Лордом может стать любой гражданин старше
                                двадцати пяти, но с довольно внушительным списком достижений. На практике, за всю
                                историю никто раньше сорока туда не попадал – времени просто не хватает, чтобы выполнить
                                все требования.</p>
                            <p className="card-text text-justify">Например, нужно иметь опыт управления командой больше,
                                чем из десяти тысяч человек, а их не так, чтобы много по стране (случалось даже, что
                                порог приходилось снижать, чтобы хоть кто-то мог соревноваться на очередных выборах).
                                Объем полномочий и срок правления могут варьироваться в некоторых секторах.</p>
                            <p className="card-text text-justify">Упомянутый выше ИИ «Система
                                доверия» проводит анонимные опросы населения и наблюдает за аспектами жизни лордов,
                                их успехами и режимом работы и, если уровень недовольства в регионе высок или лорд
                                занимается целыми днями игрой в шахматы, ИИ применяет к нему санкции.</p>
                            <p className="card-text text-justify">Несмотря на формально одинаковые шансы для каждого
                                гражданина (всех кандидатов на выборы выдвигает ИИ, основываясь на реальных результатах,
                                а не мнении доверчивых людишек), некоторым ушлым ребятам удается готовить себе
                                преемников: опытные лорды натаскивают детей, чтобы те получали больше очков в глазах
                                робота и таким образом наголову опережали оппонентов.</p>
                            <p className="card-text text-justify">Так что хоть наследственной аристократии в Приоритете
                                и нет, но обычные люди заметно реже попадают на высшие политические должности. Из-за
                                этого уже несколько десятилетий обсуждается возможность запрета детям быть преемниками
                                родителей, но, как можно догадаться, предложение встречает резкий отпор.</p>
                            <p className="card-text text-justify">
                                Кроме этого, в Приоритете действует строжайший мараторий на агитацию. Выборы лорда и
                                короля
                                не омрачены ничем, кроме открытых дебатов. Любой, кто попытается пиариться в СМИ или тем
                                более
                                заказать рекламу, или навсегда вылетит из политической жизни страны, или сядет в тюрьму.
                                Причина такой жестокости проста: Приоритет слишком много страдал от популистов,
                                пудривших
                                мозги людей. Хорошо хоть не казнят, ведь такие предложения поступали...
                            </p>
                            <p className="card-text text-justify">
                                Более того: если лорд открыто поддерживает кандидата на пост короля, он обязан написать
                                на эту тему настоящую научную работу, изложив все свои аргументы. В ней требуется
                                рассмотреть
                                достижения кандидата, его личностные качества и, самое главное, проанализировать
                                прошлые успехи на предмет воспроизводимости. Грубо говоря: повезло ли персоне оказаться
                                в нужное время в нужном месте или она действительно добилась всего исключительно
                                своими силами.
                            </p>
                            <p className="card-text text-justify">
                                То же самое делают ученые по всей стране, сорвенуясь в разносе будущего монарха. В
                                Приоритете
                                даже есть древний афоризм на эту тему: «Чем меньше работаешь, тем больше шансов стать
                                королем».
                                Понять эти слова может только кидонианец, но я все-таки постараюсь вкратце пояснить:
                                много работающий человек в конечном итоге совершает и много ошибок, а каждому
                                кандидату в короли припомнят все, даже самые мелкие, оплошности. Ну и возможно речь еще
                                и о том,
                                что много работающий человек просто не в силах выдержать избирательную кампанию...
                                тут точно не скажу, я же не кидонианка.
                            </p>
                            <h6 className="title-bg">Крупнейшие политические партии Приоритета:</h6>
                            <ul>
                                <li>
                                    <a data-caption="Ллойд Скаллиган" data-fancybox="true"
                                        href={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                            style={{ "maxWidth": "150px", "float": "left", "marginRight": "1em" }}
                                            alt="Ллойд Скаллиган" src={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}
                                            fetchpriority="low" />
                                    </a>
                                    <a data-caption="Михель Колберт" data-fancybox="true"
                                        href={imgs["bs_char_colbert_michel_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                            style={{ "maxWidth": "150px", "float": "left", "marginRight": "1em" }}
                                            alt="Михель Колберт" src={imgs["bs_char_colbert_michel_1_jpg_full"].link}
                                            fetchpriority="low" />
                                    </a>
                                    <strong>4П (Первая плебисцитарная партия Приоритета)</strong> - проагатонская
                                    партия,
                                    продвигающая идею реформации страны и отмены меритократии. Члены 4П уверены, что
                                    агатонская модель демократии, с прямым избирательным правом без необходимости
                                    демонстрировать
                                    заслуги, сделает Приоритет лучше, а его общественную жизнь проще. Именно деятелей
                                    этой
                                    партии чеще всего упрекают в популизме, игре на общественном мнении
                                    и, как ни парадоксально, коррупции. Лидер - <TextInlineLink
                                        name={"bs_char_scalligan_lloyd"} />,
                                    лорд сектора <TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"} />.
                                    Главный штаб находится на <TextInlineLink name={"bs_planet_helena"}
                                        text={"Хелене-у-Тэллаусы"} />, недалеко от
                                    родового гнезда Скаллиганов. В ней же состоит и <TextInlineLink
                                        name={"bs_char_colbert_michel"} />, отец <TextInlineLink
                                        name={"bs_char_colbert_simone"} text={"Симоны"} />.
                                </li>
                                <li>
                                    <strong>Альянс внешних миров</strong> - политическая формация, отстаивающая интересы
                                    автономных территорий Приоритета и ассоциированных планет-государств. Очень сильное
                                    общественное движение, имеющее в своем активе немало видных деятелей. Основа их
                                    влияния
                                    чисто экономическая: именно т.н. внешние миры зачастую становятся домом для
                                    крупнейших промышленных корпораций
                                    за счет дешевизны земли. И эти корпорации очень агрессивно отстаивают интересы своих
                                    миров.
                                </li>
                                <li>
                                    <strong>Партия промышленников</strong> - вопреки названию, состоит по большей части
                                    не из промышленников. Это общественная сила, представляющая интересы небольших
                                    бизнесов
                                    и частных предпринимателей. Зачастую плательщиков вышеупомянутой фиксированной
                                    пошлины,
                                    «безналоговщины», как этих людей иногда называют.
                                    У партии очень низкий авторитет среди обеспеченных людей Приоритета
                                    и среднего класса, зато множество спонсоров среди деятелей теневого сектора
                                    экономики.
                                </li>
                            </ul>

                            <h3 id={"linga"} className="title-bg">Язык</h3>
                            <div className="" style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                <div className="bg-image-sm"
                                    style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                    <img alt="header" src={imgs["banner_cydonia"].link} style={{ "maxWidth": "100%" }} />
                                </div>
                            </div>

                            <p className="card-text text-justify">
                                Официальных языка в Приоритете два: кидонианский и земной. Большинство документов
                                издаются и
                                заполняются на земном, но при желании можно затребовать версию и на кидонианском.
                                Сделано это для простоты и унификации, как и все в этой стране.
                            </p>
                            <p className="card-text text-justify">
                                Кидонианский – самый сложный и невостребованный язык в галактике.
                                Тяжелая грамматика и фонетика сделали его нежеланным даже в почтенных семьях Приоритета,
                                что уж говорить о толпах мигрантов, что стремятся в страну за лучшей жизнью.
                                Обычно на кидонианском говорит местная богема и потомки первых поселенцев, чтобы
                                подчеркнуть свой статус.
                                Хотя и обычные люди не брезгуют вставить словечко-другое – так речь звучит красивее,
                                особенно если словечко матерное.
                                Кидонианский все еще преподают в школах на обязательной основе, но он неизбежно вымирает
                                и в ближайшие столения станет совершенно невостребован.


                            </p>
                            <p className="card-text text-justify">
                                Забавный факт: в кидонианском есть три разных способа написать слово «Кидония» и семь
                                вариаций слова «зеленый».
                            </p>

                            <h3 id={"worth"} className="title-bg">Стоит посетить</h3>
                            <a data-fancybox="true" data-caption="Гора Панмор"
                                href={imgs["cydonian_landscape_panmor_mountain"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "2em" }} alt="Кидония"
                                    src={imgs["cydonian_landscape_panmor_mountain"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cydonia"} text={"Кидония"} /> и вся
                                    система <TextInlineLink name={"bs_star_mirage"} text={"Мираж"} />:</strong>
                                <ul>
                                    <li>
                                        город Старозамковый (столица Приоритета), прекрасный образец
                                        архитектуры <TextInlineLink name={"bs_timeline"}
                                            text={"ранней световой эпохи"} />,
                                        где большая часть зданий была построена еще во времена <TextInlineLink
                                            name={"bs_char_brienne_de_estrada"}
                                            text={"Бриэнны де Эстрада"} /> и <TextInlineLink name={"bs_timeline"}
                                                text={"Тристана Завоевателя"} />
                                    </li>
                                    <li>
                                        гора Панмор в северных субтропиках восточного полушария, место высадки первых
                                        колонистов и
                                        по сути точка, в которой родилась современная цивилизация (<TextInlineLink
                                            name={"bs_timeline_late_colonies_time"}
                                            text={"Время поздних колоний"} /> началось
                                        именно с момента заселения Кидонии)
                                    </li>
                                    <li>
                                        луна <TextInlineLink name={"bs_planet_bassian"} />, научная столица кластера
                                        Бриэнн и место, где рождаются легендарные
                                        изобретения, способные перевернуть галактику
                                    </li>
                                </ul>
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_helena"} text={"Хелена-у-Тэллаусы"} />:
                                </strong> один из древнейших
                                заселенных миров, который однажды почти выбрали столицей Приоритета. Безумно похожая на
                                Кидонию планета, разве
                                что без орбитальных колец. Здесь еще жива атмосфера времен <TextInlineLink
                                    name={"bs_timeline_light_era_dawn"} text={"Зари световой эпохи"} />, которую
                                ее сестра давно утратила.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_avvaline"} text={"Аввалин"} /> в
                                    системе <TextInlineLink name={"bs_star_lovecraft_zeta"} text={"Дзеты Лавкрафта"} />:
                                </strong> разрекламированный
                                на всю галактику курорт для любителей отдыха на природе, но в то же
                                время чтобы рядом
                                не бродили агрессивные звери, а официант всегда мог принести салат из морепродуктов.
                                Место отдыха бизнесменов и кинозвезд может предложить не только дорогие удовольствия, но
                                и в полне бюджетные развлечения. Например, пешие походы в дикие земли, начисто лишенные
                                следов цивилизации. Аввалину удалось сохранить свою первозданную природу практически
                                нетронутой,
                                при этом обезопасив ее для приезжих и нарастив поток туристов до миллиарда в год!
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cassini"} text={"Кассини"} /> у одноименного
                                    солнца секторе <TextInlineLink name={"bs_space_grace_sector"} text={"Грация"} />:
                                </strong> родина
                                кассианской оперы, кидонианского балета и эпатажной творческой богемы начала прошлого
                                века.
                                Место для тех, кто интересуется поп-культурой, кидонианскими блокбастерами и театральной
                                школой времен <TextInlineLink name={"bs_timeline_war_300"}
                                    text={"Трехсотлетней войны"} />.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_hela"} text={"Хела"} /> и ее
                                    система <TextInlineLink name={"bs_star_hela_gemini"}
                                        text={"Хела-Джемини"} />:</strong> поразительной красоты
                                двойная система со множеством диковинных мест,
                                где опасность и красота - почти синонимы. За потрясающие рубиновые небеса
                                аламарси прозвали ее Дафа-Хару, Рубиновым лисом, и оставили здесь немало артефактов,
                                которые
                                даже спустя тысячи лет еще умудряются находить ученые! Крайне сложная для навигации
                                звездная
                                система не только готовит лучших пилотов в стране, но и проводит умопомрачительные
                                гонки в жерлах ионных штормов.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cyrenaic"} /> в <TextInlineLink
                                    name={"bs_space_arthuri_belt"} text={"Поясе Артура"} /> (сектор <TextInlineLink
                                        name={"bs_space_mistral_sector"} text={"Мистраль"} />):
                                </strong> знаменитый дом первых оседлых аламарси и один из немногих уцелевших
                                памятников культуры кочевников времен <TextInlineLink
                                    name={"bs_char_arthur_the_founder"} text={"Артура Основателя"} />.
                                Мир, с которого начался артурианский культ, объединивший разрозненные флоты аламарси
                                в единое культрное пространство и подаривший нам истории о <TextInlineLink
                                    name={"bs_creature_hydra"} text={"Стальной Гидре"} />, <TextInlineLink
                                    name={"bs_creature_hartrhon"} text={"Гартроне"} /> и <TextInlineLink
                                    name={"bs_creature_cyllopus"} text={"Циллопусе"} />.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Миры <TextInlineLink name={"bs_space_samborian_traverse"}
                                    text={"Самборианского траверса"} />: </strong>
                                практически незаселенная область Приоритета, где много пиратов, спрятанных кладов и
                                справедливых рейнджеров, вершащих правосудие в безлюдных мирах. Для любителей романтики,
                                идущей рука об руку с опасностью.
                            </p>
                            <p className="card-text text-justify">
                                Воистину, каждый уголок Приоритета стоит изучения! Только не залетайте в <TextInlineLink
                                    name={"bs_space_free_fire_zone"} text={"Зону свободного огня"} /> без документов.
                            </p>
                        </div>
                        <div className="col-lg-2 text-center ">
                            <h4>Оглавление:</h4>
                            <ul className="book-info" style={{ "padding": "0px" }}>
                                <li><a href="#society">Общество и история</a></li>
                                <li><a href="#education-and-science">Образование и наука</a></li>
                                <li><a href="#economic">Экономика</a></li>
                                <li><a href="#army">Армия</a></li>
                                <li><a href="#priumviratas">Приумвират</a></li>
                                <li><a href="#linga">Язык</a></li>
                                <li><a href="#worth">Стоит посетить</a></li>
                            </ul>
                            <h6>Место действия:</h6>
                            <Appearance name={"book_hyperbeacons"} />
                            <br /><br />
                            <Appearance name={"book_cydonian_autumn"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
