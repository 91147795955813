import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Nyorun_dreams() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>тяжелый <TextInlineLink name={"bs_ships_ship_classes"} text={"линейный корабль"} /></span>,
        "type": <span><TextInlineLink name={"bs_ships_grime"} /></span>,
        "owned": <span><TextInlineLink
            name={"bs_state_the_great_monarchy"} />, Эшелон Пятых, 1-й оборонный флот <TextInlineLink
                name={"bs_planet_earth"} text={"Земли"} /></span>,
        "size": <span>4800x1200x1200м</span>,
        "captain": <>-</>,
        "conflicts": <span><TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактическая"} />, <TextInlineLink
            name={"bs_timeline_the_shark_revolt"} text={"Восстание Акулы"} /></span>,
        "status": <span>уничтожен во время <TextInlineLink name={"bs_timeline_the_shark_revolt"}
            text={"Второго демарша Пауков"} /></span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"рельсотронные батареи"} /> (от 60 до 500 калибра), управляемые и неуправляемые ракетные батареи, <TextInlineLink
            name={"bs_weaponry_magnetic_cannon"}
            text={"магнетроны"} /> всех калибров, системы радиоэлектронной борьбы</span>,
        "defence": <span>многослойная броня из <TextInlineLink name={"bs_tech_space_steel"} text={"корабельной стали"} /> с 32% <TextInlineLink
            name={"bs_tech_anzuritum"} text={"анзурита"} />, эмиссионные противоракетные заграждения, системы РЭБ (против наведения и навигации), активная ракетная и рельсовая защита</span>,
        "text":
            <>
                <h3 className="title-bg">О корабле</h3>
                <p className="card-text text-justify"><span className="strong">ЭКМ ТК-Ф 2212-08 «Грёзы Ньёрун»</span> – флагман 1-го
                    оборонного флота Земли и мобильный штаб Эшелона Пятых. Машина поступила на службу одновременно с
                    назначением <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Сони Мергелис"} /> на
                    должность гросс-адмирала Пятых (читай командующего Эшелоном) и прошла под ее командованием
                    всю <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактическую"} />. За это время «Грёзы»
                    успела совершить немало военных преступлений, таких как геноцид на <TextInlineLink
                        name={"bs_planet_esghariot_en_nuvnish"} text={"Исгариоте"} /> - Мергелис славилась
                    своей чрезвычайной жестокостью и потрясающей преданностью земному режиму.</p><p
                        className="card-text text-justify">«Грёзы» была уничтожена во время битвы за Угольную, в последние
                    дни михъельмского восстания. Гросс-адмирал приказала ИИ самоуничтожиться, когда экипаж покинет
                    судно, чтобы избежать захвата корабля мятежниками.</p>
            </>
    }
}
