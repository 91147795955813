import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Galactic_security() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>силовая структура</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб-квартира: </strong> <TextInlineLink
                            name={"bs_planet_agathon"} />

                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>выслеживание международных преступников</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Галактическая безопасность</span> –
                    международная полиция. Ищет по галактике беглых преступников и силком везет их на родину, иногда
                    охраняет важных чинуш там, где обычной охраны мало. От охотников за головами отличается более
                    гуманными методами, хотя кто знает, что они делают, когда вокруг нет камер наблюдения?</p><p
                        className='card-text text-justify'>
                    <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритет"} /> не подписал всех пунктов
                    договора о
                    создании этой организации и на протяжении многих веков агенты ГБ вынуждены работать на кидонианской
                    территории с большими ограничениями. Кроме этого, кидонианская полиция не выдает ГБ преступников,
                    имеющих кидонианское гражданство. Единственное исключение - решение суда.</p>
            </>
    }
}
