import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Free_navy() {
    return {
        "top": "",
        "text":
            <>
                <h3 className="title-bg">Об организации</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Вольным флотом</span> в общем и целом
                    называют <TextInlineLink name={"bs_comp_privateer"} text={"каперские"} /> или околопиратские
                    формирования из боевых (иногда транспортных) кораблей, которые за деньги оказывают огневую
                    поддержку или выполняют другие не очень законные задачи.
                </p>
                <p className='card-text text-justify'>
                    К их услугам по понятным причинам нередко прибегают и
                    правительства. Один из общеизвестных (хотя и не доказанных) случаев: оплата несколькими агатонскими
                    бизнесменами, косвенно связанными со структурами <TextInlineLink name={"bs_comp_cms"} text={"СБК"} />,
                    услуг вольных стрелков во время <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                        text={"Восстания Акулы"} />. Нанятые ими бойцы
                    выступили
                    на стороне мятежников и помогли михъельмскому флоту взять
                    такие важные планеты как <TextInlineLink name={"bs_planet_the_night"} text={"Угольная Ньёрун"} /> и
                    <TextInlineLink name={"bs_planet_earth"} text={"Земля"} />.
                </p>
                <p className='card-text text-justify'>
                    Самый крупный поставщик вольных стрелков в галактике - <TextInlineLink
                        name={"bs_comp_free_trade_league"} />. Ее бойцы, набранные и обученные такими организациями как
                    <TextInlineLink name={"bs_comp_feathered_serpent"} />, ценятся в первую очередь как дуболомы для
                    решения проблем с конкурентами, но за хорошую плату готовы потягаться даже с военным флотом
                    целого государства (что неоднократно случалось).
                </p>
            </>
    }
}
