import { Appearance } from "../../../../../components/appearance";

import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Arville() {

    return {
        "date": <span>???</span>,
        "owned": <span>нет</span>,
        "habitats": <span>неизвестно</span>,
        "g": <span><span className='badge bg-danger'>1.27</span> земной (крайне тяжелые условия)</span>,
        "atmosphere": <span><span className='badge bg-warning'>подходит для людей (с оговорками)</span> <span
            className='badge bg-danger'>высокоэлектризована</span> <span
                className='badge bg-danger'>высокоионизирована</span> <span className='badge bg-danger'>низкая температура у поверхности</span></span>,
        "biosphere": <span>своя</span>,
        "system": <span>Хеллеона</span>,
        "type": <span>землеподобная (относительно)</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_the_rift"} text={"Разлом"} /></span>,
        "imgs": [
            { "short": "arville_500", "big": "arville" }
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <a data-fancybox="true" data-caption="Ландшафт на Арвилль: иллюстрация Урсулы Адамс"
                    href={imgs["arville_landscape_1.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Арвилль" src={imgs["arville_landscape_1.jpeg"].link} fetchpriority="low" />
                </a>
                <a data-fancybox="true" data-caption="Ландшафт на Арвилль: иллюстрация Урсулы Адамс"
                    href={imgs["arville_landscape_2.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Арвилль" src={imgs["arville_landscape_2.jpeg"].link} fetchpriority="low" />
                </a>
                <p className='card-text text-justify'><span className='strong'>Арвилль</span> –
                    малоизвестный, скорее даже мифический мир где-то в <TextInlineLink name={"bs_space_the_rift"}
                        text={"Разломе"} />. Первые
                    упоминания о нем можно найти в
                    рассказах <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /> примерно
                    за <TextInlineLink name={"bs_timeline"} text={"2100 лет до Таманрасетт"} />. Кочевники описывали мир
                    как уже заселенный,
                    хотя никаких упоминаний о колонистах в этой части космоса нет. Современные ученые склонны полагать,
                    что Арвилль
                    не существует, а изображения от разного рода искателей диковинок - это снимки другой планеты,
                    которая лишь напоминает описания Арвилль со слов аламарси.
                </p>
                <p className='card-text text-justify'>
                    За прошедшие тысячелетия исследователи неоднократно снаряжали экспедиции в Разлом
                    в поисках этой планеты, надеясь найти там утерянную цивилизацию, состоящую из потомков
                    древних землян. Ни одна не увенчалась успехом: Разлом – слишком обширная и малоисследованная
                    территория, чтобы отыскать конкретную планету, ссылаясь лишь на словесное описание двухтысячелетней
                    давности.
                </p>
                <p className='card-text text-justify'>
                    Само описание, к слову, было очень нелицеприятным: если верить рассказам аламарси,
                    поверхность Арвилль большую часть времени затянута плотными облаками, сквозь которые
                    едва пробивается свет местного солнца (они назвали его Хеллеоном). Сама планета достаточно
                    далеко от солнца, чтобы облака лишь слегка задерживали тепло, не давая миру окончательно
                    замерзнуть, но совсем не спасали от крайне низких температур у поверхности. Кроме этого
                    были упоминания высокой ионизации атмосферы и большого числа молний, возниающих буквально у самой
                    земли.
                    Кто бы захотел селиться в таком месте?
                </p>
                <p className='card-text text-justify'>
                    Точку в вопросе существования Арвилль, как принято считать, поставили бассианские
                    археологи: <TextInlineLink name={"bs_state_cydonian_priority_science"}
                        text={"Академия Содружества"} /> истратила на поиски планеты в общей
                    сложности 50 лет и больше 30 миллионов шармов,
                    но ученым не удалось даже сузить область поиска. Поэтому на сегодняшний день планета признана
                    несуществующий.
                </p>

                <p className='card-text text-justify'>
                    Интересный факт: <TextInlineLink name={"bs_char_ursula_adams"} /> в своем <TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                        text={"«Путеводителе по Млечному Пути для перелетных птиц»"} /> утверждала,
                    что не только посетила Арвилль, но и познакомилась с его жителями.
                    В этом ей якобы помог другой скиталец, который пожелал остаться инкогнито. Большая часть
                    критиков считает эту часть попыткой приукрасить свои заслуги или вовсе тонкой иронией
                    на тему "древних утерянных знаний", которая то и дело всплывает в массовой культуре.
                </p>

                <blockquote>
                    <p className="card-text text-justify">...он отказался выдать координаты, а мой корабль
                        пришлось оставить на Дейдарисе. На его машине мы направились сначала до Картахены, а затем
                        напрямую к Хеллеону, который
                        оказался гораздо ближе от населенных территорий, чем я полагала. Здесь, на шестой орбите
                        от солнца, и вертится Арвилль: поразительно бледная, вся затянута облаками и бесконечными
                        штормами. С орбиты я едва сумела разглядеть хоть какие-то ориентиры...
                    </p>
                    <p className="card-text text-justify">[...]</p>
                    <p className="card-text text-justify">...атмосфера настолько ионизирована, что я несколько
                        раз видела полярное сияние прямо у поверхности! Физику со своим образованием я знаю на отлично,
                        но Гидра раздери,
                        не имею ни малейшего понятия, как это здесь работает. Мой друг пошутил, что без защитного
                        костюма я получу рак мозга быстрее, чем успею простудиться... или не шутил?..
                    </p>
                    <p className="card-text text-justify">[...]</p>

                    <p className="card-text text-justify">...Страшный дубак! Даже солнце здесь какое-то...
                        холодное, неживое...
                    </p>
                    <small><TextInlineLink name={"bs_char_ursula_adams"} />,<TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"} /></small>
                </blockquote>
            </>
    }
}
