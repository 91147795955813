import React, { Component } from 'react'
import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import { GetWikiArticlesByCat, TextInlineLink } from "../../../../components/cms/wiki_functions";

function Universe() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Мир || Кидонианка и Кидонианское солнце"

    }

    render() {
        let list = GetWikiArticlesByCat("bs_world")
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBSCategory name={<span><i className="fa-solid fa-scroll" />  Мир</span>} />
                </div>
                <div className='row'>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-12">
                        <div className='well'>
                            <h4 className='text-center'>
                                МИР «Кидонианского солнца»
                                <br />
                                <small>на 1 год <TextInlineLink name={"bs_timeline"} text={"до Таманрасетт"} /></small>
                            </h4>
                            <table className="table centered">
                                <thead>
                                    <tr>
                                        <th scope="col">Название</th>
                                        <th scope="col">Что</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map(item => (
                                        <tr>
                                            <td><TextInlineLink name={item.repo} text={item.name} /></td>
                                            <td>{item.cat_display}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export { Universe };
