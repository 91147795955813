import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Terminology() {
    return {
        "text":
            <>
                <h4 className='text-center'>Термины</h4>
                <p className="card-text text-justify"><span className="strong">Клетка фарадея (фарадейка)</span> –
                    защитное приспособление, отражающее внешние ЭМ-воздействия. В буквальном смысле представляет из себя
                    токопроводящую клетку, напоминающую первые подобные поделки древних землян. Современная отличается
                    тем, что устроена немного хитрее и способна адаптироваться практически под любое существующее
                    ЭМ-оружие: на некоторых кораблях даже существует механизм поглощения и конвертации энергии в
                    полезную работу (например, вскипятить чайник).</p>
                <p className="card-text text-justify"><span className="strong">«Красными»</span> на флоте называют цели,
                    помеченные системами распознавания как враждебные (или потенциально враждебные – зависит от того,
                    насколько ты параноик). Реальный цвет пометок значения не имеет.</p>
                <p className="card-text text-justify"><span className="strong">Крейсерская скорость</span> – максимально
                    эффективная скорость корабля для передвижения на большие расстояния, при которой он расходует
                    минимум энергии на каждый километр. То есть, превышение этой скорости возможно, но нерентабельно.
                </p>
                <p className="card-text text-justify"><span className="strong">Люфт</span> – ситуация, когда во время
                    полета машину шатает туда-сюда, из-за чего движение по прямой превращается в плохую кардиограмму.
                    Страшнее всего эффект от люфта на световых скоростях: можно легко ошибиться звездной системой или
                    даже страной из-за искажения вектора полета.</p>
                <p className="card-text text-justify"><span className="strong">Огневой контакт</span> – начало
                    боестолкновения с врагом.</p>
                <p className="card-text text-justify"><span className="strong">Параллакс, Ῥ</span> – в контексте
                    военного флота (а не астрономии) означает величину смещения объекта или корабля относительно
                    плоскости, в которой находится наблюдатель. То есть, под каким углом будет огневой контакт, если
                    твой корабль останется в текущем положении. Если Ῥ = 0,0 – наблюдаемый объект находится ровно в той
                    же плоскости, что и наблюдатель. Далее, при смещении объекта по сфере вертикально или горизонтально,
                    растет первая или вторая цифра (макс. значение = 1,1 и это эквивалентно 0,0). Параллакс необходим
                    капитану военного корабля вместе с координатами противника в трехмерной сфере, чтобы понимать, как
                    изменить положение машины в предстоящем бою.</p>
                <p className="card-text text-justify">В гражданском флоте эта величина используется в традиционном
                    астрономическом смысле.</p>
                <p className="card-text text-justify">
                    <span className="strong">«Регрессионка»</span> – см. статью <TextInlineLink
                        name={"bs_tech_shield_kinetic"} text={"Поле кинетической регрессии"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Рельса»</span> – см. статью <TextInlineLink name={"bs_weaponry_railgun"}
                        text={"Рельсотрон"} />.
                </p>
                <p className="card-text text-justify"><span className="strong">Скорость</span> – измеряется в разных
                    единицах, в зависимости от контекста. На досветовых имеет смысл считать все в километрах в секунду,
                    на около- и сверхсветовых – уже непосредственно в скоростях фотонов (1 световая, 20 световых, 100
                    световых и т.д.).</p>
                <p className="card-text text-justify"><span className="strong">«Сумасшедший Иван»</span> – маневр, при
                    котором корабль делает резкий разворот, чтобы объекты, расположенные вне зоны досягаемости орудий,
                    попали в нее.</p>
                <p className="card-text text-justify"><span className="strong">Фаланга</span> – сомкнутый строй флота, в
                    котором корабли выстраиваются в несколько линий на определенном расстоянии друг от друга и идут
                    одним фронтом на врага. Самый простой тип построения, при котором не учитываются многие факторы,
                    возникающие во время боя. Фалангу используют в основном чтобы подавить огнем вражеский флот и
                    выдавить его с занимаемых позиций. Во всех остальных случаях такое построение может уменьшить
                    маневренность.</p>
                <h4 className='text-center'>Сокращения</h4>
                <p className="card-text text-justify">
                    <span className="strong">ГФК</span> – гражданский флот Кидонии. Обозначение кораблей, принадлежащих
                    гражданам, подданным и компаниям <TextInlineLink name={"bs_state_cydonian_priority"}
                        text={"Приоритета"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ЗОК</span> – см. статью <TextInlineLink name={"bs_weaponry_oaac"}
                        text={"Зенитно-орбитальный комплекс"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ЗПО</span> – зона прицельного огня. Условный рубеж между двумя кораблями
                    или флотами, после которого системы наведения способны корректировать огонь с приемлемой точностью.
                    У каждой боевой машины своя ЗПО, но отсчет как правило ведется от главных калибров основной боевой
                    единицы: остальные корабли или открывают огонь позже, или стреляют менее точно – зависит от текущих
                    задач флота.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ИРФ</span> – инженерный республиканский флот. Обозначение, принятое в
                    Объединенных республиках для кораблей двойного назначения, несущих оборудование, которое может быть
                    использовано как для поисков/добычи ископаемых, так и для военной разведки.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КВД</span> – корабль <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Великой Доминации"} />. Общее обозначение
                    Айлирэнского военного флота.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КВФ</span> – Кидонианский военный флот. Общее обозначение для всех боевых
                    кораблей <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КФК</span> – королевский флот Кидонии. Машины дипломатического и
                    правительственного флота <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">МП(У)Р</span> – межпланетная (управляемая) ракета. Опасное оружие, несущее
                    ядерный или антиматериальный заряд и способное обходить большинство систем защиты: ракета
                    приближается к цели на световых скоростях и может быть перехвачена или на старте, или у самой
                    поверхности. В обоих случаях шансы очень малы.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ОВП</span> – ожидаемое время прибытия.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ПКР</span> – см. статью <TextInlineLink name={"bs_tech_shield_kinetic"}
                        text={"Поле кинетической регрессии"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">РЗК</span> – ракета «земля-космос». Редкий тип вооружения, предназначенный
                    в первую очередь для охраны планет, присутствие на которых должно быть скрыто от сканеров.
                    Размещаются в замаскированных пусковых шахтах на поверхности и выполняют ту же роль, что и ЗОК.
                    Эффективность РЗК не очень высока против крупных кораблей: они как правило несут на борту мощные
                    сканеры и лазерную противоракетную систему, которые заранее обнаруживают и уничтожают РЗК.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">РК</span> – ракетный корабль. Машина, использующая в качестве главного
                    наступательного оружия ракетное.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">СРФ</span> – специальный республиканский флот. Аббревиатура для
                    кораблей <TextInlineLink name={"bs_comp_cms"} text={"Службы безопасности Короны"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ТБР</span> – тяжелый бомбардировщик. Вопреки названию, вооружен не только
                    бомбами (которые подходят исключительно для планетарных боев), но и разного рода ракетным и
                    торпедным оружием. «Тяжелый» в большинстве случаев означает повышенную ударную мощь в сравнении с
                    машинами своего класса.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ТВД</span> – театр военных действий. Территория, на которой уже происходят,
                    могут или будут происходить военные действия.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ТК</span> – тяжелый крейсер. Мощная боевая машина, способная выполнять
                    задачи как в составе флота, так и отдельно от него. Обычно вооружена всеми существующими видами
                    оружия и может самостоятельно громить целые вражеские флоты. Приставка «тяжелый» означет, что
                    корабль вооружен дополнительными орудиями или имеет усиленную броню по сравнению другими машинами
                    своей конструкции.</p><p className="card-text text-justify">Также аббревиатура может
                        расшифровываться как «тяжелый корабль» и означать машину, предназначенную в первую очередь для работы
                        вместе с эскадрой. Они отличаются от крейсеров в первую очередь меньшим числом выполнимых задач и
                        пониженной скоростью.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ТКП</span> – тяжелый корабль поддержки. Боевая машина, поддерживающая огнем
                    основные ударные корабли эскадры. Вооружение, скорость и броня варьируется от конкретного типа.
                    Приставка «тяжелый» указывает на усиленное вооружение или броню по сравнению с другими машинами
                    своего класса.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">УПКР</span> – см. статью <TextInlineLink name={"bs_weaponry_casm"}
                        text={"Умная противокорабельная ракета"} />.
                </p>
                <p className="card-text text-justify"><span className="strong">ЭКМ</span> – эскадренный корабль
                    Монархии. Обозначение большинства военных кораблей <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Великой Монархии"} /> до ее развала во
                    время <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Восстания Акулы"} />.</p>

            </>
    }
}
