import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Redeyed() {
    return {
        "is_a_type": true,
        "developed": <span><TextInlineLink
            name={"bs_state_tribalon_republic"} /> («Трайбалонские верфи Виковиры»)</span>,
        "class": <span>гражданское <TextInlineLink name={"bs_ships_ship_classes"} text={"инженерное судно"} /></span>,
        "size": <span>800x140x60м</span>,
        "status": <span>производство остановлено в 23 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "weapons": <span>нет</span>,
        "defence": <span>противоастероидная броня из <TextInlineLink name={"bs_tech_space_steel"}
            text={"корабельной стали"} />,
            гасители помех, клетка Фарадея</span>,
        "text": <>
            <h3 className="title-bg">О классе</h3>
            <div style={{ "maxWidth": "320px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                <img style={{ "maxWidth": "300px" }} alt="«Платон»" src={imgs["bs_ship_plato"].link} fetchpriority="low" />
            </div>
            <p className="card-text text-justify">
                <span className="strong">«Красноглаз»</span> – тип гражданских
                инженерных кораблей, разработанный трайбалонцами для геологоразведки и поиска
                разного рода аномалий в толще планет. Активно применялся по всему <TextInlineLink
                    name={"bs_space_democratic_space"} text={"Демкосмосу"} />, в первую очередь такими
                конторами как <TextInlineLink name={"bs_comp_vikoveera_mineralworks"}
                    text={"Виковира минералворкс"} /> и <TextInlineLink
                    name={"bs_organizations_union_of_terrformers"} text={"Союз терраформеров"} />.
            </p>
            <p className="card-text text-justify">
                «Красноглаз» нес на борту несколько десятков сканеров, каждый из которых можно было подстроить для
                работы
                в разных условиях и на разных частотах. Кроме этого, конструкция позволяла добавить еще 3-5 компактных
                приборов
                или 1-2 массивных - огромное преимущество в сравнении с конкурентными моделями, которые зачастую не
                позволяли
                расширить набор оборудования.
                Этим преимуществом часто пользовались археологи, которые дополняли основные сканеры более чуткими
                устройствами, чтобы находить минимальные отклонения от нормы под поверхностью планетоидов. Среди
                агатонских ученых
                корабли проекта «Красноглаз» стали настоящей легендой. Даже когда на рынке появились более совершенные
                аналоги, многие предпочитали покупать их, только чтобы разобрать на запчасти и улучшить свое старое
                судно.
            </p>
            <p className="card-text text-justify">
                По этой причине, когда Трайбалонские верфи свернули производство, они еще много лет производили запасные
                части для «Красноглаза».
            </p>
            <p className="card-text text-justify">
                При этом популярность машины была велика не только в Демкосмосе, но и в отдаленных частях
                галактики: <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /> и <TextInlineLink
                    name={"bs_people_samborians"} text={"самборианцы"} /> высоко оценили гибкость модели и обилие запчастей.
                Когда агатонские владельцы
                «Красноглаза» все-таки продавали свою ласточку, она часто оказывалась именно на рынках <TextInlineLink
                    name={"bs_space_samborae_sector"} text={"Самборы"} />, где
                быстро получала новую жизнь во флотах <TextInlineLink name={"bs_comp_free_trade_league"}
                    text={"Лиги свобдной торговли"} /> или
                отрядах <TextInlineLink name={"bs_comp_bonecollectors"} text={"собирателей костей"} />.
            </p>
        </>
    }
}
