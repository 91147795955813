import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Sector_zero() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span>под вопросом</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "people": <span>земляне</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Нулевой сектор</span> – относительно
                    небольшой участок галактики, где зародилось человечество и активно развивалось во времена
                    досветовых перелетов. Ничего особо примечательного тут нет: кругом памятники, музеи и всякие
                    арт-выставки с фотографиями ушедших эпох.</p><p className="card-text text-justify"><span
                        className="strong">Последствия «Кидонианки»:</span></p><p className="card-text text-justify">Нулевой
                            сектор
                            погряз в хаосе и раздорах после восстания и теперь не совсем ясно, будет ли он полностью под
                            управлением землян или разделится на несколько независимых территорий.</p>
            </>
    }
}

