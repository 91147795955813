import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ajliraen() {

    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>57 млрд. на поверхности + 198 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.01 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Доминион Раасал"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"} /></span>,
        "type": <span><span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} /></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"} /></span>,
        "imgs": [{ "short": "bs_wiki_ajliraen_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">
                    Выдержки из <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                        text={"«Путеводителя по Млечному Пути для перелетных птиц»"} /> Урсулы
                    Адамс <small>(3 г. <TextInlineLink name={"bs_timeline"}
                        text={"ДТ"} />)</small>
                </h3>
                <p className="card-text text-justify">
                    <span className="strong">Айлирэн</span> – мир величия и контраста, рожденный тремя солнцами. Густой свет жёлтых карликов
                    Раасала и Айлирэна смягчается багровыми лучами красного гиганта Доминиона, создавая вечные белые ночи,
                    от которых на поверхности почти не остаётся места для темноты. Это мир без звездных небес –
                    лишь бесконечный свет, от которого невозможно укрыться.
                </p>

                <p className="card-text text-justify">
                    Когда-то на Айлирэне процветала буйная флора: леса с искривлёнными деревьями,
                    покрытыми светящимися листьями, и бескрайние поля растений, извивающихся, словно животные.
                    Но от этой древней природы остались лишь воспоминания.
                    Городская застройка поглотила почти весь ландшафт, и сегодня планета покрыта монументальными мегаполисами.
                    Бетон, мрамор и металл вытеснили зелень, а воздушные магистрали соединяют городские кластеры, уходящие ввысь
                    в облака. В таких масштабных постройках люди ощущают себя словно песчинки в океане архитектурного величия.
                </p>
                <p className="card-text text-justify">
                    Айлири стремятся к совершенству формы и содержания —
                    каждый дом, каждый дворец или административное здание воплощают идеал симметрии и строгости.
                    Высоченные башни тянутся в небеса, их фасады украшены барельефами с изображением подвигов великих
                    предков и символами имперской славы. Улицы широкие, вымощенные полированным камнем,
                    а вдоль проспектов расположены бесконечные ряды статуй, воспевающих деяния граждан Айлирэнской Доминации.
                    Всё здесь проникнуто пафосом и торжеством — даже узоры на балюстрадах повествуют о славных победах.
                </p>
                <p className="card-text text-justify">
                    Несмотря на холодную строгость урбанистического стиля, в этом мире чувствуется нечто монументальное и вечное.
                    Кажется, что всё на Айлирэне построено, чтобы пережить века — от крепостных стен до дворцов и памятников.
                    Но за этим величием скрывается душная атмосфера контроля и классового неравенства.
                    Жители-айлири (полноценные граждане) живут в просторных апартаментах с роскошными видами,
                    а квириты обитают в мрачных рабочих районах, едва различимых в архитектурных тенях.
                    В таких местах белый свет трёх солнц уже не так ярок, и горизонт утопает в тумане испарений и машинного дыма.
                </p>
                <p className="card-text text-justify">
                    На поверхности Айлирэна почти не осталось живой природы, но в садах дворцов и храмов все ещё сохраняются
                    искусственные оазисы — места, где цветы растут под контролем ботаников, словно символизируя,
                    что природа может существовать лишь под властью человеческого порядка. Даже здесь красота подчинена закону,
                    где каждый лист должен расти на своём месте, а каждый водопад течь по установленному маршруту.
                </p>
                <p className="card-text text-justify">
                    Айлирэн — это величественный мраморный храм цивилизации,
                    где прогресс стал новой религией, а империю сравнивают с вечным светом трёх солнц,
                    от которого не спрятаться и не скрыться.
                </p>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify">Колонию на <span className="strong">Айлирэне</span> основали
                    почти одновременно с Кидонией. На тот момент для землян путь к системе Доминиона был одним из самых
                    сложных и долгих,
                    но путешествие того стоило: Айлирэн имел свою биосферу, которую пришлось лишь слегка
                    подкорректировать для Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    Первым колонистам пришлось несладко, но высадка и обустройство прошли гораздо лучше,
                    чем у <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бриэнны де Эстрада"} /> во время
                    колонизации <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"} />.
                    Новоиспеченные айлири быстро разорвали все связи с <TextInlineLink name={"bs_planet_earth"}
                        text={"Землей"} /> и другими
                    колониями - начали выстраивать
                    свое общество и старались не повторить ошибок предков. Если почитать историю Айлирэнской
                    Доминации, станет ясно - ничего не вышло.
                </p>
                <p className="card-text text-justify">
                    Отречься от бывших соотечественников оказалось легко: путь до Земли и до Кидонии
                    занимал десятилетия, некоторые корабли вообще не долетали. Связи тоже было, а потому
                    лишь <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /> были спутниками айлирэнской
                    цивилизации в первые века
                    освоения планеты.
                </p>
                <p className="card-text text-justify">У Айлирэна три солнца: <TextInlineLink
                    name={"bs_star_raasal_ajlyrien_dominion"} text={"Раасал, Айлирэн и Доминион"} />. Первые два – желтые
                    карлики, совсем как Земное, а третье – красный
                    гигант. Здесь всегда царят белые ночи и некогда была очень буйная флора, причудливая
                    и красивая. Но айлири, как и многие другие людские общества,
                    выбрали путь урбанизации и забетонировали все к чертям.</p>
            </>
    }
}
