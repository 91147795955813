import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Otton_bjorne() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Оттон Бьерне",
        "fullname": <span>Оттон Бьерне</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"} text={"Михъельм"} /></span>,
        "nation": <span>михъельмец</span>,
        "born": <span>193 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>192</span>,
        "work": <span>главнокомандующий флотами <TextPopup name={"bs_popups_state_shark_republic"}
            text={"Республики Акулы"} />, <s>гросс-адмирал
                Паучьего Эшелона Монархии</s></span>,
        "imgs": [
            { "short": "bs_char_otton_bjorne_1_jpg_500", "big": "bs_char_otton_bjorne_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Оттон</span> – соратник <TextInlineLink name={"bs_char_tom_torvalds"}
                        text={"Торвальдса"} /> в борьбе
                    за независимость Михъельма от землян. Коренной михъельмец, он большую часть жизни провел в земной
                    армии, носясь по галактике, защищая режим Монархии от многочисленных врагов.
                </p>
                <p className="card-text text-justify">
                    До <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Галактической"} /> он
                    никогда не раздумывал о том, насколько плохо земляне относятся к его народу. И даже во время войны,
                    когда сыны и дочери его родного мира гибли в сражении за эго и амбиции Монарха, Оттон не представлял
                    себе иного мироустройсва. Все поменялось после Первого михъельмского восстания.
                </p>
                <p className="card-text text-justify">
                    Когда под конец Галактической михъельмцы впервые обратили оружие против
                    землян, Бьерне, по его
                    собственному признанию, прозрел. Он вдруг осознал, что не обязательно жертвовать жизнью ради тирана
                    и что галактику можно перестроить. Судьба уберегла его от участия в восстании: Оттон оказался совсем
                    в другом регионе космоса и не смог присоединиться к соотечественникам. Поэтому когда их разбили и
                    казнили всех зачинщиков, Бьерне пообещал себе, что поднимет свой народ на новое.
                </p>
                <p className="card-text text-justify">
                    Вместе с Торвальдсом и <TextInlineLink name={"bs_comp_cms"} text={"агатонской разведкой"} /> они
                    разработали хитрый и достаточно изощренный план
                    мирного распада Монархии. Однако арест Торвальдса во время <TextInlineLink
                        name={"book_cydonian_girl"} text={"«Кидонианки»"} /> и последующие чистки в высших властных кругах
                    страны парализовали восстание. Из-за образовавшейся анархии началась самая настоящая гражданская
                    война.
                </p>
                <p className="card-text text-justify">
                    Лишь усилиями <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивара де Кармы"} /> и его
                    отчаянных товарищей удалось погасить пожар и принудить землян
                    отказаться от претензий на контроль над Михъельмом.
                </p>
                <p className="card-text text-justify">
                    После событий «Кидонианки» Оттон стал самым могущественным человеком в <TextInlineLink
                        name={"bs_space_the_old_space"} text={"Старом космосе"} />: он
                    сосредоточил в своих руках рычаги управления десятками боевых флотов бывшей Монархии и пообещал,
                    что земляне больше никогда не посягнут на независимость своих «исконных» территорий.
                </p>
            </>
    }

}