import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function CanBeucanGaereas() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "is_not_ready": false,
        "name": "Гэри Бьекен",
        "fullname": <span>Гэриас <small>«Румор», «Заткнись уже», «Специалист широкого профиля»</small> кан Бьекен</span>,
        "born": <span>54 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>53</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span>может и есть</span>,
        "work": <span>специалист широкого профиля</span>,
        "imgs": [
            { "short": "bs_char_can_beucan_gaereas_1_png_500", "big": "bs_char_can_beucan_gaereas_1_jpg_full" },
            { "short": "wide_profile_specialist_jpg_200", "big": "wide_profile_specialist_jpg" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <br />(раздражитель Эсоры)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <img style={{ "maxWidth": "300px", "float": "right" }} alt="Гэри Бьекен"
                    src={imgs["wide_profile_specialist_png_300"].link} fetchpriority="low" />

                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify"><strong>Биография появится в процессе написания <TextInlineLink
                    name={"book_cydonian_autumn"} text={"«Кидонианской осени»"} /></strong>
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Последствия <TextInlineLink name={"book_cydonian_girl"}
                        text={"«Кидонианки»"} />:</span>
                </p>

                <p className="card-text text-justify">Находится в поразительном состоянии: объявлен в
                    розыск как международный преступник, но благодаря дипломатическому иммунитету
                    Республики Акулы не может быть арестован.
                </p>
                <h3 className="title-bg">Занимательные факты</h3>
                <p className="card-text text-justify">Имя этого персонажа – Гэриас кан Бьекен. «Cane» на
                    итальянском значит собака, и то же самое услышит кореец в слоге «гэ». Случайно
                    вышло.</p>
                <h3 className="title-bg">Эволюция героя</h3>
                <p className="card-text text-justify"><span className="strong">Гэри</span> изначально
                    задумывался капитаном Румором (это была фамилия, а не прозвище) в прямом подчинении у
                    адмирала <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Кармы"} />.
                    Землянин был михъельмцем и ему полагалось служить в 322-м космопехотном корпусе
                    «Стражи Асгарда», 156-ой отдельная дивизии «Валахия». Но красивые названия пришлось
                    вырезать вместе с заменой биографии: как только Ивар перешел из адмиралов
                    михъельмской мятежной армии в кидонианца-фрилансера и стал главным героем, Гэри, как
                    верный <span className="strike">пес</span> друг последовал за ним.</p>
                <p className="card-text text-justify">А еще, он должен был погибнуть в конце, в сцене с
                    Мергелис. Вариантов его безвременной кончины было аж три: от меча Мергелис, от
                    кинжала Мергелис, от пистолета Мергелис. Но в конечном счете, когда были вырезаны
                    другие персонажи (Рита,
                    Аарон и Тор – читай подробнее в <TextInlineLink name={"bs_unenclosed"} text={"«Удаленные герои»"} />)
                    и Гэри получил их реплики, стало ясно, что убирать его из
                    повествования нельзя. Ведь тогда придется ввести кого-то еще и давать новому герою ту
                    же самую роль «тупого дружка с задней парты», а это бессмысленный и даже вредный ход.
                </p>

                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <p className="card-text text-justify"><span className="strong">Вырезанный диалог из первой сцены:</span>
                </p>
                <blockquote><p className="card-text text-justify">– 322-й космопехотный корпус «Стражи
                    Асгарда», 156-я отдельная дивизия «Валахия», – с гордостью отчеканил Гэри. – «Элита
                    флота»<br />– Как много названий, – не скрывая удивления ответила <TextInlineLink
                        name={"bs_char_de_levine_rosalie"} text={"Розали"} />. – Я не
                    запомню<br />– Ничего, мы тебе на доспехах всё равно напишем. Чтобы потом знать, на
                    какое кладбище отправлять труп</p></blockquote>
            </>
    }
}
