import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Breezeans() {
    return {
        "homeworld": <span>сектор <TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"} /></span>,
        "state": <span>нет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"}
            text={"земной"} /></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify">
                    <span className="strong">Бризеанцы</span> – разобщенный народ
                    сектора Бриз, не имеющий своего государства или даже культурного самосознания.
                </p>
                <p className="card-text text-justify">
                    Этих людей объединяет только одно: почти все миры Бриза находятся
                    под контролем агатонских корпоратократов, а их жители заключены в оковы несправедливых законов,
                    ограничивающих свободу, иногда вплоть до права покидать планету.
                </p>
            </>
    }
}
