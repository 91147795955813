import { Component } from 'react'
import { BooksBlacksunPart1 } from "../components/BooksBlacksunPart1"
import wiki from '../components/cms/wiki.json';
import imgs from "../components/cms/images_repo.json";
import { Carousel } from 'antd';
import { WorkInProgress } from "../components/workInProgress";

class Home extends Component {
    render() {
        return (
            <>
                <div key="work_in_progress_row" className="row" style={{ marginBottom: "1em" }}>
                    <WorkInProgress />
                </div>
                <div key="mainRow" className="row">
                    <div key="news" className="col-lg-7">
                        <Carousel>
                            <div>
                                <a href={wiki["bs_char_ursula_adams"].link}>
                                    <img alt="Баннер 20" src={imgs["banner_776_400_20"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_de_karma_ivar"].link}>
                                    <img alt="Баннер 18" src={imgs["banner_776_400_18"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_de_levine_rosalie"].link}>
                                    <img alt="Баннер 21" src={imgs["banner_776_400_21"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_esora_eve"].link}>
                                    <img alt="Баннер 14" src={imgs["banner_776_400_14"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_the_whip"].link}>
                                    <img alt="Баннер 16" src={imgs["banner_776_400_16"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_javet_eijenora"].link}>
                                    <img alt="Баннер 13" src={imgs["banner_776_400_13"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_colbert_simone"].link}>
                                    <img alt="Баннер 15" src={imgs["banner_776_400_15"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_shimmering_devil"].link}>
                                    <img alt="Баннер 17" src={imgs["banner_776_400_17"].link} fetchpriority="low" />
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_browdy_adam"].link}>
                                    <img alt="Баннер 19" src={imgs["banner_776_400_19"].link} fetchpriority="low" />
                                </a>
                            </div>
                        </Carousel>
                    </div>
                    <div key="news" className="col-lg-5">
                        <h2>Добро пожаловать!</h2>
                        <p className="lead text-justify">Этот сайт посвящен серии книг Кидонианское солнце</p>
                        <p className="text-justify">В первую очередь советуем заглянуть в энциклопедию <a
                            href={wiki["bs_glossary"].link}>мира Кидонианскоuj солнца</a>. А еще в <a
                                href={wiki["bs_gallery"].link}>галерею с иллюстрациями</a> к книгам, посмотреть <a
                                    href={wiki["all_books"].link}>список этих самых книг</a> и немного <a
                                        href={wiki["bs_unenclosed"].link}>невошедших забавностей</a>.</p>
                    </div>
                </div>
                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Книги</h3>
                </div>
                <BooksBlacksunPart1 />

                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Герои</h3>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_the_whip"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["no_back_whip_5_200"].link}
                                        alt="Бич" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_esora_eve"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_esora_eve_2_png_200_transparent"].link}
                                        alt="Ева Эсора" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_de_levine_rosalie"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_de_levine_rosalie_1_png_200_t"].link}
                                        alt="Розали де Левин" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_skarsgard_oliver"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_skarsgard_oliver_1_png_200_transparent"].link}
                                        alt="Оливер Скошгард" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_de_karma_ivar"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_de_karma_ivar_1_png_200_t"].link}
                                        alt="Ивар де Карма" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_browdy_adam"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_browdy_adam_1_png_200"].link}
                                        alt="Адам Броуди" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_can_beucan_gaereas"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_can_beucan_gaereas_1_png_200_t"].link}
                                        alt="Гэри" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_hartrey_kellays"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_hartrey_kellays_1_png_200_t"].link}
                                        alt="Келлес Хартри" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Места</h3>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_maps_oecumene"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <img style={{ "width": "200px", "height": "200px" }} className=""
                                    src={imgs["bs_wiki_galaxy_v2_250x250_map_ru"].link}
                                    alt="Карта галактики" />
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_valoria"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["valoria_500"].link}
                                        alt="Валория" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_new_havana"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["new_havana_500"].link}
                                        alt="Новая Гавана" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_avvaline"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["avvaline_500"].link}
                                        alt="Аввалин" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_arville"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["arville_500"].link}
                                        alt="Арвилль" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_tamanrasett"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["tamanrasett_500"].link}
                                        alt="Таманрасетт" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_cydonia"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["cydonia_500"].link}
                                        alt="Кидония" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_space_tiara_minor_nebulae"].link}>
                            <div className="img-thumbnail-v2"
                                style={{ "width": "210px", "height": "210px", "margin": "auto" }}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{ "width": "200px", "height": "200px" }}
                                        src={imgs["tiara_minor_500"].link}
                                        alt="Туманность Малой короны" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </>
        )
    }
}
export { Home };
