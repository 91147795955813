import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Free_trade_league() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>криминальная организация</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>контрабанда, пиратство, черный рынок</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штабы:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"} />, <TextInlineLink
                            name={"bs_planet_khal_vernon"} />, <TextInlineLink
                                name={"bs_planet_new_havana"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Лига свободной торговли</span> –
                    криминальный синдикат родом из <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборы"} />.
                    Некогда состоял из небольшой неорганизованной толпы пиратов и контрабандистов, но со временем
                    разросся на всю галактику, обзавелся союзниками в правительствах и военном флоте, проложил свои
                    теневые торговые пути в обход границ и построил аванпосты по всей человеческой ойкумене. Сейчас
                    почти на всех планетах <TextInlineLink name={"bs_state_free_worlds"}
                        text={"Свободных миров"} /> можно найти представителей Лиги: у
                    них есть своя регулярная армия, система
                    налогообложения, здравоохранения, образования, суды и все остальные атрибуты обычного
                    государства.</p><p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span>
                </p><p className="card-text text-justify">Из-за устроенного Виктором Ленисаадом бардака на
                    <TextInlineLink name={"bs_planet_earth"} text={"Земле"} /> Лига свободной торговли потеряла множество
                    ценных
                    связей на планете, а затем и по всей стране. Это принесло колоссальные убытки.</p>
            </>
    }
}
