import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Shimmering_devil() {
    return {
        "name": "Мерцающий дьявол",
        "fullname": <span>Мерцающий дьявол</span>,
        "born": <span>???</span>,
        "age": <span> ???</span>,
        "homeworld": <span>???</span>,
        "species": <span>???</span>,
        "imgs": [
            { "short": "bs_char_shimmering_devil_1_jpg_500", "big": "bs_char_shimmering_devil_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Что известно?</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Мерцающий дьявол</span> – существо
                    неизвестного вида, предположительно связанное с <TextInlineLink name={"bs_weaponry_the_black_sun"}
                        text={"Черным солнцем"} /> и
                    создавшее на его
                    основе невероятно продвинутые <TextInlineLink name={"bs_tech_inter_branal_positioning_beacon"}
                        text={"маяки межбранального позиционирования"} /> (одним
                    из которых
                    является <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} />).
                </p>
                <p className='card-text text-justify'>
                    Со слов <TextInlineLink name={"bs_char_the_whip"} text={"Бича"} />, именно Дьявол уничтожил
                    его <TextInlineLink name={"bs_spec_firstborns"} text={"цивилизацию"} />.
                </p>
            </>
    }
}
