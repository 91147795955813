import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Tribalon() {
    return {
        "date": <span>~3000 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_tribalon_republic"} /></span>,
        "habitats": <span>142 млрд. (63 на поверхности)</span>,
        "people": <span>трайбалонцы, агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.08 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Аламейн</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} text={"Виковира"} /></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span></span>,
        "imgs": [{ "short": "bs_wiki_tribalon_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Трайбалон</span> заселен
                    агатонцами в рамках собственной программы заселения <TextInlineLink
                        name={"bs_space_tiara_minor_nebulae"} text={"Туманности Малой короны"} />.
                    Это было жизненно необходимо на фоне постоянных попыток землян подмять под себя старые колонии.
                    Трайбалон планировался как запасной мир на случай, если потомкам <TextInlineLink
                        name={"bs_char_alkanarra_tristan"} text={"Тристана Завоевателя"} /> удастся
                    высадиться на Агатоне и подавить его оборону.
                </p>
                <p className="card-text text-justify">
                    Когда угроза ослабла, а <TextInlineLink name={"bs_state_the_great_monarchy"}
                        text={"земное государство"} /> впало в очередной период
                    междоусобиц, Трайбалон
                    начал превращаться в самостоятельную республику и стал центром уже своей колониальной
                    инфраструктуры.
                </p>
                <p className="card-text text-justify">
                    Несмотря на многовековую историю самостоятельности, примерно половина населения Трайбалона до сих
                    пор
                    считает себя <TextInlineLink name={"bs_people_agathonians"} text={"агатонцами"} />.
                </p>
            </>
    }
}
