import { TextInlineLink } from "../../../../../components/cms/wiki_functions";
import { Appearance } from "../../../../../components/appearance";

export function Graces() {

    return {
        "date": <span>~260 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>13 млрд. на поверхности + 9 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>Грейс Младшая</span>,
        "mother": <span>Грейс Старшая</span>,
        "g": <span>1.14 / 0.48 земной</span>,
        "atmosphere": <span className="badge bg-success">подходит для людей</span>,
        "biosphere": <span>своя, терраформирована минимально</span>,
        "system": <span>Грейсы</span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Морихей"} /></span>,
        "type": <span>землеподобные <TextInlineLink name={"bs_universe_planet_types"} text={"аграрные миры"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"} /></span>,
        "imgs": [{ "short": "bs_planet_graces_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О мире</h3>
                <p className="card-text text-justify">
                    <span className="strong">Грейсы</span> – двойная полупланетарная система в приливном захвате, как ее
                    зовут
                    любители выделываться.
                    Это два мира-сада, имеющие общую атмо- и биосферу. Основная планета, Старшая, чуть
                    больше <TextInlineLink name={"bs_planet_earth"} text={"Земли"} />, а
                    ее
                    спутница, Младшая - на половину меньше. При этом Младшая по классификации является планетоидом, а не
                    полноценным миром, поэтому систему и принято называть "полупланетарной".
                </p>
                <p className="card-text text-justify">
                    Местная биосфера зародилась независимо от людей и была лишь слегка ими изменена: терраформеры
                    устранили чересчур опасные виды и уничтожили микропаразитов, которые могли навредить человеку.
                    В остальном Грейсы сохранили свой первозданный вид.
                </p>
                <p className="card-text text-justify">
                    Оба мира аграрные и знамениты на весь Приоритет своими уникальными плодовыми культурами,
                    особо ценными за счет того, что их крайне сложно привить в других мирах. Особенности
                    местной биосферы трудновоспроизводимы без полной терраформации. Поэтому Грейсы, находящиеся в Зоне
                    свободного огня, тщательно
                    охраняются и
                    пираты уже много лет даже не пытаются совершить налет на караваны с едой, улетающие из системы.
                </p>
            </>
    }
}
