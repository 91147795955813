import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Cydonians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_cydonia"} />,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"} />,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_levine_clara_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"кидонианский"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"земной"} /></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Кидонианцы</span> – собирательное имя
                    жителей кидонианских территорий в <TextInlineLink name={"bs_space_brienne_cluster"}
                        text={"Кластере Бриэнн"} />.
                    Изначально так называли только поселенцев на Кидонии, но со временем, по мере формирования
                    Содружества, а затем Приоритета, имя стало распространяться и на всех союзников.
                </p>
                <p className="card-text text-justify">
                    К кидонианцам уже много веков относят и абсолютно самобытные культуры, которые пронесли свою
                    идентичность
                    даже сквозь тысячелетия жизни в общем пространстве. Среди
                    них: <TextInlineLink name={"bs_planet_bassian"} text={"бассианцы"} />, <
                        TextInlineLink name={"bs_planet_hela"} text={"хеллесианцы"} />, <
                        TextInlineLink name={"bs_planet_cyrenaic"} text={"киренайцы"} />, <
                        TextInlineLink name={"bs_planet_cassini"} text={"кассианцы"} /> и многие другие.
                </p>
                <p className="card-text text-justify">
                    В галактике мало кто знает о существовании этих народов, а сами жители Приоритета не привыкли
                    рассказывать о своей настоящей идентичности. Некоторые отшучиваются: в галактике о кидонианцах
                    настолько интересные
                    стереотипы, что просто причисляя себя к ним, можно легко заиметь успех в любом обществе.
                </p>
                <p className="card-text text-justify">Определенные личности зовут кидонианцев «зеленомордыми», но это от
                    зависти.</p>
                <div className="alert alert-danger">
                    <p>Продолжение следует</p>
                </div>
            </>
    }
}
