import { Alert } from "antd";

export const WorkInProgress = () => {
    return (
        <>
            <Alert
                message={<strong style={{ color: "#842029", fontSize: "1rem" }}>Внезапное объявление:</strong>}
                description={<>
                    <strong>От 16.05.2024:</strong>
                    <br />
                    Работа над «Осенью» затягивается, но идет.
                    Как видите, я даже обзавелся нормальными портретами героев и сменил хостинг для картинок!
                    А это половина успеха (шутка).
                </>
                }
                type="error"
            />
        </>
    )
}