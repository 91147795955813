import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cartagena() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>2.2 млрд. (2 на поверхности)</span>,
        "people": <span>айлири (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>4</span>,
        "mother": <span></span>,
        "g": <span>1.11 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_star_hedwig"} text={"Ядвиги"} /></span>,
        "sector": <span>Кирикия</span>,
        "type": <span>землеподобная, <TextInlineLink name={"bs_universe_planet_types"} text={"аграрная"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "area": <span>Туманность Лабиринт Ришелье</span>,
        "imgs": [{ "short": "bs_wiki_cartagena_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Картахена</span> – мир, названный в
                    честь древнеземного города Карфаген, исчезнувшего с карт задолго до того, как люди вышли в
                    космос. Сейчас это небольшая аграрная агломерация, хотя и плотно заселенная для подобных планет
                    (обычно на фермерских планетах живет мало людей из-за автоматизации труда), но первыми его
                    посетили аламарси, еще до заселения <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"} />.
                    На
                    планете до сих пор есть следы древних посадочных площадок и горнорудных разработок, которые
                    охраняются как культурные объекты.</p>
            </>
    }
}

