import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bacarte() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Морихей"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span>кидонианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>-</span>,
        "imgs": [{ "short": "bs_wiki_bacarte_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <div className="alert alert-danger">
                    <p>Будет позже</p>
                </div>
            </>
    }
}
