import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_cyllopus() {
    return {
        "homeworld": <span>неизвестно</span>,
        "size": <span>неизвестно</span>,
        "type": <span>мифологическое</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} text={"Артурианство"} /></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Циллопус</span> – демон (а для
                    кого-то бог) из ранних легенд аламарси. Первые упоминания датируются примерно 5200 годом до
                    Таманрасетт. Обычно
                    изображался в виде гигантской рыбы наподобие земного глубоководного удильщика. Только вместо
                    биолюминисцентной лампы у него на удочке целое солнце. Его свет мешает добыче разглядеть тварь,
                    пока та не сомкнет свои челюсти на ничего не подозревающем корабле. Некоторые аламарси верили,
                    что Циллопус пожирает населенные миры в том числе, поэтому, мол, селиться на планетах – себе
                    дороже. К звездам они по той же причине предпочитали близко не подлетать (или потому что боялись
                    расплавить обшивку своего древнего хлама – кто теперь разберет).</p>
            </>
    }
}
