import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Majbory() {
    return {
        "date": <span>-</span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>12</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "people": <span>самборианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>25 млрд.</span>,
        "imgs": [{ "short": "bs_wiki_majbory_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Майбори</span> – звезда в глубине
                    Самборы. Небольшой желтый карлик (тавтологично звучит, ага), давным-давно обнаруженный аламарси,
                    которые использовали его в качестве стандартной свечи: светимость у звезды очень стабильная, она
                    проявляет гораздо меньше привычных для звезд этого класса активностей. Плюс, Майбори на тот
                    момент находилась на пересечении нескольких маршрутов, по которым кочевники свозили на Кидонию
                    добытые диковинки и новые звездные карты для последующей продажи. Так что выбор звезды в
                    качестве маяка был очевиден.</p><p className="card-text text-justify">Через несколько
                        тысячелетий, когда аламарси покинули эту часть Самборы в поисках еще не изведанных территорий,
                        систему заняли сначала мирные колонисты-фермеры, а затем и взявшие их под «опеку» пираты. С тех пор
                        здесь одна из самых укрепленных баз Лиги свободной торговли.</p>
            </>
    }
}

