import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Scellurae() {
    return {
        "date": <span>946 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span>Республика Акулы</span>,
        "habitats": <span>32 млн.</span>,
        "people": <span>сцеллурианцы, земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>нет</span>,
        "g": <span>0.99 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную, <span className='badge bg-danger'>крайне опасна</span></span>,
        "system": <span>Алевтины</span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"} /></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"аграрный мир"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_scellurae_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Сцеллура</span> – планета-сад земного
                    типа с кислотной биосферой, колонизирована больше тысячи лет назад. За это время люди успели
                    извести всю фауну, а флору модифицировать на генном уровне настолько сильно, что ее уже сложно
                    отличить от земной.</p><p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span>
                </p><p className="card-text text-justify">Сцеллура понесла большие финансовые потери из-за орбитальной
                    битвы между <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Пауками и Пятыми"} />. Местным
                    жителям пришлось вложить немалые средства в очистку биосферы от следов ионного топлива и радиации.
                    Тем не менее, тот факт, что именно здесь произошел легендарный взлом земных коммуникаций, принес
                    планете славу по всей галактике. Спрос на ее уникальную фермерскую продукцию возрос в десятки раз, а
                    налоговые послабления со стороны михъельмцев с лихвой компенсировали все затраты.</p><p
                        className="card-text text-justify">Сцеллурианцы даже задумались о полной независимости,
                    но <TextInlineLink name={"bs_planet_michelm"} text={"Михъельм"} /> не поддержал эту идею. Иронично.</p><p
                        className="card-text text-justify"><span
                            className="strong">Заметки Ивара о Сцеллуре из «Кидонианки»:</span>
                </p>
                <blockquote><p className="card-text text-justify"><span className="blockquote-footer">Сутки почти земные.</span>
                </p><p className="card-text text-justify"><span className="blockquote-footer">Природа недружелюбная: вода и почва загрязнены соединениями серы. Биосфера пропитана кислотой. Если над водоемом висит безвредный на вид пар – это, скорее всего, хлороводород. Образуется из-за испарения соляной кислоты.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Шлем ни в коем случае не снимать – сок растений, утренняя роса и даже туман с аппетитом съедят твое лицо.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Местная флота прекрасно себя чувствует в таких условиях, так что прикасаться к диким растениям не стоит. Только к обработанным человеком, здесь таких много.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Мир по большей части аграрный, выращивает деликатесы под открытым небом. На других планетах здешние фрукты потребуют специального ухода. Поэтому многие местные виды даже не пытались привить в других мирах, из-за чего их плоды стоят довольно дорого. Так что стараемся ничего лишнего не сжигать.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Фауны почти нет, за исключением редких насекомых. </span><span
                        className="code">НЕ ТРОГАТЬ ИХ РУКАМИ!</span></p><p className="card-text text-justify"><span
                            className="blockquote-footer">Почти вся поверхность – фермерские угодья, лесов нет. В основном будем блуждать по чужим огородам.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Население – несколько миллионов, городов нет, только разбросанные по степям маленькие коммуны.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Местные активно поддерживали восстание Михъельма из-за непомерно высоких налогов, введенных землянами еще во время Войны и с тех пор так и не сниженных. Но прибытие Эшелона Пятых, судя по всему, умерило пыл.</span>
                    </p></blockquote>
            </>
    }
}

