import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Javet_pauline() {
    return {
        "name": "Паулинэра Явет",
        "fullname": <span>Паулинэра Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлирэн"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"} /></span>,
        "uncle": <span><TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "born": <span>57 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>56</span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_eijenora"} />, <TextInlineLink
            name={"bs_char_javet_erbees"} /></span>,
        "work": <span>адмирал 1-го ударного флота <
            TextInlineLink name={"bs_state_ajliraenian_domination"}
            text={"Айлирэнской Доминации"} /> («Абиссинский кулак»)</span>,
        "imgs": [
            { "short": "bs_char_javet_pauline_1_jpg_500", "big": "bs_char_javet_pauline_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Юность</h3>
                <p className="card-text text-justify">
                    <span className="strong">Паулинэра</span> – представительница одной из боковых ветвей династии Яветов.
                    Ее родители далеко за пределами списка наследников, но это не помешало им получить титул и статус
                    при дворе императора Бейсарион. За верную службу и профессионализм он возвел их в ранг наследной
                    аристократии и добавил весьма щедрых финансоых бонусов.
                </p>
                <p className="card-text text-justify">
                    Причиной были успехи в горнорудной отрасли: родители Паулинэры создали компанию, которая смогла
                    вывести производство корабельной стали в айлирэнских провинциях на циклопические показатели. Это
                    спасло флоты <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Доминации"} /> от
                    полного разгрома на полях <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Галактической"} /> и позволило всего за несколько
                    лет
                    восполнить значительную часть потерь в технике от войны. Император позже признавался, что при более
                    низком объеме производства айлири не смогли бы выстоять под натиском демократов и <TextInlineLink
                        name={"bs_state_republic_of_tiara_minor"} text={"агатонский флот"} /> в
                    итоге разбил бы войска Доминации. Страна оказалась на краю пропасти под конец войны: кораблей
                    было меньше, чем матросов, готовых на них служить.
                </p>
                <p className="card-text text-justify">
                    Семья Паулинэры не единолично удостоилась благодарности императора, но статус при дворе удалось
                    получить только им, поскольку решающее значение в глазах Бейсарион имела фамилия.
                </p>
                <p className="card-text text-justify">
                    Сама девушка родилась примерно за 20 лет до этой войны. Она еще училась в школе, когда грянул
                    конфликт и с упоением слушала истории отца о том, как они создают все больше боевых кораблей. Его
                    истории и успехи парадоксальным образом вызвали в ней не желание стать промышленником, а любовь к
                    военному флоту. Поэтому после школы она пошла в военную академию, но закончила ее уже после
                    Галактической.
                </p>
                <p className="card-text text-justify">
                    С тех пор Паулинэра служила во множестве флотов по всей Доминации и не раз командовала боевыми
                    соединениями в конфликтах с <TextInlineLink name={"bs_people_samborians"} text={"самборианцами"} />.
                    Вершиной ее карьеры стало назначение в
                    «Абиссинский кулак», которое она получила благодаря поддержке самого императора. Несмотря на рвение
                    и навыки девушки, мало кто был готов поверить, что Паулинэра действительно заслужила командовать
                    легендарным 1-м ударным флотом Доминации. Тем самым, что неоднократно спасал саму государственность
                    и народ айлири.
                </p>
                <h3 className="title-bg">Революция в Монархии (<TextInlineLink name={"book_cydonian_girl"} />)</h3>
                <p className="card-text text-justify">
                    Девушка, понимая, какой шанс ей выпал и насколько сложно будет доказать подчиненным, что она
                    достойна должности, принялась с двойным упорством громить пиратов в <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Свободных мирах"} /> и даже
                    вступила в личный конфликт с <TextInlineLink name={"bs_char_hector_salinger"}
                        text={"Гектором Селинджером"} />,
                    лидером <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатого змея"} />.
                </p>
                <p className="card-text text-justify">
                    При дворе Паулинэру приняли очень холодно, как и ее родителей. У нее была всего одна подруга - первая
                    наследная принцесса <TextInlineLink name={"bs_char_javet_eijenora"} text={"Эльсинора"} />. Девушки
                    быстро
                    нашли общий язык, не помешала даже разница в возрасте. Они часто проводили вечера за бокалом какао,
                    обсуждая мечты о реформации страны и делясь крамольными мыслями о том, насколько устарел
                    айлирэнский традиционалистский порядок вещей. Обе пришли к выводу, что после ухода Бейсарион
                    Доминацию следует превратить в подобие Приоритета с его либеральной меритократией.
                </p>
                <p className="card-text text-justify">
                    Поэтому когда в <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархии"} /> случилась
                    гражданская война и Эльсинора открыто призналась, что не планирует
                    помогать <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Монарху"} /> сохранять власть,
                    Паулинэра ее поддержала. Она пошла против воли своего
                    правителя и лишь имитировала подготовку войск ко вторжению в пространство землян. Вместо этого
                    неделями гоняла боевые корабли на учения и техосмотр,
                    фактически сделав «Абиссинский кулак» небоеспособным. В итоге земляне не получили никакой помощи,
                    хотя император был уверен, что принцесса не ослушается его и поддержит последнего из Алканарра.
                    Из-за этого при дворе разразился колоссальный конфликт, который грозил Паулинэре потерей не только
                    звания, но и свободы (а возможно жизни). Эльсинора передала ей, что император планирует назначить
                    другого наследника, а Эльсинору и всех, кто с ней близок, подвергнуть репрессиям. Девушкам срочно
                    требовался запасной план.
                </p>
            </>
    }
}
