import React, { Component } from 'react'
import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import {
    CharacterCard,
} from "../../../../components/characters/Black_sun_characters_lists";
import { Col, Row } from 'antd';

function Characters() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Герои || Кидонианка и Кидонианское солнце"

    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBSCategory name={<span><i className="fa-solid fa-user-tie" />  Герои</span>} />
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-12">
                        <blockquote className='well'>
                            <p className="card-text text-justify">Интересен вот какой факт: большую часть истории люди
                                могли рассчитывать
                                в самом лучшем случае на 100 лет жизни. Это кажется неочевидным, но сейчас, когда мы
                                легко можем
                                позволить себе 250, а при хорошей медицине и все 300, изменилась не только
                                продолжительность жизни.
                                Наша внешность, поведение, даже образ мышления совершенно отличаются от таковых у
                                предков: сейчас человек
                                после 25-30 лет перестает стареть и в большинстве случаев черты лица не изменяются как
                                минимум до 150.
                                Наши предки в лучшем случае могли сохранять моложавость до 40 и то, лишь при
                                определенных условиях.
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                Поэтому то, что нам с вами кажется простым и очевидным, для них было испытанием: мы
                                живем в мире, где возраст
                                выдают взгляд и манеры, им же было достаточно лишь посмотреть на лицо человека. И взгляд
                                для них
                                казался чем-то очень сложным, запутывающим. Это в корне иной образ мышления!
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                Если бы путешествия во времени существовали, мы могли бы разыграть много забавных
                                каламбуров.
                                Например, показать личное дело нашего современника нашему предку, чтобы бедняга гадал:
                                как человек,
                                выглядящий максимум на 30, сумел получить 40 лет трудового стажа?!
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                Не менее интересным ему бы показался термин «вторая старость», который современные
                                психологи
                                применяют для описания явления, во многом похожего на кризис среднего возраста, но
                                наступающего примерно
                                после 250 лет жизни.
                            </p>
                            <small>Юлиан Харари, «Homo sapiens: правдивая история выходцев со Старой Земли», 265 г.
                                ПТ</small>
                        </blockquote>


                        <div className='well'>
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_the_whip"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_esora_eve"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_rosalie"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_karma_ivar"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_browdy_adam"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_can_beucan_gaereas"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_oliver"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_van_gloria_adel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hartrey_kellays"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_simone"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_ursula_adams"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_callisto_kindrace"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kato_aemilius"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_lenisaad_victor"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_werner_rita"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_shimmering_devil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_tom_torvalds"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_montgomery_lorena"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_salibar_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_mereglis_sonya"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_otton_bjorne"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kaory_nazil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_eijenora"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_vaal_leon"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_jasmine_haervany"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_vivien"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_bejsenaer"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_erbees"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_pauline"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_xavier_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_scalligan_lloyd"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_silgoni_ursula"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hector_salinger"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_michel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_clara"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_arthur_the_founder"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_brienne_de_estrada"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_tristan"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_siel"} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export { Characters };
