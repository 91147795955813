import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hector_salinger() {
    return {
        "name": "Гектор Селинджер",
        "fullname": <span>Гектор Селинджер</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>лидер синдиката <TextInlineLink name={"bs_comp_feathered_serpent"} /></span>,
        "homeworld": <span>неизвестно</span>,
        "born": <span>неизвестно</span>,
        "age": <span>неизвестно</span>,
        "nation": <span><TextInlineLink name={"bs_people_samborians"} text={"самборианец"} /> (предположительно)</span>,
        "imgs": [
            { "short": "bs_char_hector_salinger_1_jpg_500", "big": "bs_char_hector_salinger_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Гектор</span> – лидер одной из самых могущественных организаций в <
                        TextInlineLink name={"bs_state_free_worlds"} text={"Свободных мирах"} />. Неизвестно, существовал ли
                    Пернатый змей до того, как он его возглавил или же
                    синдикат был создан Гектором лично. Однако достоверно известно, что именно Селинджер сделал
                    Змея тем, чем он является уже много лет: мощнейшей армией <TextInlineLink
                        name={"bs_space_samborae_sector"} text={"Самборы"} />, держащей в страхе целые миры и
                    даже некоторых агатонских полководцев.
                </p>
                <p className="card-text text-justify">
                    Место его рождения и биография до появления Змея в окрестностях <TextInlineLink
                        name={"bs_planet_samborae"} text={"Метрополии Хнаасор"} /> тоже неизвестны.
                    Ни одна разведка галактики не смогла выяснить даже крохотных деталей о его жизни, что наталкивает
                    на три возможных вывода: либо Селинджер рос там, где нет камер наблюдения (а в Самборе полно таких
                    планет), либо он каким-то образом откупился от властей своего родного мира и «убедил» их удалить
                    все записи о себе, либо, что наиболее вроятно, он просто сменил внешность. В пользу последнего
                    варианта говорит тот факт, что Селинджер никогда не подвергался детальному сканированию и
                    никто точно не знает, есть ли у него под кожей следы операций.
                </p>
                <p className="card-text text-justify">
                    Талант Гектора как полководца весьма сомнителен, поскольку Змей не использует никакой особой тактики
                    и зачастую берет просто числом и подавляющей огневой мощью. Однако его умение сплотить людей,
                    дать им идею, создать иллюзию братства - это заслуживает уважения. Именно благодаря лидерским
                    качества Селинджера и его абсолютной беспринципности Змей действует не как разобщенная сеть
                    наемников,
                    а как самая настоящая религиозная секта.
                </p>
                <p className="card-text text-justify">
                    Бойцы Пернатого змея редко сдаются в плен и предпочитают лишить себя жизни, чтобы не попадать в
                    руки врага. Причем речь даже не о других группировках, а о вполне законопослушных агатонских
                    силовиках, которые вместо пыток применяют обычную сыворотку правды.
                </p>
                <p className="card-text text-justify">
                    По этой же причине Гектор не потерял власть, когда в 19 году <TextInlineLink name={"bs_timeline"}
                        text={"ДТ"} /> потерпел
                    поражение от <TextInlineLink name={"bs_state_cydonian_priority"}
                        text={"Приоритета"} /> в <TextInlineLink
                        name={"bs_space_samborian_traverse"} text={"Самборианском траверсе"} />. Вместо этого он получил
                    опыт, который затем использовал для усиления синдиката. С тех пор Змей неоднократно демонстрировал
                    чудеса адаптации к тактике кидонианских военных.
                </p>
            </>
    }
}
