import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Valoria() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>12 млн.</span>,
        "governor": <span>Антоний Клорелл</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>нет</span>,
        "mother": <span></span>,
        "g": <span>1.084 земной</span>,
        "atmosphere": <span className="text-warning">сильно загрязнена</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_bacarte"} text={"Бакарты"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Морихей"} /></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"шахтерский мир"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [{ "short": "bs_wiki_valoria_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <div className="alert alert-danger">
                    <p>Будет позже</p>
                </div>
            </>
    }
}
