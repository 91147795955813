import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Sleeping_suns() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>желтые карлики</span>,
        "stars": <span>2</span>,
        "planets": <span>17</span>,
        "habitable": <span>1</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} text={"Старый космос"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"} /></span>,
        "owned": <span>Республика Акулы</span>,
        "people": <span>михъельмцы, земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>очень мало</span>,
        "imgs": [{ "short": "bs_wiki_sleeping_suns_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Спящие солнца</span> – две близняшки
                    из верхней части класса F, держат рядом с собой шестнадцать незаселенных планет, по большей
                    части мелких и каменистых, а также одну пригодную для жизни и терраформированную людскими
                    автоматическим станциями +/- пять с половиной с половиной тысяч лет назад. Поместье Спящих солнц
                    где-то тут.</p><p className="card-text text-justify">Названы спящими из-за того, как неспешно
                        они поднимаются по небосклону Поместья. Спорная ассоциация, но древние земляне и не такие названия
                        выдумывали.</p><p className="card-text text-justify"><span
                            className="strong">Последствия «Кидонианки»:</span>
                </p><p className="card-text text-justify">Трудно поверить, но система даже не заметила, что сменила
                    государство и форму правления.</p>
            </>
    }
}

