import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";
import { GetQuotes } from "../../../components/Quotes";


function Book_Black_sun() {

    let repo = "book_black_sun"
    let name = wiki[repo].name
    document.title = "Кидонианское солнце - " + name
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book" />  Черное солнце</span>} />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_black_sun_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Черное солнце"
                            src={imgs["book_black_sun_jpg_ru"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Черное солнце</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo} />
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Книга пока не готова
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="18" aria-valuemin="0"
                                    aria-valuemax="100">18%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt" /> <h6>Статус:</h6> <strong><span
                                className="text-info">готовится к работе</span></strong></li>
                            <li><i className="far fa-list-alt" /> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Кидонианское солнце #8"} /></li>
                            <li><i className="fas fa-angle-double-left" /> <h6>Предыдущая:</h6> <TextInlineLink
                                name={"book_the_free_traders"} text={"Вольные торговцы"} /></li>
                            <li><i className="fas fa-angle-double-right" /> <h6>Следующая:</h6> <strong><span
                                className="text-danger">нет</span></strong></li>
                            <li><h6>Связанные
                                страницы:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className=" well">
                        <p className="card-text text-justify">Рано для спойлеров 😬</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li>Галерея пока пуста</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { Book_Black_sun };
