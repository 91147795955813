import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Grn() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
            text={"метапространственная механика"} />, информатика</span>,
        "use": <span>системы связи</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">ГРС</span>, <span
                    className="strong">ИнтерСеть</span> или <span
                        className="strong">Галактическая ретрансляторная сеть</span> – очень могущественная технология,
                    которая позволяет обмениваться сообщениями быстрее света. В ее основе лежит сеть крохотных
                    червоточин, ведущих в <TextInlineLink name={"bs_tech_abspace"} text={"Надпространство"} />.
                    Это некое подобие надстройки над нашей вселенной, место, в котором больше измерений, чем здесь у
                    нас, из-за чего там работают другие законы физики и можно двигаться больше чем в трех
                    измерениях.</p><p className="card-text text-justify">Путем <span
                        className="strike">магии</span> сложных математических расчетов и <span
                            className="strike">магии</span> больших затрат энергии, люди сумели создать стабильные червоточины,
                    через которые можно в режиме реального времени передавать и получать радиоволны. Изнутри
                    Надпространство покажется чем-то вроде дуршлага, утыканного трехмерными дырками, через которые
                    непрерывно сыплются терабайты картинок с котиками. Если там кто-то живет, и он научился
                    де-модулировать волны и расшифровывать сигнал, то у него не очень высокое мнение о нашей
                    цивилизации.</p><p className="card-text text-justify">Человеков туда запихнуть пока не получилось –
                        даже если всю жизнь просидишь на диете, то все равно останешься слишком большой тушкой, и
                        стабильного тоннеля таких размеров создать не получится. К тому же, наша материя ведет себя в том
                        мире немного некорректно, и никто не знает, что случится, если там окажется живое существо. К тому
                        же (2), наш мозг не может осознать больше трех измерений, так что, очутившись внутри, космонавт
                        скорее всего стошнит в шлем и нырнет обратно домой.</p><p className="card-text text-justify">ГРС
                            делится на регионы, каждый из которых управляется <span className="strong">хабом</span>, большим
                    центром обмена данными, который выбирает наилучшие маршруты для соединений. Рядовая единица сети –
                    это <span className="strong">маяк гиперсвязи</span> (он же ретранслятор и он же навибуй), который
                    помогает кораблям находить свое положение в пространстве, а людям – слать селфи в соцсети.</p><p
                        className="card-text text-justify">Формально ГРС не зависит ни от какого государства. Ее
                    обслуживанием занимаются самые разные корпорации, которые зачастую не скованы границами одной
                    страны. Любое оборудование тщательно проверяется перед включением в Сеть, и, если обнаружатся
                    нарушения (шпионский код, подозрительные устройства), то узел будет «выброшен» из Сети до устранения
                    неполадок. Такой подход, плюс шифрование всех данных перед передачей, позволяет избежать
                    неправомерной слежки со стороны спецслужб.</p>
                <div className="row">
                    <blockquote><p className="card-text text-justify">Обыватели зовут единицы ИнтерСети буями или
                        навибуями, от слова «навигация». Но инженеры обычно используют официальный термин: маяк
                        гиперсвязи. Так даже звучит солиднее.</p><small><TextInlineLink name={"book_cydonian_girl"}
                            text={"«Кидонианка»"} /></small>
                    </blockquote>
                </div>
                <div className="row">
                    <blockquote><p className="card-text text-justify">Маяки гиперсвязи (обыватели зовут их навибуями
                        или ретрансляторами) несут на борту самые мощные сканеры в галактике, как на военных
                        крейсерах. Такие засекут даже муху на расстоянии в миллион километров и о любом контакте
                        докладывают в центр управления Сетью, на всякий случай. Если кто-то пытается взять маяк на
                        абордаж, этому бедняге придется несладко: внутри такой штуки ядерный и экзоматериальный
                        реактор, и никакого инстинкта самосохранения. Стоит сделать одно неосторожное движение, как
                        встроенный ИИ запустит процесс перегрева. Пираты лет сто не пытались захватывать буи, разве
                        что в качестве замысловатого способа эвтаназии.</p><small><TextInlineLink
                            name={"book_hyperbeacons"} text={"«Похитители маяков»"} /></small></blockquote>
                </div>
                <div className="row">
                    <blockquote><p className="card-text text-justify">Она уставилась в обзорное окно на огромную
                        металлополимерную конструкцию.</p><p className="card-text text-justify">Больше половины
                            маяка ГРС занял ядерный реактор: он нужен для питания многочисленных приемников и
                            передатчиков; а еще четверть – генератор экзотической материи, который создает внутри
                            крохотную, размером в несколько атомов углерода сингулярность.</p><p
                                className="card-text text-justify">И эта маленькая дырочка – не просто черная дыра, она –
                            ворота в другую реальность. Со школьных уроков физики всем известно, что это место зовется
                            Надпространством , потому что находится условно «над» нашей вселенной и очень плотно
                            контактирует с ней, но не смешивается. Надпространство словно бы повторяет изгибы мира
                            людей, вплотную прилегая к нему. И внутри него больше измерений пространства, чем у нас.
                            Если в нашем мире можно двигаться вперед, вбок и вверх, то там… ну, человеческий мозг не
                            способен такое вообразить. Кроме верха и низа там есть еще направления, которые описываются
                            только математически.</p><p className="card-text text-justify">И если двигаться по этим
                                измерениям, то перемещение из точки А в точку Б происходит гораздо быстрее, чем в нашей
                                вселенной. Как бы по диагонали. Входя в Надпространство у Кидонии, можно пролететь километр
                                и выйти обратно в нашу вселенную не в кидонианской атмосфере, а где-нибудь на Земле.</p><p
                                    className="card-text text-justify">«Здорово, правда?» – говорил на лекциях преподаватель по
                            физике, тыкая в пугающие математические формулы. Жаль только, что он и сам с трудом понимал,
                            как это на самом деле работает. А Тошико даже не пыталась: просто научилась ремонтировать
                            эти штуки, а какая именно магия их питает – дело третье.</p><p
                                className="card-text text-justify">Иногда она раздумывала над тем, как будет круто жить,
                            когда люди научатся расширять тоннель достаточно сильно, чтобы в него прошел корабль. Это
                            станет революцией, не меньше. Насколько такое действие безопасно – она не знала. Прямо
                            сейчас по галактике натыканы миллиарды мелких дырочек в другую вселенную, эдакий дуршлаг
                            мироздания. Выглядит безобидно, но вдруг на той стороне тоже есть кто-то, желающий
                            воспользоваться порталами в своих целях? Да и вообще, им может не понравиться, что люди
                            используют чужой мир как переулок между звездами. И если Homo Sapiens расширят тоннели до
                            огромных размеров, появится отличный шанс проникнуть в новую вселенную и полакомиться
                            человечинкой.</p><small><TextInlineLink name={"book_cydonian_girl"}
                                text={"«Кидонианка»"} /></small>
                    </blockquote>
                </div>
            </>
    }
}
