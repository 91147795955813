import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Urtm() {
    return {
        "capital": <TextInlineLink name={"bs_planet_agathon"} />,
        "form": <span>конфедерация</span>,
        "currency": <TextInlineLink name={"bs_currency_teros"} text={"агатонский терос"} />,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"}
            text={"земной"} /></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <TextInlineLink name={"bs_space_democratic_space"} />,
        "sectors": "-",
        "worlds": "-",
        "people": "множество",
        "imgs": [{ "short": "bs_wiki_republic_of_tiara_minor_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Объединенные республики Малой Короны или ОРМК
                </span>
                    – плеяда небольших планет-государств, раскиданных внутри и по краям Туманности Малой Короны. Ничего
                    примечательного в них нет и граничат они только друг с другом и Агатоном.
                </p>
                <p className="card-text text-justify">ОРМК считают сектор Бриз своей зоной приоритетного развития и
                    называют его
                    Республиканский протекторат Бриз, хотя официально там нет единого правительства или других органов
                    власти.
                </p>
            </>
    }
}

