import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Samborae_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span>-</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_samborae"} /> (неофициально), <TextInlineLink
            name={"bs_planet_new_havana"} /> (неофициально), <TextInlineLink
                name={"bs_planet_khal_vernon"} /> (неофициально)</span>,
        "people": <span>самборианцы, айлири, кидонианцы</span>,
        "population": <span></span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Автономный сектор Самбора</span> –
                    шутка, никакой он не автономный, а скорее просто бесконтрольный. Это участок космоса, по
                    размерам примерно как <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритет"} /> (а
                    Приоритет очень большой – представь себе Канаду, только в рамках галактики). И в Самборе нет
                    никаких межзвездных государств – только вольные народы и Лига свободной
                    торговли.</p><p className="card-text text-justify\">Каждая планета сама себе выбирает лидеров и
                        форму правления. Но одна общая цель у них все же есть: не <span
                            className="strike">умереть с голоду</span> стать
                    частью больших стран. <TextInlineLink name={"bs_people_samborians"} text={"Самборианцы"} /> терпеть не
                    могут
                    флаги, гербы, гимны и прочие атрибуты власти – они с молоком автоматического питательного аппарата
                    впитывают стремление к свободе. Конечно, свобода понятие относительное, поэтому в Самборе, например,
                    ты свободен иметь рабов. Если бы не этот факт, их можно было бы назвать социалистами.</p>
                <p className="card-text text-justify">Пиратской Самборой зовут часть сектора, лишенную официальных
                    государств, гражданской инфраструктуры и как правило абсолютно дикую.</p>
            </>
    }
}

