import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Surghanne() {
    return {
        "date": <span>~4000 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><span class="strike"><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>, под вопросом</span>,
        "habitats": <span>190 млн.</span>,
        "people": <span>сурганнцы, земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.16 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Сурганны</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Рассвета"} /></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"аграрный мир"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_surghanne_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Сурганна</span> – самая
                    многонаселенная и многопрофильная аграрная планета в секторе Рассвета, известна на всю Монархию
                    своими фруктовыми садами и шоколадными фабриками. На Сурганне родился бы Аарон Торн, не будь он вырезан из сюжета «Кидонианки».</p>
                <p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span></p>
                <p className="card-text text-justify">Сурганна отказалась поставлять свои продукты землянам и вышла
                    на международный рынок. Экспортировать еду в Империю Элат и <TextInlineLink
                        name={"bs_state_aethurian_republic"} text={"Этурийскую республику"} /> оказалось на
                    удивление выгодным.</p>
            </>
    }

}

