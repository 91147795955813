import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_old_space() {
    return {
        "date": <span>-</span>,
        "type": <span>регион галактики</span>,
        "owned": <span></span>,
        "region": <span></span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "people": <span>от землян до агатонцев</span>,
        "bs_universe_currencies": <span>любые</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Старый космос</span> – область,
                    которую люди заселили в самые древние времена. К нему можно отнести <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархию"} />, <TextInlineLink
                        name={"bs_state_tallesian_principality"} />, империю
                    Элат и пару десятков планет-государств.</p><p className="card-text text-justify"><span
                        className="strong">Последствия «Кидонианки»:</span></p><p className="card-text text-justify">Старый
                            космос <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"трещит по швам"} /> из-за михъельмцев
                    и вряд ли эта каша остынет в ближайшие лет пятьдесят.</p>
            </>
    }
}

