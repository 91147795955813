import wiki from "./cms/wiki.json";

function SidebarBSTimeline() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>История мира</a>
    </div>
}

function SidebarBSMap() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_maps"].link}>Карта мира</a></div>

}

function SidebarBSUnenclosed() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_unenclosed"].link}>Невошедшее</a>
    </div>
}


export function SidebarBSWikiAll() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_wiki_all"].link}>Полный список
        страниц</a></div>
}

function SidebarBSWiki() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Энциклопедия</a>
    </div>
}


function SidebarBSGallery() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Галерея</a></div>
}


export { SidebarBSTimeline, SidebarBSUnenclosed, SidebarBSGallery, SidebarBSWiki, SidebarBSMap };