import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Alkanarra_tristan() {
    return {
        "name": "Тристан Алканарра",
        "fullname": <span>Тристан <small>«Завоеватель»</small> Алканарра</span>,
        "born": <span>~4200 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>~200</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "imgs": [
            { "short": "bs_char_alkanarra_tristan_1_jpg_500", "big": "bs_char_alkanarra_tristan_1_jpg_full" },
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Тристан</span> – первый из династии Алканарра, правившей земными
                    территориями около 4200 лет. Вопреки расхожему мифу, он родился не в грязных трущобах Земли, а в
                    элитном мегаполисе на поверхности Месяца, земной луны. Его родителями были бизнесмен и светская
                    львица, хотя со временем он и попытался скрыть этот факт своей биографии: народу следовало считать,
                    что их правитель такой же простой парень, как и они сами.
                </p>
                <p className="card-text text-justify">
                    Ближе к 16 Тристан переехал с родителями на поверхность Матушки-Земли, где жизнь заметно отличалась
                    от сытых городов спутника. Большая часть прародины человечества уже представляла собой трущобы или
                    пустоши со следами добычи полезных ресурсов. Жители этих мест зачастую работали за копейки на
                    предприятиях дельцов, подобных Алканарра-старшему. Однако это не помешало Тристану найти с ними
                    общий язык.
                </p>
                <p className="card-text text-justify">
                    Вопреки остальным ребятам своего социального положения он предпочитал общаться не с одноклассниками
                    из престижной школы, а с парнями из трущоб. Там он понял, что его дорогая одежда, манеры,
                    образованность не только вызывают зависть и желание ограбить. Они дарят возможности. Харизма
                    Тристана позволила ему
                    влиться в местные круги и обзавестись самой настоящей бандой.
                    Парень очаровал местных ребят,
                    убедив их, что в душе является одним из них. Просто ему больше повезло в этой жизни. Он обещал
                    своим новым знакомым, что поможет выбиться в люди, когда обзаведется своим бизнесом. И улица
                    приняла его, научив всем грязным приемам, которым учился среднестатистический житель земных
                    трущоб <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"ранней Световой эпохи"} />.
                </p>
                <p className="card-text text-justify">
                    Так земные переулки подарили ему множество полезных знакомств, большую часть из которых представляли
                    собой будущие рэкетиры, домушники, киллеры и просто наемные громилы. Еще в юношестве Тристан
                    научился находить таких людей, завоевывать их доверие и пользоваться услугами.
                </p>
                <p className="card-text text-justify">
                    С их помощью он и вел дела. В будущем, получив от отца деньги на свое дело, Тристан сразу начал с
                    того, что запугал конкурентов и всех, кто мог доставить хоть малейшие проблемы. Он создал себе
                    локальную монополию сначала на обычную законную торговлю автозапчастями, а затем и на незаконный
                    оборот оружия. Уже к 30 годам основателя «величайшей династии», как ее зовут некоторые, знали как
                    Тристан Огнестрел. А к 40 парень контролировал большую часть теневого оборота оружия
                    в <TextInlineLink name={"bs_star_sun_zero"} text={"Солнечной системе"} />.
                </p>
                <p className="card-text text-justify">
                    Но на этом он не желал останавливаться. Мечтой Тристана была власть не только над уличными
                    бандитами, но и над земной богемой. Алканарра видел, насколько шатко положение правительств
                    прародины человечества и понимал, насколько просто человеку с ресурсами будет взять свою долю.
                </p>
                <p className="card-text text-justify">
                    Многочисленные войны ослабили земных лидеров, а народы устали слушать обещания вечного процветания,
                    которое наступит с очередной основанной колонией лет через N. И Тристан решил перенести принцип,
                    которым подчинил улицу, на целую галактику.
                </p>
                <p className="card-text text-justify">
                    Он воспользовался имевшимися колоссальными ресурсами, чтобы избраться лидером одного из хлипких
                    земных государств, название которого в истории не сохранилось. На этом посту Тристан предложил
                    коллегам из других влиятельных держав простой и надежный план: как снизить недовольство народов и
                    разбогатеть. Потребовалось всего-то снарядить небольшой военный флот, каких было уже множество
                    отправлено в черную пустоту, и вместо полного завоевания отбившихся от рук колоний начать просто
                    грабить их.
                </p>
                <p className="card-text text-justify">
                    Земляне были помешаны на контроле других миров, однако куда выгоднее оказалось заставлять эти миры
                    слать на Землю ресуры не силой, а страхом. Обладая передовыми на тот момент световыми двигателями
                    и вооружением, солдаты объединенного земного флота смогли относительно быстро добираться до
                    развитых колоний и давать им выбор: либо вы раз в год отправляете к нам корабль с едой и другими
                    полезными материалами, либо мы опустошаем вашу планету и просто забираем это.
                </p>
                <p className="card-text text-justify">
                    Колонии, как правило, не имели ни ресурсов, ни населения, ни даже банально технологий для создания
                    полноценной армии: на сто тысяч бойцов, отправленных с Земли, они были способны выставить не больше
                    тысячи. Раньше этого хватало, так как война шла в основном партизанская. Земляне тратили ресурсы в
                    одном далеком мире, не имея возможности напасть на другие. Им приходилось десятилетиями копить силы
                    для новой атаки.
                    Однако тактика выжженного мира, предложенная Тристаном, поменяла правила игры: теперь было выгоднее
                    платить дань,
                    а не сопротивляться.
                </p>
                <p className="card-text text-justify">
                    Меньше чем за 30 лет Тристан, оставаясь у власти, смог закабалить десятки миров. На Землю потекли
                    ресурсы, которых хватило, чтобы вытащить из бедности миллиарды людей, даже несмотря на
                    апокалиптический уровень коррупции. Это дало Алканарра поддержку народов и полную свободу действий:
                    де-юре оставаясь лидером одной из многих стран, он стал де-факто управлять всей земной внешней
                    политикой. А внутренняя его и не интересовала.
                </p>
                <p className="card-text text-justify">
                    Тристан прожил очень долгую жизнь, ни на день не останавливаясь на пути покорения <TextInlineLink
                        name={"bs_space_the_old_space"} text={"Старого космоса"} />.
                    Он был одним из первых Homo sapiens, сумевших дотянуть до 200 лет - безумная цифра по тем временам.
                    Даже ультрабогатые люди жили не больше 120.
                </p>
                <p className="card-text text-justify">
                    Умер он, считая, что создал первую в истории <TextInlineLink name={"bs_state_the_great_monarchy"}
                        text={"межзвездную империю"} />. Однако
                    до
                    полноценного государства было еще очень далеко: потомкам Тристана, захватившим власть после него,
                    потребовались столетия, чтобы разместить свои администрации на «завоеванных» планетах. И даже
                    после этого проблемы со связью и медлительность коммуникаций приводили к тому, что уже через
                    одно-два поколения жители колоний вообще забывали, что являются частью некой империи.
                    Они просто платили дань прародине человечества, считая ее своеобразным налогом на отсутствие
                    проблем,
                    и строили такое общество, какое хотели.
                </p>
                <p className="card-text text-justify">
                    Тем не менее вклад Тристана в историю (пусть и мрачный) не стоит умалять. Он совершил невозможное:
                    добился, чтобы одна
                    планета, расположенная в триллионах километров от другой, взяла под контроль эту самую другую и
                    «убедила» ее делиться ресурсами. Алканарра показал: межзвездные империи могут существовать, хотя
                    до него многие рассматривали войны за планеты лишь как глупую и невыгодную затею.
                </p>
                <p className="card-text text-justify">
                    За империями, как это всегда бывает, последовали и первые межзвездные республики. После его походов
                    люди отправлялись в далекие миры, сознательно планируя заселить несколько планет и сразу объединить
                    их в одно государство: для выгодной торговли и, разумеется, обороны. Колонисты брали с собой
                    чертежи и оборудование для создания технологий орбитальной защиты, целые НИИ тайком переезжали с
                    Земли на
                    <TextInlineLink name={"bs_planet_agathon"} text={"Агатон"} />! Люди стали мыслить глобально,
                    рассматривая планеты лишь как источник ресурсов, а не
                    якорь, к которому привязана цивилизация.
                </p>
            </>
    }
}