import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ellania() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"} />,
        "habitats": <span>23 млрд. на поверхности + 212 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонцы"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "mother": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "g": <span>0.12 земной</span>,
        "atmosphere": <span className="text-danger">нет</span>,
        "biosphere": <span>нет</span>,
        "system": <TextInlineLink name={"bs_star_horus"} text={"Хор"} />,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Виковира"} />,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} /></span>,
        "type": <span>луна-<TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} /></span>,
        "imgs": [{ "short": "bs_wiki_agathon_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">О луне</h3>
                <p className="card-text text-justify">
                    <span className="strong">Эллания</span> воспринимается
                    агатонцами как спальный квартал. Это просто небольшой планетоид на орбите Агатона, застроенный
                    многоэтажными зданиями и парковыми аллеями. Гравитация и атмосфера поддерживаются в каждом квартале
                    отдельно. Существует система пеших переходов между всеми крупными жилыми зонами: если вооружиться
                    упорством и кучей свободного времени, большую часть луны можно обойти на своих двоих.
                </p>
                <p className="card-text text-justify">
                    На Эллании очень много открытых пространств: парков, площадей и бульваров, над которыми нет крыши -
                    только атмосферный щит удерживает воздух и людей внутри. На случай отказа питания предусмотрены
                    резевные кратковременные щиты, которые сохранят атмосферу, пока не прибудут спасатели.
                </p>
                <p className="card-text text-justify">
                    Есть здесь и люди, которые предпочли построить некое подобие загородных домов, но из-за отсутствия
                    биосферы любоваться из окна можно только звездами и равномерным серым ландшафтом.
                </p>
            </>
    }
}
