import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Planet_types() {
    return {
        "top": "",
        "text":
            <>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 1em 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["tamanrasett"].link}>
                            <img style={{ "maxWidth": "150px" }} alt="Таманрасетт" src={imgs["tamanrasett_500"].link} />
                        </a>

                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Аграрный мир</span> –
                    планета, предназначенная в основном для производства продуктов питания в любом виде:
                    свежих, сублимированных и т.д. Как правило в таких мирах живет очень мало людей (до
                    нескольких миллионов), которые управляют армиями роботов-комбайнов, станциями
                    гидропоники или автоматизированными фабриками питательных смесей. Чаще всего жители
                    этих миров - обеспеченные бизнесмены, единолично владеющие огромными территориями,
                    которые используют по своему усмотрению (к ним относятся и крупные корпорации). Реже
                    - арендаторы, выкупившие право использовать небольшие зоны аграрного мира под свою
                    ферму на определенный срок. Еще реже - не очень богатые члены коммуны, которые
                    коллективно владеют землей или арендуют ее для ведения общего дела.</p>
                <p className="card-text text-justify">Примеры: <TextInlineLink name={"bs_planet_macktobykhar"}
                    text={"Мактубихар"} />, <TextInlineLink
                        name={"bs_planet_sleeping_suns_maison"} text={"Поместье Спящих солнц"} />, <TextInlineLink
                        name={"bs_planet_scellurae"} text={"Сцеллура"} />, <TextInlineLink name={"bs_planet_surghanne"}
                    />, <TextInlineLink
                        name={"bs_planet_cartagena"} text={"Картахена"} /></p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["cydonia"].link}>
                            <img style={{ "maxWidth": "100%" }} alt="Кидония" src={imgs["cydonia_sm"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Метрополия</span> –
                    высокоурбанизированная планета с большим населением и слабо развитой пищевой
                    промышленностью, не способная обеспечить сама себя пищей и предметами первой
                    необходимости. Обычно так происходит не столько из-за перенаселения, сколько из-за
                    удорожания земли. Когда мир становится престижным местом и все хотят в него
                    переселиться, цены неизбежно растут, размещать производство становится не выгодно, и
                    оно переезжает в другие миры. А чаще всего сначала на орбиту, а когда и там все
                    дорожает – на орбиты других планет и так все дальше и дальше с каждым веком.
                    Учитывая, сколько площадей требуется, чтобы вырастить десяткам миллиардов
                    привередливых Homo sapiens вкусной еды, иногда под одну метрополию приходится
                    выделить целую аграрную планету.</p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["new_havana"].link}>
                            <img style={{ "maxWidth": "100%" }} alt="Новая Гавана" src={imgs["new_havana_500"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify">Минус
                    подхода очевиден: мало того, что во время войны такой мир легко отрезать от путей
                    снабжения, так еще и пираты часто паразитируют на торговых маршрутах в поисках легкой
                    добычи. Из-за этого приходится оттягивать флот от границ страны к центру и строить
                    укрепленные военные станции, чтобы защищать богему от звездных бродяг. Как следствие,
                    каждая такая планета влетает налогоплательщикам в немалую копеечку. Метрополии бывают не
                    только столичные – в некоторых странах их десятки.</p>

                <p className="card-text text-justify">Отдельно можно выделить т.н. полуметрополии: миры со
                    средним размером населения, способные обеспечить себя лишь базовыми товарами.</p>
                <p className="card-text text-justify">Примеры всех видов метрополий: <TextInlineLink
                    name={"bs_planet_agathon"} text={"Агатон"} />, <
                        TextInlineLink name={"bs_planet_earth"} text={"Земля"} />, <TextInlineLink
                        name={"bs_planet_ajliraen"} text={"Айлирэн"} />, <TextInlineLink name={"bs_planet_samborae"}
                            text={"Самбора"} />, <TextInlineLink
                        name={"bs_planet_telenmark"} text={"Теленмарк"} />, <TextInlineLink
                        name={"bs_planet_khal_vernon"} />, <TextInlineLink
                        name={"bs_planet_hela"} text={"Хела"} />, <TextInlineLink
                        name={"bs_planet_helena"} />, <TextInlineLink
                        name={"bs_planet_cassini"} text={"Кассини"} />, <TextInlineLink name={"bs_planet_ackron"} />
                </p>

                <p className="card-text text-justify"><span className="strong">Планета-сад</span> – мир,
                    уже способный поддерживать жизнь до терраформации людьми и имеющий естественную биосферу.</p>
                <p className="card-text text-justify">Примеры: <TextInlineLink
                    name={"bs_planet_cydonia"} />, <TextInlineLink
                        name={"bs_planet_helena"} text={"Хелена-у-Тэллаусы"} /></p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["valoria"].link}>
                            <img style={{ "maxWidth": "150px" }} alt="Валория" src={imgs["valoria_500"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Промышленный (шахтерский) мир</span> –
                    планета, отведенная целиком под производство непродовольственных товаров или добычу
                    ископаемых. Обычно их биосфера испорчена многолетними ядовитыми выбросами, но еще
                    чаще - ее просто никогда не было, потому что планету не терраформировали. В последнем
                    случае жизнь без средств защиты, разумеется, на планете невозможна.
                </p>
                <p className="card-text text-justify">Примеры: <
                    TextInlineLink name={"bs_planet_valoria"} />, <
                        TextInlineLink name={"bs_planet_daydaris"} />, <
                        TextInlineLink name={"bs_planet_alkiviad"} />
                </p>
            </>
    }
}
