import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vataary_vector() {
    return {
        "date": <span>-</span>,
        "type": <span>территория</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Мегасфен"} /></span>,
        "people": <span>айлири (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_vataary_vector_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Вектор Ватаари</span> – линия
                    заселенных миров на окраине Доминации. Здесь можно найти в основном <TextInlineLink
                        name={"bs_universe_planet_types"} text={"промышленные или шахтерские"} /> миры.</p>
            </>
    }
}

