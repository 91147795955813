import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Priumviratus() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>правительственная организация</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_de_silgoni_ursula_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_colbert_michel_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_scalligan_lloyd_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>парламент Приоритета</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Приумвират переехал в <strike>новое здание</strike> <
                    TextInlineLink name={"bs_state_cydonian_priority_priumviratas"} text={"новую статью"} />.</span></p>
            </>
    }
}
