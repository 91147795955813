import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Alkiviad() {

    return {
        "date": <span>320 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "governor": <span></span>,
        "habitats": <span>засекречено</span>,
        "people": <span></span>,
        "moons": <span>нет</span>,
        "mother": <span></span>,
        "g": <span>1.15 земной</span>,
        "atmosphere": <span className='badge bg-warning'>вредна для людей</span>,
        "biosphere": <span>не существует</span>,
        "system": <span>Алкивиада</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} /></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"промышленная"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span></span>,
        "imgs": [{ "short": "bs_wiki_alkiviad_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Алкивиад-4</span> – четвертая планета
                    от одноименной звезды, небольшой промышленный мир в Демократическом космосе. Несколько столетий его
                    разрабатывали агатонские рудные компании, но когда ресурсы заметно истощились, а атмосфера стала
                    малопригодна для дыхания, добыча стала невыгодной. Государство помогло корпорациям избавиться от
                    убыточного актива: выкупило планету и построило на поверхности несколько тысяч трудовых лагерей.
                </p><p className='card-text text-justify'>Здесь проходят перевоспитание преступники, чьи проступки
                    были достаточно вредны для общества, чтобы оно пошло на самую суровую из разрешенных в Республике
                    мер наказания: смертельно опасную каторжную работу. В отличие от других тюрем, где заключенные
                    заняты безопасными и относительно чистыми производствами, колонии Алкивиада предоставляют лишь
                    возможность провести десятилетия в темных грязных шахтах. Если повезет, можно попасть на карьер на
                    свежем воздухе (хотя «свежим» его назовет только форма жизни, не имеющая легких), но жизнь от этого
                    лучше не станет.</p>
            </>
    }
}

