import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Colbert_simone() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} text={"«Похитители маяков»"} />,
        "name": "Симона Колберт",
        "fullname": <span>Симона Колберт</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"хеллесианка"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "born": <span>66 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>65</span>,
        "dad": <span><TextInlineLink name={"bs_char_colbert_michel"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Эсквариат"} /> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Приоритета"} /></span>,
        "imgs": [
            { "short": "bs_char_colbert_simone_1_jpg_500", "big": "bs_char_colbert_simone_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юность</h3>
                <p className="card-text text-justify">
                    <span className="strong">Симона</span> родилась на Хеле, как и небезызвестный <TextInlineLink
                        name={"bs_char_skarsgard_oliver"} />. Отец, Михель Колберт, был выше по социальному статусу, чем ее
                    мать, поэтому Симона по старой традиции взяла его фамилию. Это было верным решением, поскольку
                    в год ее совершеннолетия отец получил должность губернатора системы <TextInlineLink
                        name={"bs_star_hela_gemini"} />.
                </p>
                <p className="card-text text-justify">
                    После школы девушка провела несколько лет на <TextInlineLink name={"bs_planet_bassian"}
                        text={"Бассиане"} />, на углубленных
                    курсах по <TextInlineLink
                        name={"bs_science_astromechanics"} text={"астромеханике"} /> и физике космических
                    полетов. Курсы были платные, поэтому Симона, отказавшись от финансирования семьи, работала в ночную
                    смену в местном экспериментальном конструкторском бюро. Без образования у нее не было шансов
                    получить
                    хорошую должность и приходилось заниматься мелкой административной работой, выслуживаясь перед
                    опытными
                    инженерами. Однако это дало ей неограниченный доступ ко многим чертежам и
                    теоретическим
                    выкладкам бассианских умельцев - прекрасный источник информации для того, кто хочет заниматься
                    созданием космокораблей.
                </p>
                <p className="card-text text-justify">
                    После курсов, имея в запасе не только сертификат, но и обширные знания, полученные в бюро, Симона
                    направилась прямиком
                    в <TextInlineLink name={"bs_state_cydonian_priority_science"}
                        text={"«Бриэнку»"}
                        popup={"Хеллесианская академия торгового и военного флота имени Бриэнны де Эстрада"} />,
                    где без труда получила стипендию и даже подработку при университете. Там девушка провела почти 10
                    лет,
                    учась создавать маршевые, световые и маневровые двигатели для кораблей любых классов.
                    Ее бакалаврская работа, <span className='code'>«Теоретические способы применения экзоматерии в
                        двигательных установках сверхтяжелых боевых кораблей»</span>, привлекла внимание рекрутеров
                    Эсквариата. Мало какое оборонное ведомство может пройти мимо человека, размышляющего о том, как
                    создать что-то крутое и военное.
                </p>
                <p className="card-text text-justify">
                    Сначала Симона хотела отказаться и даже взяла целый месяц на раздумия. Главное сомнение было в том,
                    что Эсквариат работает на королеву, а королева - это всегда политика. Симона терпеть не могла
                    отцовскую
                    работу, его друзей и вечное лицемерие, с помощью которого он втирался в доверие к людям и даже ИИ.
                    Однако когда вербовщики заверили, что рядовые сотрудники Эсквариата никак не связаны с политикой
                    и получают гигантские бюджеты для творчества в области прорывных технологий, сомнения моментально
                    развеялись.
                </p>
                <p className="card-text text-justify">
                    Чтобы стать полноценным эсквайром, Симоне пришлось пройти боевую подготовку там же, в «Бриэнке».
                    На какое-то время девушка сменила лабораторный халат на доспехи военного образца. Ей так понравилось
                    носить броню, что даже годы спустя Колберт предпочитала работать в мастерской именно в боевом
                    облачении, а не гражданском защитном костюме.
                </p>
                <p className="card-text text-justify">
                    Катастрофическая неприязнь отца к королеве, начальнице Симоны, стала триггером многих
                    семейных ссор. Ну и еще тот факт, что Колберт-младшая любит называть коллег отца из <TextInlineLink
                        name={"bs_state_cydonian_priority_priumviratas"} text={"Приумвирата"} /> бесполезными
                    нахлебниками и выдумывать им
                    оскорбительные прозвища.
                </p>
                <h3 className="title-bg">Служба в Эсквариате (<TextInlineLink name={"book_hyperbeacons"}
                    text={"«Похитители маяков»"} />)</h3>

                <p className="card-text text-justify">
                    С <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Оливером"} /> Симона познакомилась сразу
                    после начала службы в Эсквариате и бугай всегда вызывал у нее
                    смешанные
                    чувства. Его агрессия и дерзость в принятии решений часто приводили к серьезным проблемам для всего
                    ведомства и Колберт, даже не являясь боевым офицером, не раз страдала от его действий.
                </p>
                <p className="card-text text-justify">
                    Когда королева <TextInlineLink name={"bs_char_van_gloria_adel"} text={"ван Глория"} /> неожиданно
                    поставила ее на должность руководителя 3-го НИИ Минобороны Приоритета, этих проблем стало только
                    больше - именно Оливер испытывал в бою новые разработки ее подчиненных. Сам же 3-й НИИ (он же
                    «Объект №33 «Паллада» под управлением инженерно-исследовательского корпуса Её Величества») - главный
                    и крупнейший исследовательский центр Эсквариата, поэтому став его руководителем, Симона фактически
                    возглавила все научное крыло подразделения.
                </p>
                <p className="card-text text-justify">
                    Примерно за год до Таманрасетт Симона узнала о массовых исчезновениях людей по всей галактике.
                    Сначала в Приоритете пропал боевой флот, не оставив и следа. Затем - население целой
                    планеты, <
                        TextInlineLink name={"bs_planet_exile_1"} text={"Исхода-1"} />. Девушка задействовала все ресурсы
                    Эсквариата, чтобы вычислить источник угрозы, но помог ей в итоге <
                        TextInlineLink name={"bs_char_hartrey_kellays"} />, невзрачный айтишник из <TextInlineLink
                        name={"bs_state_cydonian_adimensional_communications"} text={"КНК"} />. С помощью его наработок
                    казенный ИИ смог выяснить детали похищения людей у Исхода и Симона в очередной раз отправила
                    Оливера провести разведку боем.
                </p>
                <p className="card-text text-justify">
                    Там, на поверхности <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбенны"} />,
                    случился первый <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"контакт"} /> людей
                    и <TextInlineLink name={"bs_spec_vitruvianus"} text={"витрувианов"} />,
                    который перевернул историю галактики.
                </p>


            </>
    }
}
