import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ackron_nebulae() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>газопылевая туманность</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлирэн"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"} /></span>,
        "imgs": [{ "short": "ackron_nebulae", "big": "ackron_nebulae" }, {
            "short": "bs_wiki_ackron_map_ru",
            "big": "bs_wiki_oecumene_map_ru"
        }],
        "appears":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Акронская небула</span> –
                    густозаселенная область пространства в глубине Доминации. Здесь сосредоточен центр политической и
                    экономической мощи страны, а также огромная часть населения.
                    Названа в честь <TextInlineLink name={"bs_planet_ackron"} text={"Акрона"} />, одной из первых
                    открытых планет в этом регионе.
                </p>
            </>
    }
}
