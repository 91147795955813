import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Agathonians() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "state": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_kato_aemilius_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"}
            text={"земной"} /></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Агатонцы</span> – жители
                    Агатона, <TextInlineLink name={"bs_state_urtm"} text={"Объединенных Республик Малой Короны"} /> и
                    прилегающих звездных систем.
                    Люди любят все упрощать, поэтому агатонцами часто называют всех жителей <TextInlineLink
                        name={"bs_space_democratic_space"} text={"Демкосмоса"} /> и территорий
                    в рукавах Стрельца и Персея по направлению к ядру от Земли. То есть, всех, кто живет вдоль
                    гипертрассы Земля-Агатон-Тернерианский предел, если двигаться от <TextInlineLink
                        name={"bs_state_aethurian_republic"} text={"Этурийской республики"} />.
                    Например, <TextInlineLink name={"bs_state_tribalon_republic"} text={"трайбалонцев"} />.
                </p>
                <p className="card-text text-justify">
                    Следует отличать от бризеанцев, живущих в Демокосмосе, но серьезно угнетаемых агатонскими
                    корпоратократами
                    в Бризе.
                </p>
                <h3 id={"language"} className="title-bg">Язык</h3>
                <p className="card-text text-justify"><span
                    className="strong">Арум (arum linga – золотой язык)</span> или <span
                        className="strong">«агатонский»</span> – официальный язык <TextInlineLink
                        name={"bs_state_republic_of_tiara_minor"} text={"Республики Малой короны"} /> и <TextInlineLink
                        name={"bs_state_galactic_democratic_alliance"} text={"Демократического содружества"} />.
                    Считается прямым потомком романских языков <TextInlineLink name={"bs_planet_earth"}
                        text={"Земли"} /> и
                    прибыл на <TextInlineLink name={"bs_planet_agathon"} text={"Агатон"} /> вместе с первой волной
                    переселенцев
                    на заре световых перелетов. За прошедшие тысячелетия сильно видоизменился, но все еще сочетает в
                    себе следы многих старых диалектов: испанского, французского, итальянского и других. В остальной
                    галактике его как правило зовут «агатонским», потому что «золотой» – уж слишком претенциозно со
                    стороны жителей одной планетки.</p>
                <div className="alert alert-danger">
                    <p>Продолжение следует</p>
                </div>
            </>
    }
}
