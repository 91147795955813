import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Religion_arthurians() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>религия</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Основатель: </strong>
                        <span>
                            <TextInlineLink name={"bs_char_arthur_the_founder"}
                                text={"Артур Калибурн"} />
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Пантеон:</strong>
                        <span><TextInlineLink name={"bs_creature_hydra"} />, <TextInlineLink
                            name={"bs_creature_hartrhon"} />, <TextInlineLink name={"bs_creature_cyllopus"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Артурианство" src={imgs["bs_religion_arthur_1"].link} fetchpriority="low" />
                </div>
                <p className='card-text text-justify'><span className='strong'>Артурианство</span> – древний
                    культ <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} />, первое в истории учение,
                    зародившееся уже после расселения людей по галактике. Представляет собой философскую и
                    нравственную систему, выстроенную вокруг дневников полумифического капитана <TextInlineLink
                        name={"bs_char_arthur_the_founder"} text={"Артура Калибурна"} /> и его
                    синкретических взглядов на вселенную. В них есть место как строго научным теориям, так и
                    откровенно фантастическим замечаниям о существовании исполинских космических
                    монстров. <TextInlineLink name={"bs_creature_hydra"} text={"Гидра"} />, <TextInlineLink
                        name={"bs_creature_hartrhon"} text={"Гартрон"} />, <TextInlineLink name={"bs_creature_cyllopus"}
                            text={"Циллопус"} /> и другие
                    создания из детских
                    сказок аламарси переосмыслены Артуром и возведены в ранг реальных.</p><p
                        className='card-text text-justify'>Дневники Артура хранятся на разных <TextInlineLink
                        name={"bs_tech_hiveship"} text={"кораблях-ульях"} />, периодически перемещаются,
                    разделяются и снова собираются в одном месте. Когда и как это произойдет, знают лишь единицы и никто
                    из них не позволит чужакам увидеть священные страницы. За всю историю только несколько ученых сумели
                    поработать с текстами, но невозможность проверить выводы нивелирует их ценность.</p>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginLeft: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Артурианство" src={imgs["bs_religion_arthur_2"].link} fetchpriority="low" />
                </div>
                <p
                    className='card-text text-justify'>Несмотря на это, в реальности Артура как исторической личности
                    сомневаться не приходится. Факты из независимых источников доказывают, что несколько тысячелетий
                    назад и правда существовал некий капитан, сплотивший вокруг себя аламарси. Частично уцелевшие
                    хроники древних планетарных поселений указывают на загадочного лидера кочевников, сумевшего
                    объединить разрозренные и часто конфликтующие флоты в единую армаду. Если верить губернаторам первых
                    земных колоний, этот человек говорил от лица всех кочевников, что казалось невероятным достижением:
                    в те времена разные флоты аламарси не могли ужиться на одной территории.</p><p
                        className='card-text text-justify'>Еще одним доказательством реальности Артура можно считать отрывки
                    дневников его современников-аламарси: ученые неоднократно находили в обломках кочевых флотов
                    рукописи, датированные подхоящей эпохой. Там описание Артура и его деятельности совпадает со всем,
                    что о нем говорили жители планет. Согласно этим документам, он был одним из создателей внеземной
                    цивилизации Homo sapiens: разработал сложную систему надпространственных маяков (пока еще
                    не <TextInlineLink name={"bs_tech_grn"} text={"ИнтерСеть"} />), которая вела заблудившиеся корабли к
                    базам первых аламарси (для тех времен это был прорыв) и заложил основы общественного строя
                    кораблей-ульев. За это кочевники прозвали его Основателем.</p>
                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Артурианство" src={imgs["bs_religion_arthur_3"].link} fetchpriority="low" />
                </div>
                <p
                    className='card-text text-justify'>Цели Артура остались не ясны: объединение такого числа кораблей в
                    один флот означает необходимость во много раз увеличить добычу ресурсов и резкий рост числа
                    логистических проблем. Даже один флот аламарси - очень большое формирование из тысяч кораблей всех
                    форм и размеров. Сведение вместе десятков таких флотов образует армаду, с которой не справится ни
                    один адмирал, а практическая польза от этого почти равна нулю. Некоторые ученые считают, что так
                    Артур пытался защищаться от пиратов, но в те времена само понятие пиратства очень разнилось от
                    современного: топливо стоило очень дорого, скорости были на порядок ниже, а незаселенные
                    пространства занимали почти всю галактику. Поэтому маловероятно, что существовали разбойники,
                    готовые гоняться за потрепанным кочевым флотом, который численно превышает любую пиратскую армаду,
                    но при этом не обладает ресурсами, ради которых в те времена стоило рисковать жизнью. Даже сегодня,
                    тысячи лет спустя, когда улей аламарси стал считаться ценной добычей, очень немногие бандиты
                    решаются вступать в конфликт со столь огромным флотом пусть даже и полугражданских кораблей.</p><p
                        className='card-text text-justify'>Скорее всего верна другая теория: Артур сознательно пытался
                    создать новое учение, поэтому старался оказать влияние на как можно большее число людей.</p>

                <div style={{ "maxWidth": "220px", "padding": "0.25rem", marginLeft: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right" }}>
                    <img style={{ "maxWidth": "200px" }} alt="Артурианство" src={imgs["bs_religion_arthur_4"].link} fetchpriority="low" />
                </div>
                <p
                    className='card-text text-justify'>Кроме философских наставлений, Артур не оставил после себя почти
                    ничего: приписываемые ему изобретения давно исчезли в пустоте космоса, аламарси растеряли свое
                    единство, а каких-либо заповедей или норм поведения этот культ не предписывает. Поэтому многие
                    аламарси одновременно являются последователями Артура и при этом относят себя к какой-либо другой
                    религии, которых на кочевом флоте предостаточно.</p>
                <p className="card-text text-justify">Лишь немногие аламарси пронесли через века абсолютную верность
                    культу
                    и фанатичную веру в учение Артура. Они объединились в секту, прозванную Культом Черного солнца. Его
                    последователи напрочь отрицают любые другие учения, ограничивают контакты с внешним миром (даже с
                    другими
                    аламарси на своем корабле-улье) и периодически совершают странные ритуалы, включающие нанесение
                    татуировок на неизвестных языках, декламирование
                    рукописей Артура и паломничество к системе <TextInlineLink
                        name={"bs_star_hedwig"} text={"Ядвиги"} />.
                </p>
                <p className="card-text text-justify">Именно там закончился путь Артура: он
                    исчез при загадочных обстоятельствах, пытаясь изучить космос вокруг этой звезды. Если верить
                    записям, его корабль пропал с радаров, а затем был
                    обнаружен без пилота. Следов борьбы или космического боя найти не удалось: либо Артур сбежал сам,
                    испугавшись нахлынувшей славы, либо враги обставили покушение как бегство из кочевого флота.</p>
            </>
    }
}
