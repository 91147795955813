import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tajbennae_incident() {
    document.title = "Контакт на Тайбенне || Кидонианка и Кидонианское солнце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Контакт на Тайбенне"} />

            <div className="alert alert-danger">
                <p className="card-text text-justify">
                    <strong>Ниже могут быть
                        спойлеры</strong> к книгам: <TextInlineLink name={"book_hyperbeacons"}
                            text={"«Похитители маяков»"} />
                </p>
            </div>
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Контакт на Тайбенне</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_tajbennae_incident"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right" /> <strong>Дата: </strong> <span>12 месяцев <TextInlineLink
                                                name={"bs_timeline"} text={"ДТ"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right" /> <strong>Место:</strong>
                                            <span><TextInlineLink name={"bs_planet_tajbennae"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right" /> <strong>Участники:</strong>
                                    <span><TextInlineLink name={"bs_char_skarsgard_oliver"} />, <TextInlineLink
                                        name={"bs_char_hartrey_kellays"} />, <TextInlineLink
                                            name={"bs_char_colbert_simone"} /></span>
                                </li>
                            </ul>
                            <p><strong>Связанные
                                статьи:</strong> <>{GetConnections("bs_timeline_tajbennae_incident").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">История</h3>
                    <p className='card-text text-justify'><span className='strong'>Контакт на Тайбенне</span> или <span
                        className='strong'>Тайбеннский инцидент</span> – первое официально зафиксированное боевое
                        столкновение между людьми (кидонианцами) и <TextInlineLink name={"bs_spec_vitruvianus"}
                            text={"витрувианами"} /> (т.н.
                        «морковными
                        гуманоидами»). Ему предшествовали массовые похищения людей витрувианами в разных частях
                        галактики, а непосредственно перед Инцидентом - в системе <TextInlineLink
                            name={"bs_star_last_exodus"} text={"Последнего Исхода"} /> (кидонианский сектор Флёр).
                    </p><p className='card-text text-justify'>На тот момент люди еще не были в курсе, с чем имеют дело,
                        поэтому действовали неосторожно и понесли большие потери. Случайная находка помогла военному флоту
                        под командованием <TextInlineLink name={"bs_char_skarsgard_oliver"}
                            text={"Оливера Скошгарда"} /> отследить похитителей до
                        планеты <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбенна"} />, но из-за статического щита
                        вокруг
                        планеты сканеры не смогли ничего на ней обнаружить. Поэтому эсквайр Скошгард отправил на поверхность
                        группу космодесантников и лично ее возглавил.</p><p className='card-text text-justify'>Солдаты не
                            успели добраться до поверхности: корабли были сбиты <TextInlineLink
                            name={"bs_weaponry_magnetic_cannon"} text={"ЭМ-вспышкой"} />, а связь утеряна.
                        Когда ее удалось восстановить, выяснилось, что почти все бойцы погибли, а Скошгард (единственный
                        уцелевший) наткнулся на неизвестных антропоморфных существ (витрувианов, как их позднее
                        назвал <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивар де Карма"} />). В ходе нескольких
                        стычек
                        ему удалось получить важные разведданные и даже навести флот на точные координаты базы противника:
                        передовой аванпост витрувианов, вместе с огромным количеством живой силы, был уничтожен орбитальным
                        ударом. Сам эсквайр спасся благодаря <TextInlineLink name={"bs_char_the_whip"} text={"Бичу"} />, о
                        чем не сразу доложил командованию, мотивируя это стратегией, которую разработал пришелец. Якобы это
                        помогло оттянуть полноценное вторжение витрувианов и выиграть время для поиска оружия против них,
                        хотя нюансы этого плана до сих пор кажутся многим военным аналитикам сомнительными.</p>
                </div>
            </div>
        </>
    )
}
