import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Travalsar() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "people": <span>айлири</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Травалсар</span>
                    – внутренный сектор Доминации, где можно найти по большей части метрополии и богемные миры,
                    заполненные
                    виллами чиновников и бизнесменов.
                </p>
            </>
    }
}

