import { TextInlineLink } from "./cms/wiki_functions";

let quotes = {

    "bs_planet_cydonia": [
        {
            "text": <p>Зелено-синий шар с вкраплениями белых
                облаков, окруженный кольцами пыли и льда, которые блестели, словно черно-белая радуга.
                Планета сияла в ярких лучах Мираж и ослепляла своей простой, но изящной красотой</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_space_tiara_minor_nebulae": [
        {
            "text": <p>Со стороны Агатона она показалась разноцветным желе,
                которое сплющили, а затем раскатали, словно кусок теста. Огромное пятно переливалось
                голубыми, красными, бирюзовыми и рыжими огоньками ионизированного газа. Звезды яркими
                бусинами блестели в покрывале из пыли и газа, будто многочисленные глаза космического
                насекомого</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_tamanrasett": [
        {
            "text": <p>Тернерианский предел - это условная граница исследованного космоса, а Таманрасетт - пропускной
                пункт.
                Дальше по рукаву Персея путешествовали только аламарси, но они отказались продавать мне карты. Пришлось
                сделать свои</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_planet_new_havana": [
        {
            "text": <p>Гавана встретила меня запахом дождя и мокрой пыли.
                А еще рельсой, направленной в лицо. Запишу на будущее: вольные торговцы не доверяют демократам</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_planet_arville": [
        {
            "text": <p>Э нет, я слишком крут чтобы подыхать на куске льда</p>,
            "author": <><TextInlineLink name={"bs_char_skarsgard_oliver"}
                popup={""} />, <TextInlineLink name={"book_helleon_cold"} popup={""} /></>,
        }
    ],
    "bs_universe_bird_of_passage_guide_to_milky_way": [
        {
            "text": <p>Эта книга может принести вам как пользу,
                так и вред. Рассчитывайте свои силы и действуйте грамотно: наша галактика – чертовски
                опасное место, а людские тела слишком хрупки, чтобы бездумно бросаться в приключения без
                подготовки. Сидя в метрополии, вы можете думать, что современная медицина способна на все... но способны
                ли вы найти
                эту медицину в трех тысячах световых лет от ближайшего ретранслятора?</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_ursula_adams"} /></>,
        }
    ],
    "bs_tech_abspace": [
        {
            "text": <p>Это место зовется Надпространством, потому что находится условно «над» нашей вселенной и очень
                плотно контактирует с ней, но не смешивается. Надпространство словно бы повторяет изгибы мира людей,
                вплотную прилегая к нему. И внутри него больше измерений пространства, чем у нас. Если в нашем мире
                можно двигаться вперед, вбок и вверх, то там… ну, человеческий мозг не способен такое вообразить</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Сдается мне, маяки просто «утаскивают» в Надпространство.<br />
                – Разве это возможно? Ведь привычная нам материя не может существовать в Надпространстве
            </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_colbert_simone"} /> и <TextInlineLink popup={""}
                name={"bs_char_hartrey_kellays"} />, <TextInlineLink
                    name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],

    "bs_weaponry_space_force": [
        {
            "text": <p>Высокий широкоплечий детина, точь-в-точь стереотипный космопехотинец</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        },
        {
            "text": <p>Я в штурмбате служил, парень крепкий</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "book_black_sun": [
        {
            "text": <p>И сказал Господь: Мне отмщение, Аз воздам</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        }
    ],
    "bs_comp_esquirete": [
        {
            "text": <p>Эсквариат не знаменит высокими моральными
                стандартами или стремлением помочь всему человечеству. Но если отбросить предрассудки,
                то они могут принести пользу</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_cassini": [
        {
            "text": <p>Меньше чем через сутки он погрузился на небольшой транспорт до Кассини, столицы сектора, а еще
                через двое – пересел на лайнер «Муромец», который обещал доставить Хартри прямиком на орбиту
                Кидонии</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        },
        {
            "text": <p>Кассини у одноименного солнца секторе Грация: родина
                кассианской оперы, кидонианского балета и эпатажной творческой богемы начала прошлого века.
                Место для тех, кто интересуется поп-культурой, кидонианскими блокбастерами и театральной школой времен
                Трехсотлетней войны</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"}
                popup={""} />, <TextInlineLink
                    name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_planet_michelm": [
        {
            "text": <p>Из туч корабль вышел сразу на ночную сторону планеты. В одну секунду все черным-черно, а в другую
                – вокруг уже мерцает яркий мегаполис, уходящий за горизонт. Небо вспыхнуло морем движущихся светлячков:
                летающие машины в метрополии заменяют звезды</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_malatia": [
        {
            "text": <p>Там находится, до сих пор, кстати, «Институт прикладной метапростанственной механики». Глубже
                копнуть уровень допуска не позволяет</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_the_night": [
        {
            "text": <p>До самого горизонта, насколько видел глаз, раскинулась черная-пречерная пустыня без каких-либо
                перемен цвета. Холмы невозможно отличить от оврагов, а небо – от земли: все слилось в единый пейзаж.
                Вокруг во всех смыслах была Ночь, как иногда зовут Угольную. И только вспышки молний, да взрывы высоко
                над головой позволили отличать низ от верха</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_kyonmar": [
        {
            "text": <p>Мираж испортил только промышленный комплекс в километре от Виктора. Монументальные строения без
                окон рассыпались по степи, как бетонные грибы, и загородили добрую часть горизонта. Над каждым зависло
                несколько кораблей-грузовиков, ждали очередь на погрузку, а между «грибами» расположилась грубо залитая
                бетоном площадь без малейших удобств. Люди приспособили планету для утилитарных целей, об услаждении
                взора никто не задумывался</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_tiora": [
        {
            "text": <p>Королева, разумеется, отправила туда военный флот, но вариантов оказалось немного: либо подорвать
                ракету прямо в шахте и убить кучу народа на Тиоре, либо попытаться перехватить ее в атмосфере до
                светового ускорения, но шансы на удачу в такой ситуации процентов пятьдесят</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_tajbennae": [
        {
            "text": <p>– Эсквайр, на сканерах пусто, – отозвался штурман «Служаки». – Вся планета покрыта очень
                необычной статикой. Радиосвязь на поверхности работает, но микроволновой фон зашкаливает. Излучение явно
                искусственное</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_planet_vustain": [
        {
            "text": <p>Сам Вустен, напротив, мир довольно скучный: огромный газовый гигант, состоящий из серо-коричневых
                и белесых слоев, будто огромный торт. За восемнадцать лет работы в этой клоаке галактики Келлесу такое
                цветовое решение надоело до безумия</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_planet_surghanne": [
        {
            "text": <p>В случае забастовки, сурганнцы могут лишить Землю более чем половины товаров первой
                необходимости, что приведет к гуманитарному коллапсу в столице Монархии</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_earth": [
        {
            "text": <p>Девушка закрыла глаза, чтобы не ослепнуть, и вдохнула полной грудью аромат родины. Это была
                ошибка. В нос ударила гарь, машинное масло и безумная палитра непонятных ароматов: вонючая еда, специи,
                пролитый кофе, горящие полимеры, протекающие канистры с топливом, гниющий мусор, переулок, ставший
                туалетом, дешевые духи и много чего еще. Роза различила максимум половину ароматов и поняла, что вторая
                половина того не стоит</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_star_njorun": [
        {
            "text": <p>Рядом с другими источниками света местную звезду легко принять за далекий фонарь или вообще не
                заметить. Свет от красного карлика в атмосфере Ночи оказался слишком неестественным, поэтому мозг Розы
                облегчения не почувствовал и говорить «спасибо» не стал. Вместо этого он усилил головокружение, на
                всякий случай</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_macktobykhar": [
        {
            "text": <p>Была как-то на Мактубихаре, аграрный мир в Самборе, там люди прямо с грядок едят. Выращивают под
                открытым небом и жуют. Дорого, правда, без гидропоники, зато все натуральное</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_star_sun_zero": [
        {
            "text": <p>– Хорошо. А почему Земное солнце зовут Нулевым?<br />– От него ведется отсчет расстояний по
                галактике. Своего рода аллюзия на нулевой километр всех дорог</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"} /> и<TextInlineLink popup={""}
                name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                    name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_sleeping_suns_maison": [
        {
            "text": <p>Далеко за кромкой воды уже засияли первые лучи Старшей Спящей, раннего солнца этой планеты. Когда
                оно взойдет наполовину, из моря покажется и Младшая</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_naskarae": [
        {
            "text": <p>После новостей о Наскаре многие решили улететь подобру-поздорову и почти никто не соблюдал
                правила. Бедняги бежали прочь с планеты, образуя заторы в удобных для разгона областях, и грозный боевой
                крейсер их не спугнул</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_state_cydonian_adimensional_communications": [
        {
            "text": <p>КНК ГРС «Вустенский рубеж», 477-Атлас на связи. С кем я говорю?</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_hartrey_kellays"} />, <TextInlineLink
                name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_comp_bonecollectors": [
        {
            "text": <p>Чаще всего их находили мертвыми. Дикие миры – кладезь всякого жупела, от которого рельса не
                спасет. Мы даже делали ставки: угадай по фотографии, сколько недотепа протянет на незнакомой
                планете</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_can_beucan_gaereas"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_comp_galactic_security": [
        {
            "text": <p>Международная полиция умеет разве что ловить студентов, качающих пиратские фильмы</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>У меня международная лицензия А+ на провоз ручного оружия. Я – капер Ее Величества королевы
                Кидонии и вы не представляете, сколько мне пришлось повозиться, чтобы получить это разрешение</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_comp_free_navy": [
        {
            "text": <p>...постараюсь найти подмогу среди пиратских шаек. У нас есть контакты пары вольных флотов в
                Старом космосе, возможно, они согласятся отвлечь землян за хорошую плату</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_amnesiac": [
        {
            "text": <p>Фрилансеры Виктора обработали беднягу амнезиаком до состояния оцепенения</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_werner_rita"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_universe_currencies": [
        {
            "text": <p>– Шарм с земным нынче один к тридцати семи, – продолжил де Карма. – Это почти четыре миллиона.
                Квартиру в столице, конечно, не купишь… но вот детей можно хоть на Агатон отправить учиться</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_universe_cydonian_autumn_no_book": [
        {
            "text": <p>Ева слышала про этот праздник: с него начинается кидонианская осень, трехнедельный карнавал по
                всему Приоритету с танцами, салютами и безумными ценами в отелях. Если верить историкам, в этот день
                много лет назад Приоритет получил независимость от Земли. Точнее, выгрыз ее в Трехсотлетней войне</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_engine_overlight": [
        {
            "text": <p>– Он как бы… – Адам несколько секунд смотрел в потолок. – Меняет метрику или что-то в таком духе.
                Количество пространства перед тобой становится меньше, он его перемещает назад, за корабль. Как бы
                сматывает впереди и разматывает сзади, как ребенок с рулоном туалетной бумаги, в общем… – Гэри хохотнул.
                – Внутри, между этими искривлениями, получается небольшая колба, типа пузырек, который двигается через
                космос, на самом деле оставаясь на месте. Просто потому, что пространство само его толкает. Типа как
                внутри продвинутого вакуумного насоса. Так что мы не ускоряемся, по сути, и законов физики не нарушаем.
                Другой вопрос, что на сверхсвет так просто не уйти – наши алькубьерки пока не умеют корежить вселенную
                без хорошего разгона, но рано или поздно получится и это… Ну, а пока приходится сначала разгоняться на
                тягачах и потом уже кривить мироздание и обгонять свет…</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_browdy_adam"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_universe_doab": [
        {
            "text": <p>– А как выглядит этот День всех бомб?<br />– Обычно по всей стране проводят фестивали, какие-то
                шоу, ярмарки – просто массовые гуляния, ничего особого. Военные парады еще. Такое на каждой планете
                есть, я уверен</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"} /> и <TextInlineLink popup={""}
                name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                    name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_hiveship": [
        {
            "text": <p>Семьям выделяют крохотную каюту-студию, отделенную от соседней тремя стенами и занавеской. Именно
                поэтому суда алармаси и называют «ульями»: таких «сот» внутри сотни тысяч и в каждой по два-четыре
                жителя. Душевые, кухни, комнаты отдыха – все общее</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_shield_atmospheric": [
        {
            "text": <p>Тепло в помещениях помогал сохранять атмосферный щит, одно из немногих изобретений современного
                мира, которые монахи согласились сюда привезти. Невидимое поле тонкой пленкой разворачивалось вокруг
                стен и нагревало воздух перед попаданием внутрь</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_mpg": [
        {
            "text": <p>В общем, эта штука каким-то магическим образом не дает нашим внутренним органам вылезти из тела
                через ноздри, когда корабль ускоряется</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_shield_sound": [
        {
            "text": <p>На военных кораблях звуковых щитов не ставят – слишком много энергии нужно на их поддержание.
                Поэтому внутри всегда слышно, когда машина открывает огонь</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_shield_static": [
        {
            "text": <p>ЭМ-обертка. Или статический щит, как его обычно зовут. Это специальное энергетическое поле,
                которое нарушает работу электроники. Может служить для разных целей, но в защищенных помещениях обычно
                блокирует передачу сообщений в человеческий мозг. Я знал шутников, которые настраивали его так, чтобы
                при малейшей попытке сделать что-то незаметно, человека разбивало эпилептическим припадком – пугающее
                зрелище. И мотивирует не жульничать</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_shield_kinetic": [
        {
            "text": <p>– А почему они не долетели? – Оливер подошел к новообретенному боевому товарищу и нащупал в
                воздухе множество висящих кусочков металла.<br />– Слышал что-нибудь про поле кинетической
                регрессии?<br />– Только эти три слова. По отдельности</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"} /> и <TextInlineLink popup={""}
                name={"bs_char_the_whip"} />, <TextInlineLink
                    name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_tech_northern_star_of_niradanhae": [
        {
            "text": <p>В обозримой вселенной подобных маяков всего семь. Конкретно этот зовется Северная звезда
                Нираданхи… Очень древняя штука, возможно, даже старше тебя</p>,
            "author": <>Артур, <TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_tech_ils": [
        {
            "text": <p>Иногда, во время очередного выхода в космос рядом с источником смертельной радиации, Тошико
                приходила в голову забавная ассоциация: тонкий слой вещества, способный предохранить от немыслимо
                страшных воздействий, очень напоминает крохотный листочек, покрывающий причиндалы у древних скульптур.
                При достаточном уровне невезения, листочек легко потерять и тогда тебя ничто не спасет.</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Гражданские зовут его «скафандр», но мы, любители поумничать – «костюм индивидуального
                жизнеобеспечения». Хотя, по сути, это и правда обычный скафандр. Он защитит тебя от космической
                радиации, а она куда сильнее всех тех смешных излучений, которые ты найдешь на любой планете. Плюс, в
                нем есть кислородные свеч… генераторы воздуха, и он легко переносит трение об атмосферу. Инженеры в
                таких чинят технику в космосе, а десант – прыгает на планеты</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_somnambule": [
        {
            "text": <p>– И не опьяняет тебя от таких доз? – поинтересовалась Ева. – Я как-то две недели подряд вкалывала
                «Сомнамбулу», так меня каждое утро шатало и ноги не слушались, будто всю ночь от собак по лесу
                бегала</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_uns": [
        {
            "text": <p>Получается, команда месяц носилась по галактике, подвергалась досмотрам и задержаниям, потому что
                забыла сменить сигнатуры?</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_tech_antimatter": [
        {
            "text": <p>На вопрос, как действует эта штука на людей, Ивар только поморщился. Адам в ответ изобразил
                руками нечто разрушающееся со звуком «ПУФ!»</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_railgun": [
        {
            "text": <p>Рельса – механизм, который выстреливает шнек, то есть пулю, как говорят гражданские.<br />– А
                почему ее так называют? Я думала, это «ствол» или что-то в таком роде.<br />– Потому что «рельсотрон» –
                не самое удобное слово. А «стволами» их называют только в старом кино</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} /> и <TextInlineLink popup={""}
                name={"bs_char_de_levine_rosalie"} />, <TextInlineLink
                    name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_oaac": [
        {
            "text": <p>Землянам придется скучковаться вокруг своих ЗОКов и отбиваться плотным строем, чтобы мы не
                отлавливали их по одному. А значит, все сканеры будут зондировать наш флот вторжения примерно по одному
                вектору</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"} /> и <TextInlineLink popup={""}
                name={"bs_char_de_levine_rosalie"} />, <TextInlineLink
                    name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_magnetic_cannon": [
        {
            "text": <p>Взведенный магнетрон запищал. Существо без малейшего труда вытащило устройство из разжатого
                кулака эсквайра и уставилось с нескрываемым интересом.<br />– На гранату не похоже… – в притворной
                задумчивости протянула тварь.<br />– А вот тут ты ошибаешься.<br />ЭМ-ловушка сработала и ударила
                четырехрукого дурака током. От неожиданности он расслабил хватку, и Оливер сумел выхватить из-за пояса
                другой кинжал. Последние секунды жизни монстра были сочтены</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"} />, <TextInlineLink popup={""}
                name={"book_hyperbeacons"} /></>,
        }
    ],
    "bs_weaponry_paralyzer": [
        {
            "text": <p>Ощущения оказались вовсе не такими, как описал де Карма. Все тело
                пронзила дикая боль, от которой внутренние органы свернулись в комок и
                попросились наружу. Мышцы сковала судорога, но сознания Розали не потеряла. Она
                упала на колени и уперлась кистями в почерневший бетон, пытаясь не стошнить</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_laser": [
        {
            "text": <p>Нормальный боевой лазер в космосе можно увидеть, только если он направлен тебе прямо в глаз. Это
                ведь не кино</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_the_black_sun": [
        {
            "text": <p>– Бойтесь! Ибо Черное солнце скоро взойдет над вами, – он воздел руки небу, словно читал
                проповедь. – Звезды в ваших небесах погаснут, и вы будете смотреть мертвыми глазами в пустоту. И она
                поглотит вас, – на этом моменте взгляд его стал совершенно безумным. – И никогда больше вашему племени
                не видеть света. Так и передайте своим создателям – Бич, Тот-самый, идет за ними. Я пожну их, словно
                чума!</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"} />, <TextInlineLink
                name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_tech_the_key": [
        {
            "text": <p>– Несомненно, там внутри кусочек Черного солнца и между ними есть прямая связь. Но при попытке
                построить маршрут системы маяка не могут найти нужные координаты. Маяк не умеет обсчитывать положение
                внутри сингулярности, как, собственно, и я. Нам придется устранить эту сингулярность.<br />– А значит,
                нужен Ключ…</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"} />, <TextInlineLink
                name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_ships_nyorun_dreams": [
        {
            "text": <p>Для Сони этот крейсер больше, чем просто старая консервная банка. Это дом, это верный друг и
                помощник, с которым она защищала Монархию большую часть Галактической, а потом провела десятилетия,
                удерживая страну от скатывания в анархию и хаос. Сегодня корабль погибнет, сгорит недалеко от звезды, в
                честь которой назван. Останется только пыль да пара килограмм металлолома</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>На полпути к атмосфере капсулу залило оранжевое зарево: «Грезы» вспыхнул и исчез в пламени
                ядерного взрыва</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_weaponry_casm": [
        {
            "text": <p>– Ребятки, вы, конечно, красиво ушли из оцепления, но это не к добру. В вашу сторону пошла УПКР.
                Нет, две… две штуки. Удачи вам там</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_lenisaad_victor"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_timeline_tajbennae_incident": [
        {
            "text": <p>– Подтверждаю. У меня код «111» – контакт с разумным существом. Точнее, с его трупом.<br />В эфире
                воцарилась тишина. Однако стоит отдать Кидонианскому военному флоту должное: они пришли в себя всего за
                пару секунд.<br />– Вас принял, передаю код «111» на «Палладу». Эсквайр Колберт скоро будет на связи. Она
                оповестит королеву</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"} />, <TextInlineLink
                name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_timeline_the_shark_revolt": [
        {
            "text": <p>К сведению граждан Приоритета, планирующих полеты на территорию Великой Монархии: вчера в 20:00
                по центрально-кидонианскому времени Королевская канцелярия получила официальное сообщение с Земли о
                начале вооруженного конфликта на территории сектора Акулы. Уровень опасности – «Красный-Красный». По
                всему сектору регистрируются вооруженные столкновения между регулярной армией Монархии и неопознанными
                военизированными формированиями</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_timeline_war_300": [
        {
            "text": <p>Если верить историкам, в этот день много лет назад Приоритет получил независимость от Земли.
                Точнее, выгрыз ее в Трехсотлетней войне, которая длилась так долго потому, что отправка десанта из
                метрополии в колонию занимала пару десятилетий. И потому, что большинство десантников на той стороне
                радостно вступало в кидонианское ополчение: кому хочется двадцать лет пробыть в анабиозе, чтобы
                сражаться за грязную и ядовитую Землю против цветущей и щедрой колонии?</p>,
            "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_creature_hydra": [
        {
            "text": <p>Гидра – это тварь с кучей голов и неуемным желанием жрать путешественников… да все древние
                выдумки такие</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_creature_cyllopus": [
        {
            "text": <p>А Циллопус – это типа рыбы… у него из головы растет стебель, а на конце солнце или планета, и он
                этой штукой заманивает людей и… да, тоже ест</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_creature_hartrhon": [
        {
            "text": <p>Про Гартрона ничего сказать не могу – я мало слышал о нем. Что-то в духе паука или
                восьминога</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_spec_humans": [
        {
            "text": <p>Вот что мне нравится в людях – ваше неуемное желание ляпнуть ерунду</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },

        {
            "text": <p>– Я слежу, чтобы снаружи не прибыло подкрепление. Иначе тебя могут ненароком угваздать. <br />– Ты
                меня недооцениваешь.<br />– Да с людьми так постоянно: отвернулся, а они уже все </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"} /> и <TextInlineLink popup={""}
                name={"bs_char_the_whip"} />, <TextInlineLink
                    popup={""} name={"book_hyperbeacons"} /></>,
        }
    ],
    "bs_spec_vitruvianus": [
        {
            "text": <p>Когда из пещеры вышел еще десяток гуманоидов, Оливер услышал речь одного из четырехруких. Голос
                был идентичен человеческому, только громче и ниже по тембру. Скошгард попытался представить, как
                существо выглядело раньше, в облике Homo sapiens, но прогнал эту фантазию – если проявить жалость, они
                уничтожат все, что он любит. Этого нельзя допустить</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    "bs_people_cydonians": [
        {
            "text": <p>Вы, кидонианцы, любите ерничать, да? Все-то вам кажется забавным</p>,
            "author": <>офицер <TextInlineLink popup={""} name={"bs_comp_galactic_security"}
                text={"ГБ"} />, <TextInlineLink popup={""}
                    name={"book_cydonian_girl"} /></>,
        }
    ],
    "bs_planet_daydaris": [
        {
            "text": <p>Это ты еще на Дейдарисе не была – там снег старыми носками воняет</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_agathon": [
        {
            "text": <p>Рассматривая с орбиты широкие светящиеся поля городов, растянутые на тысячи километров, она
                невольно вспомнила детство. Просторные улицы, каменные мостовые, стеклянные здания до небес и парки на
                крышах домов. Голубое небо без единого облачка, потоки летающих машин… по ночам они становилось похожи
                на рой пылающих светлячков, за которыми совсем не видно звезд.</p>,
            "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "bs_planet_avvaline": [
        {
            "text": <p>Электромагнитный шум оживленного мира маскирует любую подозрительную
                активность. Идеальное место, чтобы спрятать операционную базу, фонящую микроволнами за парсек.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />, <TextInlineLink
                name={"book_cydonian_autumn"} popup={""} /></>,
        },
        {
            "text": <p>Аввалин – разрекламированный на всю галактику курорт для любителей отдыха на природе, но в то же
                время чтобы рядом
                не бродили агрессивные звери, а официант всегда мог принести салат из морепродуктов.</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_char_jasmine_haervany": [
        {
            "text": <p>– Он лучший пилот и механик из всех, что я видел <br />– С виду и не скажешь</p>,
            "author": <>Ивар и Ясмин, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        },
        {
            "text": <p>На этом драндулете ничего не сломаешь – только сделаешь лучше</p>,
            "author": <>Ясмин, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        },
        {
            "text": <p>Эта штука выдерживает вообще все во вселенной, кроме штатного режима работы. Можешь пролететь
                рядом с солнцем, и она выстоит. Но покатайся неделю на орбиту и обратно, и все само рассыпится.</p>,
            "author": <>Ясмин, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        }
    ],
    "bs_char_the_whip": [
        {
            "text": <p>Вот что мне нравится в людях – ваше неуемное желание ляпнуть ерунду</p>,
            "author": <>Бич, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Меня поражает, с каким рвением ты стремишься быть униженным.</p>,
            "author": <>Бич, <TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        },
        {
            "text": <p>Хреновое у вас прикрытие, господа.</p>,
            "author": <>Бич, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        }
    ],
    "bs_char_de_levine_rosalie": [
        {
            "text": <p>Какие могут быть размышления о судьбе триллионов, если хочется кушать?</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>А корабль и должен быть таким… скрипучим?</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Я не хочу работу, на которой меня будут избивать</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Ты человек вообще?! <br />– Нет!</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_can_beucan_gaereas": [
        {
            "text": <p>Сломанные ноги рулить не мешают</p>,
            "author": <>Гэри, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Не знаю, выглядит как голубиные задницы… Мы это будем есть?</p>,
            "author": <>Гэри, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_de_karma_ivar": [
        {
            "text": <p>Войны рождаются в головах, и только там их можно выиграть. Но я бы посоветовал вам их просто не
                начинать.</p>,
            "author": <>Ивар де Карма, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Ты в курсе, что твой пилот абсолютно неадекватен?<br />– У тебя пилота вообще нет, так что не
                жалуйся.</p>,
            "author": <>Ивар де Карма, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_browdy_adam": [
        {
            "text": <p>Даже не знаю, что хуже: то, что она считает меня раздолбаем, или то, что ты считаешь меня
                невезучим</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Держитесь там за все, что не отвалится при первом попадании. Сейчас покажу, за что меня прозвали
                Спрутом</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Я столько лет пиратствовал не для того, чтобы меня считали простым везунчиком. Я – долбаный
                профи!</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Ох у вас и рожи! – воскликнул он. – Давно не видел такого ужаса!</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Зачем столько ракет?<br />– Мама говорила, всегда нужно делать контрольный.</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Я отвлекся, и в кастрюльку что-то упало.</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_esora_eve": [
        {
            "text": <p>Когда мы встретимся без кандалов, я сломаю тебе оба колена и сделаю очень уродливым
                кузнечиком</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Девушка двумя жестами показала, что сделает с Иваром, если они останутся наедине, а у нее будет
                нож. Или вилка.</p>,
            "author": <TextInlineLink name={"book_cydonian_girl"} popup={""} />,
        },
        {
            "text": <p>И не стоит говорить, что мы обречены, ты вообще-то тут пилот!</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Я тебе сейчас кисти откручу и прибью к жопе, будешь ими штаны придерживать!</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>В армию идут не бабочек собирать. Глупо брать в руки оружие и думать, что это закончится
                хорошо</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>В момент, когда солдат решает выполнить преступный приказ, он перестает быть жертвой и становится
                соучастником</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Кто не рискует, тот не пьет шампанского! <br /> – И не умывается собственной кровью!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> и Ева Эсора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Имеешь что-то против? <br /> – Да! Здравый смысл!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> и Ева Эсора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Ты определенно держишь планку безумной херни, молодец</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Остроумие тебя, может, и не убьет, но мой нож – легко</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Дураки имеют привычку кучковаться и брать числом</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Враги как тараканы: приживаются без спросу</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Параноику живется тяжелее, но дольше</p>,
            "author": <>Ева Эсора, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Радуйся, что ты агент СБК!<br />– А то что, сломал бы об меня руку?</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} /> и Ева Эсора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "book_cydonian_girl": [
        {
            "text": <p>Войны рождаются в головах, и только там их можно выиграть. Но я бы посоветовал вам их просто не
                начинать.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /></>,
        },
        {
            "text": <p>– Нам нужно было сымитировать падение, что мы и сделали.<br />– Нет, мы падаем по-настоящему!<br />–
                Верно, но, как говорится, лучший способ что-то сымитировать – это сделать по-настоящему.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>Вот что мне нравится в людях – ваше неуемное желание ляпнуть ерунду</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        },
        {
            "text": <p>Сломанные ноги рулить не мешают</p>,
            "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэри"} popup={""} />,
        },
        {
            "text": <p>Когда мы встретимся без кандалов, я сломаю тебе оба колена и сделаю очень уродливым
                кузнечиком</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>Какие могут быть размышления о судьбе триллионов, если хочется кушать?</p>,
            "author": <TextInlineLink name={"bs_char_de_levine_rosalie"} popup={""} />,
        },
        {
            "text": <p>– Я думал, у тебя на уме более хитрый план для входа.<br />– Могу оставить тебя делать подкоп,
                если хочешь. Это достаточно хитро: уверен, они не ожидают увидеть у себя посреди двора грязного
                землянина</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>Автопилот – лучший друг пьяного пилота</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
        },
        {
            "text": <p>Остроумие тебя, может, и не убьет, но мой нож – легко</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>До самого горизонта, насколько видел глаз, раскинулась черная-пречерная пустыня без перемен
                цвета. Холмы невозможно отличить от оврагов, а небо – от земли: все слилось в единый пейзаж. Вокруг во
                всех смыслах была Ночь, как иногда зовут Угольную. И только вспышки молний, да взрывы высоко над головой
                позволили отличать низ от верха</p>,
            "author": <TextInlineLink name={"bs_planet_the_night"} text={"«Угольная, 3-й мир от Ньёрун»"} popup={""} />,
        },
        {
            "text": <p>Адам устроился на днище корабля и раскурил безумно вонючую сигарету. Чтобы узнать о его
                присутствии на планете, врагу не потребовалось бы сканировать покрытый тьмой пляж – достаточно было идти
                на запах.</p>,
            "author": <TextInlineLink name={"bs_planet_vitvatersand"} text={"«Витватерсанд, 3-й мир от Селурьи»"}
                popup={""} />,
        },
        {
            "text": <p>Манипуляции на работающей ядерной машине – это верный путь к бессмертию. В памяти потомков.</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
        },
        {
            "text": <p>– И ты ему подчиняешься? Он же маньяк и, по всем признакам, хочет нас убить.</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>– Неужели сам кидонианский герой пришел научить нас выигрывать войны?<br />– Разумеется,
                проигрывать-то вы и без меня научились</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>– Ты что, уже отчет состряпала?!<br />– Да там два слова всего: «Он самоубийца»</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> и <TextInlineLink
                name={"bs_char_esora_eve"} popup={""} /></>,
        },
    ],
    "book_hyperbeacons": [
        {
            "text": <p>Я отрезал твари лапу. Теперь она лежит в подсумке рядом с пальцем гуманоида. Полон решимости
                продолжить коллекционирование.</p>,
            "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""} />,
        },
        {
            "text": <p>Меня поражает, с каким рвением ты стремишься быть униженным.</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        },
        {
            "text": <p>– Гидра, связь пропала.<br />– Тебе она не нужна.<br />– Почему?<br />– У тебя есть я.<br />– А ты
                мне, прости, нафига?</p>,
            "author": <><TextInlineLink name={"bs_char_the_whip"} popup={""} /> и <TextInlineLink
                name={"bs_char_skarsgard_oliver"} popup={""} /></>,
        },
        {
            "text": <p>Кем бы ты ни был, чем бы ни занимался, рано или поздно найдутся люди, которые захотят тебя
                убить.</p>,
            "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""} />,
        },
    ],
    "book_cydonian_autumn": [{
        "text": <p>А нам с ними точно надо в рукопашную? На дистанции никак нельзя?</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэри"} popup={""} />,
    },
    {
        "text": <p>Ты прав, они не смогут захватить планету если не будет планеты.</p>,
        "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""} />,
    },
    {
        "text": <p>С хорошим движком полетит даже кирпич</p>,
        "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
    },
    {
        "text": <p>У нас 32 боевых корабля и крутой ассортимент дебилов. Справимся.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>Только дурак недооценивает важность керамики в ближнем бою.</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэри"} popup={""} />,
    },
    {
        "text": <p>Да-а-а... Это было бы гораздо умнее, чем то, что я собираюсь сделать.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>Хреновое у вас прикрытие, господа.</p>,
        "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
    },
    {
        "text": <p>Ну если надо просто подержать штурвал, то любой дурак сможет. Я, например.</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэри"} popup={""} />,
    },
    {
        "text": <p>Ладно, включили брутальных мужиков и работаем.</p>,
        "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""} />,
    },
    {
        "text": <p>Ивар, как ты вообще умудрялся получать деньги за такую работу?</p>,
        "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""} />,
    },
    {
        "text": <p>– Лети так, чтобы никто не подумал, будто мы собираемся тут что-то взорвать или протаранить.<br />–
            А мы собираемся?<br />– Собираемся.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>Оливер, мысли в правовом поле, пожалуйста. По части нелегальщины Ивар уже постарался за
            двоих</p>,
        "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""} />,
    }
    ]

}

function GetQuotes(props) {
    let quo = quotes[props.name]
    let ret = []
    let menu = []

    for (let currentElement in quo) {
        let elem = quo[currentElement]
        let cl = "carousel-item"
        if (currentElement === "0") {
            cl = "carousel-item active"
            menu.push(
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                    key={"GetQuotes_button" + currentElement}
                />
            )
        } else {
            menu.push(
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={currentElement}
                    aria-label={"Slide " + currentElement}
                    key={"GetQuotes_button" + currentElement}
                />
            )
        }
        ret.push(
            <div
                className={cl}
                key={"GetQuotes_div" + currentElement}
            >
                <blockquote key={"GetQuotes_quote" + currentElement}>
                    {elem.text}
                    <small>{elem.author}</small>
                </blockquote>
            </div>
        )
    }

    return (
        <div
            id="carouselExampleCaptions"
            data-bs-interval="false"
            style={{ "paddingBottom": "1em" }}
            className="carousel slide"
            data-bs-ride="carousel"
            key={"GetQuotes_carousel"}
        >
            <div
                className="carousel-indicators ca-blue"
                key={"GetQuotes_carousel_indicator"}
            >
                {menu}
            </div>
            <div
                className="carousel-inner"
                key={"GetQuotes_carousel_inner"}
            >
                {ret}
            </div>
        </div>)
}

export { GetQuotes }