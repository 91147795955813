import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../components/Quotes";
import { ImgRound3EM, ImgRound3EMFancyBoxArticle } from "../../../components/imgs/imgRound";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

function Book_1_Cydonian_girl() {
    let repo = "book_cydonian_girl"
    let name = wiki[repo].name
    document.title = "Кидонианское солнце - " + name


    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book" /> Кидонианка</span>} />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_cydonian_girl_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Кидонианки"
                            src={imgs["book_cydonian_girl_jpg_ru"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Кидонианка</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo} />
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_montgomery_lorena_1_jpg_200"} /></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt" /> <h6>Статус:</h6> <strong><span
                                className="text-success">опубликована</span></strong></li>
                            <li><i className="far fa-calendar-alt" /> <h6>Дата выхода:</h6> 10.03.2021</li>
                            <li><i className="far fa-list-alt" /> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Кидонианское солнце #1"} /></li>
                            <li><i className="fas fa-angle-double-left" /> <h6>Предыдущая:</h6> <strong><span
                                className="text-danger">нет</span></strong></li>
                            <li><i className="fas fa-angle-double-right" /> <h6>Следующая:</h6> <TextInlineLink
                                name={"book_hyperbeacons"} text={"Похитители маяков"} /></li>
                            <li><i className="fa-solid fa-clock-rotate-left" /> <h6>Место в <TextInlineLink
                                name={"bs_timeline"} text={"истории"} />:</h6> ~14 месяцев до <TextInlineLink
                                    name={"book_tamanrasett_contact"} text={"Таманрасетт"} /></li>
                            <li><i className="fas fa-book-open" /> <h6>Объем:</h6> ~770 стр.</li>
                            <li><i className="fas fa-book" /> <h6>Издательство:</h6> нет</li>
                            <li><i className="fas fa-user-edit" /> <h6>Редактор:</h6> Валерия Likos</li>
                            <li><i className="fas fa-palette" /> <h6>Художник:</h6> </li>
                            <li><h6>Связанные
                                страницы:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className="well">
                        <p className="card-text text-justify">
                            <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивар"} /> и <TextInlineLink
                                name={"bs_char_esora_eve"} text={"Ева"} />, <TextInlineLink name={"bs_people_cydonians"}
                                    text={"кидонианец"} /> и <TextInlineLink
                                name={"bs_people_agathonians"} text={"агатонка"} />, ввязались в чужую войну из чувства
                            долга. В
                            раздираемой конфликтами стране они объединяются, чтобы выжить, но быстро понимают: людские
                            дрязги – мелочь по сравнению с тем, что грозит галактике через считанные месяцы.</p>
                        <p className="card-text text-justify">
                            Пока человечество погружалось в хаос междоусобиц, некто открыл охоту на выходцев
                            с <TextInlineLink name={"bs_planet_earth"} text={"Земли"} />. Контактов с другой разумной
                            жизнью еще не было, но когда за несколько минут исчезает население целой планеты, поневоле
                            поверишь в пришельцев.
                        </p>
                        <p className="card-text text-justify">
                            Ивар и Ева решают выяснить, откуда исходит угроза и кто эта таинственная кидонианка, будто
                            не существовавшая до вчерашнего дня.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"}
                            small={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"}
                            small={"bs_char_browdy_adam_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                            small={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"}
                            small={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"}
                            small={"bs_char_esora_eve_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                            small={"bs_char_the_whip_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_1_full"} small={"earth_landscape_1_200"} />
                        </li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_2_full"} small={"earth_landscape_2_200"} />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt title-bg"><h3>Занимательные факты</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify">
                        <h6>Альтернативное название</h6>
                        <br />
                        Рабочим именем книги было «Странное дитя», но в последний момент перед публикацией я решил
                        сменить
                        на «Кидонианку». Выбор, на самом деле, сложный: старый вариант казался идеальным описанием
                        главной героини, а новый помогал нагнать загадочности. Загадочность победила.
                    </li>
                    <li className="card-text text-justify"><h6>Гэри – собака</h6><br />Имя этого персонажа
                        – <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гэриас кан Бьекен"} />. «Cane» на
                        итальянском значит собака, и то же самое услышит кореец в слоге «гэ». Случайно вышло.
                    </li>
                    <li className="card-text text-justify"><h6>Полное имя Монарха...</h6><br />…звучит как Сиель Агастас
                        Корель Гораций Алканарра. Думаю, ты догадываешься, почему оно не упоминается в таком виде в
                        сюжете.
                    </li>
                    <li className="card-text text-justify"><h6>Эсора – начальница</h6><br />В изначальной версии текста у
                        Евы был целый отряд коммандос в подчинении, их звали «Кошки Велимора». Но за ненадобностью он
                        был вырезан (как и планета Велимор), остался лишь персонаж по имени «Ап» – его ты встретишь в
                        следующей книге.
                    </li>
                    <li className="card-text text-justify"><h6>Ева и Гэри едва избежали смерти</h6><br />По изначальному
                        замыслу они должны были отправиться на тот свет в конце «Кидонианки», но в какой-то момент стало
                        ясно, что они прекрасно дополняют набор персонажей и избавляться от них пока рано.
                    </li>
                    <li className="card-text text-justify"><h6>Айлирэн был Глоссой</h6><br />Поначалу в сюжете
                        вместо <TextInlineLink name={"bs_state_ajliraenian_domination"}
                            text={"Айлирэнской Доминации"} /> фигурировала Доминация глоссиан со
                        столицей на Глоссе. Но название в конечном итоге показалось мне странным. В то время Айлирэн был
                        отдельным государством со своей небольшой линией и предысторией, а также родиной Валькирии Лёкен
                        (читай <TextInlineLink name={"bs_unenclosed"} text={"«Удаленные герои»"} />) Но после
                        коллапса ее сюжетной линии стал отягощать повествование без всякой цели. Пришлось принять
                        экстренные меры.
                    </li>
                    <li className="card-text text-justify"><h6>Сцинтиллианское королевство</h6><br /><p>Часть сюжета
                        затрагивала планету под названием Искра (Сцинтилла с итальянского), на которой Ивар помогал
                        местной королеве подавить народное восстание в обмен на ее содействие в свержении Монарха.
                        Иронично.</p><p>Искрианское или Сцинтиллианское королевство – планета-государство,
                            крошечная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} />, едва
                            обеспечивающая
                            себя едой. На ней нет ничего примечательного, но она расположена посреди <TextInlineLink
                                name={"bs_space_shark_sector"} text={"сектора Акулы"} />, хоть формально и не является
                            территорией <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархии"} />.</p>
                        <p>Сцены с
                            ней были довольно скучными, и после очередного прочтения я их в ужасе удалил.</p></li>
                    <li className="card-text text-justify"><h6>Божья коровка и аламарси</h6><br />Мое любимое насекомое –
                        божья коровка (меня однажды такая укусила – очень странное ощущение). В первой версии сцены
                        на <TextInlineLink name={"bs_planet_scellurae"} text={"Сцеллуре"} /> <TextInlineLink
                            name={"bs_char_browdy_adam"} text={"Адама"} /> цапнула именно божья коровка. Точнее, ее
                        гигантская
                        копия. Это было отражением моей психологической травмы.
                    </li>
                    <li className="card-text text-justify"><h6>Эрлы</h6><br />Губернаторов Монархии изначально звали
                        эрлами. Но это звучало донельзя претенциозно, поэтому я заменил на более простое слово.
                    </li>
                    <li className="card-text text-justify"><h6>Чистый Полдень</h6><br />Именно так
                        называлась <TextInlineLink name={"bs_planet_earth"} text={"Земля"} /> в первой версии книги.
                        Зачем?
                        Вообще не помню. Я вернул ей адекватное имя после того, как прочитал отрывок с упоминанием
                        Полудня своему знакомому, а он спросил «ну а Земля-то у тебя в книге есть?». Правда, взамен я
                        потерял возможность делать такие каламбуры: «…полдень на Полудне – самое страшное время суток…».
                    </li>
                    <li className="card-text text-justify"><h6>Полная версия считалочки</h6><br />Гордые горные грязные
                        гризли грозно грызут гнусную грушу.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export { Book_1_Cydonian_girl };
