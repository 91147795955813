import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";


export function Kato_aemilius() {
    return {
        "is_not_ready": false,
        "name": "Аемилиус Като",
        "fullname": <span>Аемилиус Като</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tamanrasett"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонец"} /></span>,
        "born": <span>122 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>121</span>,
        "work": <span>адмирал 12-го особого агатонского флота «Акветаника»</span>,
        "imgs": [
            { "short": "bs_char_kato_aemilius_1_jpg_500", "big": "bs_char_kato_aemilius_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Аемилиус</span> - уроженец Таманрасетт, что в <TextInlineLink
                        name={"bs_space_ternerian_end"} text={"Тернерианском пределе"} />.
                    Амбициозный офицер агатонского флота, командующий элитным антипиратским соединением - флотом
                    «Акветаника». Под его началом прошло немало успешных операций как в <TextInlineLink
                        name={"bs_space_democratic_space"} text={"Демкосмосе"} />, так и в <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Свободных мирах"} />.
                </p>
                <p className="card-text text-justify">
                    У Като множество знакомых не только в армии <TextInlineLink name={"bs_state_urtm"} text={"ОРМК"} />,
                    но и в <TextInlineLink name={"bs_comp_cms"}
                        text={"СБК"} />. Среди
                    них он слывет в первую
                    очередь хорошим тактиком, который презирает дуболомов и полагается на тонкие, хорошо просчитанные
                    ходы.
                </p>
                <p className="card-text text-justify">
                    Первое появление Аемилиуса произошло в конце <TextInlineLink name={"book_cydonian_girl"}
                        text={"«Кидонианки»"} />: он убедил
                    своего старого знакомого, <TextInlineLink name={"bs_char_xavier_kardenos"}
                        text={"генерала Карденоса"} />, поучаствовать в
                    допросе <TextInlineLink
                        name={"bs_char_esora_eve"} text={"Эсоры"} /> вместе. Как выяснилось
                    позже, Като был единственным офицером в СБК, который поверил Еве - он сам признался об этом в конце
                    романа.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Больше информации
                        будет после выхода следующий частей <TextInlineLink name={"bs_series"}
                            text={"«Кидонианского солнца»"} /></strong>
                    </p>
                </div>
            </>
    }

}