import { Component } from 'react'
import { SearchForm } from "../components/cms/wiki_functions";
import wiki from '../components/cms/wiki.json';

class Search extends Component {

    state = {
        searchQuery: ""
    }

    componentDidMount() {
        document.title = "Поиск || Кидонианское солнце"
    }

    render() {
        return (
            <>
                <div className="row" key="crumbs">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page"><a href={wiki["page_main"].link}>Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Поиск</li>
                        </ol>
                    </nav>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <h1>Поиск по сайту<small>(книги, герои, мир «Кидонианское солнца»)</small></h1>
                        <SearchForm />
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Энциклопедия</a></div>
                            <hr />
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>История мира</a></div>
                            <hr />
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Галерея</a></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export { Search };