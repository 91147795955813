import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vikoveera() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "people": <span>агатонцы</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Виковира</span> – область
                    вокруг <TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Туманности Малой короны"} /> с
                    центром на Агатоне. Основная территория <TextInlineLink name={"bs_state_urtm"}
                        text={"Объединенных республик"} />.</p>
            </>
    }
}

