import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_sunrise_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span>под вопросом</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span>Рассвет</span>,
        "people": <span>земляне</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Сектор Рассвета</span> – территория по
                    другую
                    сторону <TextInlineLink name={"bs_space_sector_zero"} text={"Нулевого сектора"} /> от <TextInlineLink
                        name={"bs_space_the_night_sector"} text={"Ночи"} />. Рассвет
                    очень пострадал от <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактической"} />.
                    Некогда
                    здесь одна на одной гнездились промышленные агломерации из целых звездных систем, но из-за
                    неудачного расположения он попал под раздачу, когда <TextInlineLink name={"bs_people_agathonians"}
                        text={"демократы"} />, <TextInlineLink
                        name={"bs_people_ajliree"} text={"айлири"} /> и <TextInlineLink name={"bs_people_cydonians"}
                            text={"кидонианцы"} /> схлестнулись
                    в
                    лобовую в конце
                    Войны. Линия фронта ползала туда-сюда и сжигала планету за планетой, пока воевать не стало
                    просто не за что.</p><p className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:
                    </span>
                </p>
                <p className="card-text text-justify"><TextInlineLink name={"bs_timeline_the_shark_revolt"}
                    text={"Восстание Пауков"} /> и Рассветного Эшелона
                    привело сектор в полное смятение. Большая часть планет требует независимости, но
                    некоторые планируют образовать единую республику со старыми границами Рассвета. Этот вопрос не будет
                    решен еще много лет.</p>
            </>
    }
}

