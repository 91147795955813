import wiki from './cms/wiki.json';
import imgs from './cms/images_repo.json';
import { WorkInProgress } from "./workInProgress";

export function BooksBlacksunPart1() {
    return (
        <>
            <div className="row row-alt">
                <WorkInProgress />
            </div>
            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_girl"].link}>Кидонианка</a><br />10.03.2021
                    </h5>
                    <a href={wiki["book_cydonian_girl"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Обложка Кидонианки"
                                src={imgs["book_cydonian_girl_200_286_ru"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_hyperbeacons"].link}>Похитители маяков</a><br />09.09.2021
                    </h5>
                    <a href={wiki["book_hyperbeacons"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Обложка Похитителей маяков"
                                src={imgs["book_hyperbeacons_200_286_ru"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_autumn"].link}>Кидонианская осень</a><br />~???
                    </h5>
                    <a href={wiki["book_cydonian_autumn"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Обложка Кидонианской осени"
                                src={imgs["book_cydonian_autumn_200_286_ru"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_bonecollectors"].link}>Собиратели костей</a><br />~???
                    </h5>
                    <a href={wiki["book_bonecollectors"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Обложка Собирателей костей"
                                src={imgs["book_bonecollectors_200_286_ru"].link} />
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}