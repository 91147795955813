import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ardaline_network_planets() {
    return {
        "date": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "type": <span>газопылевая туманность</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонцы"} />, <TextInlineLink
            name={"bs_state_tribalon_republic"} text={"трайбалонцы"} /> (<TextInlineLink name={"bs_spec_humans"}
                text={"Homo sapiens"} />)</span>,
        "population": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "imgs": [{ "short": "bs_wiki_ardaline_network_planets_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Ардалиннская сеть</span>
                    – небольшая туманность между секторами <TextInlineLink name={"bs_space_breeze_sector"}
                        text={"Бриз"} /> и <TextInlineLink
                        name={"bs_space_vikoveera"} text={"Виковира"} />. Точное число планет и космических построек
                    засекречено, как и координаты всех объектов.
                </p>
                <p className="card-text text-justify">Все без исключения миры Сети служат базами подготовки агатонской
                    армии,
                    источниками сырья для научных иститутов или полигонами для испытаний нового оружия. Планет
                    гражданского
                    назначения здесь нет.</p>
            </>
    }
}

