import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Fidget() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span><TextInlineLink name={"bs_ships_ship_classes"} text={"легкая канонерка"} /></span>,
        "type": <>-</>,
        "owned": <span><TextInlineLink name={"bs_comp_cms"} /></span>,
        "captain": <span><TextInlineLink name={"bs_char_esora_eve"} /></span>,
        "size": <>-</>,
        "conflicts": <>-</>,
        "status": <span>утерян на <TextInlineLink name={"bs_planet_michelm"}
            text={"Михъельме"} /> во время <TextInlineLink
                name={"bs_timeline_the_shark_revolt"} text={"Второго демарша Пауков"} /></span>,
        "weapons": <span>2 спаренные <TextInlineLink name={"bs_weaponry_railgun"} text={"рельсовые пушки"} /> 30го калибра, неуправляемые ракеты</span>,
        "defence": <span>броня из <TextInlineLink name={"bs_tech_space_steel"}
            text={"корабельной стали"} /> с 12% <TextInlineLink
                name={"bs_tech_anzuritum"} text={"анзурита"} /></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">«Егоза»</span> – корабль Службы
                    безопасности Короны, служебный транспорт <TextInlineLink name={"bs_char_esora_eve"}
                        text={"Евы Эсоры"} /> до
                    событий <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"} />.</p>
            </>
    }
}
