import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Michelm() {
    return {
        "date": <span>~5600 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, Республика Акулы</span>,
        "habitats": <span>63 млрд. (26 на поверхности)</span>,
        "people": <span>михъельмцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.03 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_planet_michelm"} text={"Михъельма"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"} /></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_michelm_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Михъельм</span> – он же «Ревущий» с <TextInlineLink name={"bs_lang_alamarsie"} text={"аламарси"} />. Открыт землянами еще в
                    досветовую эпоху и почти восемьсот лет развивался в полной изоляции, выживая вопреки статистике,
                    по которой первые земные колонии массово вымирали. Присоединен к новообразованной Великой
                    Монархии на заре световых перелетов и со временем выродился из цветущего мира со своей
                    неповторимой культурой в среднестатистическую, ничем не примечательную людскую метрополию.
                </p>
                <p
                    className='card-text text-justify'>
                    <span className='strong'>Последствия «Кидонианки»:</span>
                </p>
                <p
                    className='card-text text-justify'>
                    После восстания михъельмцев планета стала столицей Республики
                    Акулы и обрела независимость от землян.
                </p>
            </>
    }
}

