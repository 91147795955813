import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_free_worlds() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_samborae"} /> (неофициально), <TextInlineLink
            name={"bs_planet_new_havana"} /> (неофициально), <TextInlineLink
                name={"bs_planet_khal_vernon"} /> (неофициально)</span>,
        "form": "разнится от планеты к планете",
        "currency": <span><TextInlineLink name={"bs_currency_charmo"} text={"кидонианский шарм"} />, <TextInlineLink
            name={"bs_currency_teros"} text={"агатонский терос"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_samborian"} text={"самборианский"} />, <TextInlineLink
            name={"bs_lang_cydonian"} text={"кидонианский"} />, <TextInlineLink name={"bs_lang_earth"} text={"земной"} /></span>,
        "leader": <span>нет</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sectors": <span>1 (но очень большой)</span>,
        "worlds": <span>неизвестно</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} text={"самборианцы"} />, <TextInlineLink
            name={"bs_people_ajliree"} text={"айлири"} />, <TextInlineLink name={"bs_people_cydonians"}
                text={"кидонианцы"} />, <TextInlineLink
                name={"bs_people_earthlings"} text={"земляне"} />, <TextInlineLink name={"bs_people_agathonians"}
                    text={"агатонцы"} /> и др.</span>,
        "imgs": [{ "short": "bs_wiki_the_free_worlds_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Свободные миры</span> –
                    огромная территория в Кластере Бриэнн, которая по сути и ЕСТЬ Кластер Бриэнн. Свободные миры
                    занимают ровно один сектор - Самбора. При этом сектор размером с <TextInlineLink
                        name={"bs_state_cydonian_priority"} text={"Приоритет"} /> и <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Доминацию"} /> вместе
                    взятые. Много раз галактические шишки хотели поделить Cамбору на более маленькие территории, но
                    неизменно встречали сопротивление или даже хуже - полное безразличие со стороны местных.
                </p>
                <p className="card-text text-justify">
                    В Свободных мирах нет единой власти, они не встроены в галактическую политику и не представлены ни
                    в каких крупных международных организациях. Точное население и даже просто количество заселенных
                    миров
                    в этом секторе неизвестно. Известно только одно: нет никакого смысла искать контакт с
                    самборианцами,
                    чтобы сделать их полноправными членами галактики. Они просто этого не хотят.
                </p>
                <p className="card-text text-justify">
                    Местные правительства, которые сильно разнятся от планеты к планете (а иногда и от материка к
                    материку - безумие!), редко признаны кем-то кроме соседей и часто являются полулегальными
                    формированиями,
                    действующими на основании социального договора и традиций больше, чем на основе юридических
                    процедур. Некоторые миры и вовсе контролируются <TextInlineLink name={"bs_comp_free_trade_league"}
                        text={"Лигой свободной торговли"} /> -
                    местным аналогом Якудза,
                    у которого в подчинении миллионы пиратов и неисчислимое количество контрабандистов по всей
                    галактике. Эта организация и множество подобных ей промышляют всем, что запрещено в цивилизованной
                    галактике: торговля оружием, украденными реликвиями, запрещенными веществами, иногда даже людьми. В
                    мирах Лиги процветают
                    сомнительные медицинские экспериементы, разработка биологического оружия и другие мерзкие вещи.
                </p>
                <p className="card-text text-justify">
                    Форма правления тоже сильно отличается от государства к государству, от чего в одной системе
                    может быть
                    одновременно президент, король и гендиректор.
                </p>
                <p className="card-text text-justify">
                    Внешний хаос на поверку оказывается вполне контролируемым и местами даже более упорядоченным, чем
                    в остальной галактике. Большая часть миров живет не менее сыто и тепло, чем Кидония или Агатон и
                    зачастую их население занято вполне легальными производствами. Проблема лишь в том, что сбывать
                    свою продукцию почти некуда из-за практически повсеместного эмбарго на торговлю с самборианцами.
                    Поэтому большая часть миров обеспечивает едой и одеждой организации подобные Лиге и зачастую имеет
                    армию, слабо отличимую по внешнему виду и роду занятий от пиратской банды.
                </p>
                <blockquote>
                    <p className="card-text text-justify text-italic">
                        «Если говорить о законах и правах граждан, то, разумеется, их количество и даже само наличие
                        сильно
                        разнятся.
                        Сложно выделить четкую закономерность, но можно с уверенностью сказать, что даже в
                        самых
                        диких самборианских уголках царит как минимум одно правило: не трогай других, если не хочешь,
                        чтобы
                        тронули тебя. Оно прекрасно работает за пределами таких метрополий как Новая Гавана и
                        Хал-Вернон,
                        где
                        есть все атрибуты государственности. Поэтому даже если вы не знаете, какие ограничения действуют
                        в
                        том или ином мире - просто не лезьте куда не просят. И все будет хорошо... а, и не светите
                        лишний
                        раз оружием - кто-то может занервничать и выстрелить первым»
                    </p>

                    <small>
                        <TextInlineLink name={"bs_char_ursula_adams"} />,<TextInlineLink
                            name={"bs_universe_bird_of_passage_guide_to_milky_way"} />
                    </small>
                </blockquote>

                <h5>Юлиан Харари о Свободных мирах в «Homo sapiens: правдивая история выходцев со Старой Земли», 265 г.
                    ПТ</h5>
                <p className="card-text text-justify">
                    «Говоря о Свободных мирах, стоит начать с рассказа о том, как же
                    они на самом деле появились. Это не
                    история о пиратах, бросивших вызов устоявшимся порядкам и не о смельчаках-аламарси, что скрылись от
                    взора планетников на другом конце галактики. Речь о сотрудничестве. И тяге к свободе.
                    Хотя все и правда началось с аламарси.
                </p>
                <p className="card-text text-justify">
                    Примерно 5000 лет назад (5196 ДТ. - прим. редактора) кочевой народ открыл для себя неизведанные
                    просторы
                    вокруг планеты Самбора. Это была самая далекая часть галактики, в которую на тот момент забирался
                    человек. Там аламарси, пользуясь первыми примитивными световыми машинами, изучили окрестности на
                    много
                    парсеков вокруг. Они построили сеть навигационных маяков, сделали множество роботизированных станций
                    неотложной помощи и заселили орбиты нескольких особо примечательных миров. Ни один из них на тот
                    момент
                    не поддерживал жизнь.
                </p>
                <p className="card-text text-justify">
                    Чуть меньше, чем через век, туда добрались земные автоматы-терраформеры и принялись превращать
                    Самбору и
                    соседние миры в подобие Матушки. Шли столетия, аламарси активно пользовались свалившимися на них
                    благами
                    терраформации и были уверены, что планетники никогда не доберутся туда во плоти.
                </p>
                <p className="card-text text-justify">
                    Это была ошибка. Около 4100 лет назад (4097 ДТ. - прим. редактора) к Самборе приблизился огромный
                    колонизационный флот. Жители Старого космоса, утомленные войнами Тристана и бесконечными
                    междоусобицами
                    правителей-землян, нашли записи об отправке терраформеров в эту часть космоса и решили посмотреть,
                    что
                    вышло.
                </p>
                <p className="card-text text-justify">
                    Они заявили права на эти миры и аламарси, понимая, что более продвинутые и тяжело вооруженные судна
                    пришельцев представляют серьезную опасность, согласились разделить с ними планету. В конце концов
                    кочевники ведь живут в космосе – им поверхность нужна лишь как источник ресурсов.
                </p>
                <p className="card-text text-justify">
                    Новоприбывшие поделились с аламарси современными технологиями, взамен получив карты окружающего
                    космоса.
                    С этого момента началось создание Свободных миров Самборы, конфедерации независимых планет, которые
                    стремились лишь к одному – чтобы от них отстали.
                </p>
                <p className="card-text text-justify">
                    Еще не существовало <TextInlineLink name={"bs_planet_agathon"} text={"Агатона"} />, а <TextInlineLink
                        name={"bs_planet_cydonia"} /> и <TextInlineLink name={"bs_planet_ajliraen"} /> были не более чем
                    мелкими, мало кому известными
                    планетками где-то на периферии известной вселенной. Но свободолюбивые самборианцы уже поднимали
                    голову и
                    покоряли галактику, образовав причудливый альянс планетников и кочевников…»
                </p>
            </>
    }
}

