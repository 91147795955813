import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Machtobyrat() {

    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>-</span>,
        "people": <span>аламарси (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>61</span>,
        "system": <span>Наяды</span>,
        "sector": <span><TextInlineLink name={"bs_space_fiorella_sector"} text={"Флёр"} /></span>,
        "type": <span>газовый гигант</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [{ "short": "bs_wiki_machtobyrat_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Махтубират</span> – богатый гелием и
                    водородом газовый гигант. По договору с <TextInlineLink name={"bs_comp_priumviratus"}
                        text={"кидонианским правительством"} />,
                    несколько ульев <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"} /> добывают на нем и
                    его спутниках
                    полезные ресурсы. Уже этот факт удивляет: обычно кочевники не спрашивают разрешения, а все, что
                    требуется, собирают в ничейных мирах. Более того: здесь уже тридцать лет висят автоматические
                    наблюдательные станции аламарси, которые непрерывно сканируют космос в поисках чего-то.</p>
            </>
    }
}

