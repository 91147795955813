import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Biome() {
    return {
        "top": "",
        "text":
            <>

                <h4 className="title-bg">«Биосферы землеподобных миров», Ччеле Солеун, 12
                    год <TextInlineLink
                        name={"bs_timeline"} text={"ДТ"} /></h4>
                <p className="card-text text-justify">
                    <span className="strong">Биом</span> – биосистема из нескольких связанных экосистем. У каждой
                    планеты свой биом и даже если существа
                    из разных миров похожи, это не значит, что они имеют одинаковое строение.
                </p>
                <p className="card-text text-justify">
                    Большая часть биомов на терраформированных планетах <span
                        className="strong">совместима с земным</span>,
                    поскольку до терраформации эти миры не поддерживали жизнь вообще. Именно вмешательство людей сделало
                    такие планеты пригодными для органических существ и это вмешательство состоит из подселения
                    модифицированных земных видов и создания условий для их дальнейшего размножения. Таким образом
                    безжизненный каменный шарик через несколько веков становится похож на <TextInlineLink
                        name={"bs_planet_earth"} text={"Землю"} />, хотя и с небольшими
                    отличиями из-за мутаций и влияния уникальных условий местной среды.
                </p>
                <p className="card-text text-justify">
                    Существуют совместимые с земным биомы, которые стали таковыми в ходе естественной конвергентной
                    эволюции. Например, кидонианская биосфера очень напоминает земную и во многих
                    регионах <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"} /> даже
                    можно вырастить земные растения. Ее атмосфера практически на 100% соответствует прародине
                    человечества, хотя климат заметно отличается из-за более низких средних температур
                    и отсутствия смены времен года. Таким же был биом <TextInlineLink name={"bs_planet_the_night"}
                        text={"Угольной Ньёрун"} /> до
                    того, как она стала угольной.
                </p>
                <p className="card-text text-justify">
                    Однако большинство миров, имеющих родную биосферу, частично или совсем <span className="strong">не совместимы</span> с
                    привычными
                    нам видами. Например, <TextInlineLink name={"bs_planet_scellurae"} /> в секторе <TextInlineLink
                        name={"bs_space_shark_sector"} text={"Акулы"} />. Когда люди открыли ее, это была цветущая
                    планета-сад
                    с уникальными условиями и экологическим балансом. Но там было совершенно невозможно находиться
                    без защиты: дожди, растения и даже сам воздух были насыщены едкими соединениями - всего час на
                    поверхности без скафандра наносил непоправимые увечья. Поэтому люди постепенно, в несколько долгих
                    этапов, изменили все, что делало Сцеллуру уникальной: атмосферу, состав почвы, воды и геном почти
                    всех видов. Теперь ее биом совместим с земным и там выращивают абсолютно безопасные для нас
                    плодовые деревья. Стоила ли такая катастрофа того? Каждый решает для себя сам.
                </p>
            </>
    }
}











