import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Samborian_traverse() {
    return {
        "date": <span>-</span>,
        "type": <span>территория</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"} /></span>,
        "people": <span>кидонианцы, самборианцы (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_samborian_traverse_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p class="card-text text-justify"><span class="strong">Самборианский траверс</span>
                    – тонкий слой звездных систем на границе кидонианской территории и сектора Самбора. Часто
                    подвергается
                    нашествиям пиратов и считается отличным местом для боевого крещения кидонианских новобранцев. Был
                    отвоеван у
                    синдиката Пернатый змей, который единолично владел огромными пространствами и использовал миры
                    Траверса в
                    качестве источника полезных ископаемых для поддержки флота.
                </p>
            </>
    }
}

