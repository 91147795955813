import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function TheWhip() {
    return {

        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />, <TextInlineLink
            name={"book_hyperbeacons"} text={"«Похитители маяков»"} /></>,
        "name": "Бич",
        "fullname": <span>Бич <small>«Последний из перворожденных», «Тот-самый»</small></span>,
        "born": <span>???</span>,
        "age": ">6000",
        "homeworld": <span>???</span>,
        "species": <span><TextInlineLink name={"bs_spec_firstborns"} text={"перворожденные"} /> (?)</span>,
        "daughter": <TextInlineLink name={"bs_char_de_levine_rosalie"} />,
        "img_trans": true,
        "imgs": [
            { "short": "bs_char_the_whip_1_png_500", "big": "bs_char_the_whip_1_jpg_full" },
            { "short": "bs_char_the_whip_4_jpg_200", "big": "bs_char_the_whip_4_jpg_full" },
            { "short": "bs_char_the_whip_2_jpg_200", "big": "bs_char_the_whip_2_jpg_full" },
            { "short": "bs_char_the_whip_3_jpg_200", "big": "bs_char_the_whip_3_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <p>(спаситель утопающих)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                    <p>(лесной нудист)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Кратко о герое</h3>
                        <div className="timeline-item pb-0">
                            <p className={"text-justify"}><em>
                                <span className="strong">Бич</span>, он же <span
                                    className="strong">Хлыст</span>, он же <span className="strong">Кнут</span> – существо
                                неизвестной природы пришедшее из далеких глубин
                                космоса с неясной целью. Выглядит как юный человек мужского пола. Обладает тягой к
                                опасным приключением и настолько продвинутыми технологиями, что они больше похожи на
                                магию. Несмотря на внешне безобидный облик, у Бича сверхчеловеческие способности:
                                он может ломать крепления из корабельной стали как оригами и выживать даже после
                                попадания
                                из рельсотрона. Имя он получил именно за подобные трюки, а вовсе не за то,
                                о чем можно подумать.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>
                                Много веков Бич жил среди людей, мимикрируя под них, и даже
                                обзавелся совместным потомством. Свою тайну он решил выдать лишь нескольким избранным,
                                кто мог помочь ему в поисках <TextInlineLink name={"bs_tech_the_key"}
                                    text={"Ключа"} /> от <TextInlineLink
                                    name={"bs_weaponry_the_black_sun"} text={"Черного солнца"} />.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>Дружил с <TextInlineLink name={"bs_people_alamarsie"}
                                text={"аламарси"} />, когда это
                                еще не было
                                мейнстримом.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>
                                Герой <TextInlineLink name={"book_cydonian_girl"} text={"Кидонианки"} />, <TextInlineLink
                                    name={"book_hyperbeacons"} text={"Похитителей маяков"} />, <TextInlineLink
                                    name={"book_cydonian_autumn"} text={"Кидонианской осени"} />, <TextInlineLink
                                    name={"book_bonecollectors"} text={"Собирателей костей"} /> и
                                нескольких последующих романов.
                            </em>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроника</h3>
                        <div className="timeline-item">
                            <h5>12 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"} /> (<TextInlineLink
                                name={"book_hyperbeacons"} />)</h5>
                            <p><em><TextInlineLink name={"bs_planet_tajbennae"} /></em></p>
                            <ul>
                                <li>Отобрал у <TextInlineLink name={"bs_spec_vitruvianus"} text={"витрувианов"} /> <
                                    TextInlineLink name={"bs_tech_northern_star_of_niradanhae"}
                                    text={"Северную звезду Нираданхи"} /> на Тайбенне и затем передал
                                    маяк существу по имени Артур, чью истинную природу не смог понять до конца
                                </li>
                                <li>Спасение <TextInlineLink name={"bs_char_skarsgard_oliver"}
                                    text={"Оливера Скошгарда"} /> на Тайбенне
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>15 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"} /> (<TextInlineLink
                                name={"book_cydonian_girl"} />)</h5>
                            <p><em><TextInlineLink name={"bs_planet_earth"} /></em></p>
                            <ul>
                                <li>Знакомство с младшей из своих дочерей, Розали, случайно встреченной во дворце
                                    свергнутого лидера землян
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>Дата неизвестна</h5>
                            <p><em>Место неизвестно</em></p>
                            <ul>
                                <li>Попытался отыскать <TextInlineLink name={"bs_tech_the_key"} text={"Ключ"} />, но
                                    понял,
                                    что устройство потеряно <TextInlineLink name={"bs_people_alamarsie"}
                                        text={"аламарси"} /> много веков назад
                                </li>
                                <li>Выяснил, что <TextInlineLink name={"bs_spec_vitruvianus"}
                                    text={"витрувианы"} /> планируют вторжение
                                    в галактику и, скорее всего, полное уничтожение человечества
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>~2800 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p><em>Где-то на просторах <TextInlineLink name={"bs_maps_oecumene"}
                                text={"Млечного Пути"} /></em></p>
                            <ul>
                                <li>Бич встречает аламарси и передает им <TextInlineLink name={"bs_tech_the_key"}
                                    text={"Ключ"} />, устройство,
                                    которое открывает доступ к <TextInlineLink name={"bs_weaponry_the_black_sun"}
                                        text={"Черному солнцу"} /></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>~6000 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></h5>
                            <p><em>В неизвестной части Вселенной</em></p>
                            <ul>
                                <li>Бич захватывает <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Солнце"} />,
                                    но в конечном итоге терпит поражение и удаляется прочь из разрушенного мира
                                </li>
                                <li><TextInlineLink name={"bs_char_shimmering_devil"} /> уничтожает родной мир Бича с
                                    помощью <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Черного солнца"} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    Точный возраст Бича неизвестен, но он родился как минимум за 6 тысяч лет до <TextInlineLink
                        name={"bs_timeline"} text={"Таманрасетт"} />.
                    Через неизвестное количество времени его цивилизация была уничтожена существом по
                    имени <TextInlineLink
                        name={"bs_char_shimmering_devil"} />,
                    который применил для этого <TextInlineLink name={"bs_weaponry_the_black_sun"} />. Бич сумел
                    заточить Солнце в сингулярность и закрыть ее неким <TextInlineLink name={"bs_tech_the_key"}
                        text={"Ключом"} />, но битву
                    с Дьяволом проиграл.
                </p>
                <p className="card-text text-justify">
                    Затем он провел много веков в нескончаемых странствиях и, судя по всему, оставил немало
                    следов по всей обозримой Вселенной. Чем он занимался и что искал - неясно. Однако точно
                    известно, что около 2800 до Таманрасетт Бич прибыл в Млечный Путь и вступил в контакт
                    с людьми. Он передал аламарси Ключ, так как это устройство, по его словам, обладает
                    волей и способностью сводить живых существ с ума. Бич полагал, что люди не успеют поддаться
                    его влиянию, так как живут слишком мало и просто не успеют потерять рассудок - влияние
                    Ключа крайне слабое и проявляется только спустя много десятилетий.
                </p>
                <p className="card-text text-justify">
                    Решив проблему хранения столь опасного устройства, Бич нажил другую: люди потеряли его.
                    Неизвестно как и когда, но аламарси попросту либо позабыли об истинной ценности устройства, либо
                    корабль, перевозивший его, подвергся пиратскому налету. След Ключа потерялся несколько
                    веков назад.
                </p>
                <p className="card-text text-justify">
                    Когда настало время использовать Ключ, чтобы открыть сингулярность и выпустить Солнце для
                    уничтожения витрувианов, Бич не смог его обнаружить. Ему потребовались годы, чтобы разобраться
                    и хотя бы немного сузить круг поиска. На момент <TextInlineLink
                        name={"book_cydonian_girl"} text={"Кидонианки"} />,
                    Ключ не был найден, а сами витрувианы выяснили, что Бич его потерял и тоже открыли охоту
                    за устройством.
                </p>
                <p className="card-text text-justify">
                    В <TextInlineLink name={"book_cydonian_girl"} text={"Кидонианке"} /> Бич впервые встретил
                    младшую из своих дочерей, <TextInlineLink name={"bs_char_de_levine_rosalie"} />, которой на тот
                    момент было всего 25. Общение не заладилось и времени на воссоединение дочери и отца, к сожалению,
                    не было.
                </p>
                <p className="card-text text-justify">
                    В <TextInlineLink name={"book_cydonian_girl"} text={"Похитителях маяков"} /> Бич спас <TextInlineLink
                        name={"bs_char_skarsgard_oliver"} text={"Оливера Скошгарда"} /> от
                    превосходящих сил витрувианов и предупредил о надвигающейся угрозе пришельцев. Там же,
                    на <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбенне"} />,
                    он обнаружил <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"}
                        text={"Северную звезду Нираданхи"} />, хотя и не совсем
                    понимал природу этого устройства. Он рассчитывал, что маяк поможет ему обойти сингулярность и
                    освободить
                    Солнце без Ключа, но исследования показали, что это невозможно.
                </p>
                <p className="card-text text-justify">
                    Поэтому он отдал маяк Артуру, загадочному существу, которое тоже мимикрировало под Homo sapiens и
                    умело создавать червоточины для путешений в пространстве.
                    Взамен Артур пообещал, что поможет найти и уничтожить <TextInlineLink
                        name={"bs_char_shimmering_devil"} text={"Мерцающего дьявола"} />,
                    который оказался их общим врагом.
                </p>
            </>
    }
}
