import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function BriglioniLeushoffer47() {

    return {
        "date": <span>64 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "governor": <span>-</span>,
        "habitats": <span>122 тыс.</span>,
        "people": <span><span>кидонианцы (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span></span>,
        "moons": <span>4</span>,
        "g": <span>1.03 земной</span>,
        "atmosphere": <span className="text-danger">крайне опасна</span>,
        "biosphere": <span>не существует</span>,
        "system": <span>Брильони-Леусхоффер-4</span>,
        "sector": <span><TextInlineLink name={"bs_space_millie_sector"} text={"Милли"} /></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"шахтерский мир"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [
            { "short": "bs_planet_briglioni_leushoffer_4_7_map_ru", "big": "bs_wiki_oecumene_map_ru" }
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <h3 className="title-bg">О мире</h3>
                    <p className='card-text text-justify'>
                        <span className='strong'>Брильони-Леусхоффер-4-7</span> – планета-рудник во
                        владении <TextInlineLink name={"bs_comp_briglioni_leushoffer"}
                            text={"одноименного концерна"} /> в
                        кидонианской части <TextPopup
                            name={"bs_popups_perseus_arm"} text={"рукава Персея"} />.
                    </p>
                    <p className='card-text text-justify'>
                        Мир всегда был безжизненным, но спокойным: ни землетрясений, ни извержений вулканов. Когда
                        концерн купил
                        его, то озаботился лишь атмосферой: создал искусственную газовую оболочку, чтобы рабочие могли
                        ходить без защитных костюмов и не стал проводить дополнительную геологоразведку. Последнее
                        оказалось фатальным.
                    </p>
                    <p className='card-text text-justify'>
                        В 21 году ДТ после четырех десятилетий разработок, выяснилось, что под поверхностью

                        дремают <TextPopup text={"стратовулканы"}
                            popup={"Стратовулкан - тип вулкана, который вместо обычного извержения может легко жахнуть как бомба"} />,
                        размер которых превышает любые ожидания. Новость об этом долетела до <TextInlineLink
                            name={"bs_science_planet_engineering"} text={"планетарных инженеров"} /> концерна
                        слишком поздно: рабочие, следуя плану добычи, сделали георазрыв в одном из
                        опаснейших мест планеты. Это нарушило геологический баланс, который и так пострадал вследствие
                        людской деятельности. Планета начала быстро меняться: несколько спящих вулканов взорвались
                        почти сразу после георазрыва и запустили цепную реакцию, которая в считанные дни разрушила
                        большую часть инфраструктуры. Тысячи людей погибли во время извержений, еще больше пострадали,
                        многие пропали без вести.
                    </p>
                    <p className='card-text text-justify'>
                        Планету накрыли потоки лавы, трещины в почве, из которых струился едкий газ или гейзеры кипящей
                        воды.
                        Атмосферу затянуло пеплом и дымом. Процессоры, очищавшие воздух для рабочих, вышли из строя
                        всего через несколько дней, поэтому все, кого не успели эвакуировать за это время, погибли от
                        удушья.
                    </p>
                    <p className='card-text text-justify'>

                    </p>
                    <blockquote>
                        <p className="card-text text-justify">
                            Когда мы высадились, первое, что я услышал - это жуткий треск. Мне казалось, что сама
                            планета
                            раскалывается. Так и было. Позже нам сказали, что так звучат крошащиеся литосферные плиты -
                            мир ломался прямо под нашими ногами. Кругом был черный смог, пепел, с неба падали камни, от
                            некоторых не спасали даже доспехи - за два часа я потерял трех человек.
                            Горизонт пылал красным огнем, потоки лавы заливали долины, получались целые горящие моря.
                            Никогда не забуду этой картины.
                        </p>
                        <small>Из воспоминаний капитана спасательного отряда, первым прибывшего на место</small>
                    </blockquote>
                </div>
            </>
    }
}
