import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cassini() {
    return {
        "date": <span>2211 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>55 млрд. (12 на поверхности)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>1</span>,
        "mother": <span></span>,
        "g": <span>1.09 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Кассини</span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грация"} /></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "imgs": [{ "short": "bs_wiki_cassini_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Кассини</span> – столица сектора
                    Грация, окраинной провинции Приоритета. Названа в честь древнего земного ученого-астронома.</p>
            </>
    }
}

