import imgs from '../../../../../components/cms/images_repo.json';

import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Esquirete() {
    return {
        "top": <div className="row">
            <ul>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Государство: </strong> <span><TextInlineLink
                        name={"bs_state_cydonian_priority"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Главнокомандующий: </strong>
                    <span>Её Величество <TextInlineLink name={"bs_char_van_gloria_adel"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Численность: </strong> <span>неизвестна</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Руководитель научных проектов (научное
                        крыло): </strong> <span>эсквайр <TextInlineLink name={"bs_char_colbert_simone"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Руководитель оборонных проектов (ударное
                        крыло): </strong> <span>эсквайр <TextInlineLink name={"bs_char_skarsgard_oliver"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Научный и операционный штаб:</strong>
                    <span>система <TextInlineLink name={"bs_star_mirage"} />, «Объект №33 «Паллада» под управлением инженерно-исследовательского корпуса Её Величества», он же 3-й НИИ Минобороны</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Центр подготовки:</strong>
                    <span><TextInlineLink name={"bs_star_hela_gemini"} />, «Объект №79 «Халкида», расположен на территории Хеллесианской академии торгового и военного флота имени <TextInlineLink
                        name={"bs_char_brienne_de_estrada"} text={"Бриэнны де Эстрада"} /></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Дислокация тактического резерва: </strong>
                    <span><TextInlineLink name={"bs_space_free_fire_zone"} />, система <TextInlineLink
                        name={"bs_star_schiaparelli"} />, она же «Объект №111 «Полигон»<br /><span
                            className="badge bg-danger">РЕЖИМНАЯ ТЕРРИТОРИЯ – ОГОНЬ БЕЗ ПРЕДУПРЕЖДЕНИЯ</span></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Центр операций Экспедиционного корпуса: </strong>
                    <span><TextInlineLink name={"bs_space_samborian_traverse"} />, туманность <TextInlineLink
                        name={"bs_space_castanaro_ledge"} />, система <TextInlineLink
                            name={"bs_star_mu_holothuria"} />, она же «Самборианские ворота» <br /><span
                                className="badge bg-danger">РЕЖИМНАЯ ТЕРРИТОРИЯ – ОГОНЬ БЕЗ ПРЕДУПРЕЖДЕНИЯ</span></span>
                </li>
            </ul>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Из «Агатонской армейской энциклопедии»</h3>
                <img style={{ "maxWidth": "300px", "float": "left" }} alt="Эмблема Эсквариата"
                    src={imgs["bs_creature_kalmeria"].link}
                    fetchpriority="low" />
                <p className="card-text text-justify">
                    <span className="strong">Эсквариат Ее Величества</span> – военное соединение, подчиненное напрямую
                    монарху Приоритета. Герб – скалящаяся кальмерия, небольшой эндемичный для Кидонии хищник. Этот зверь,
                    внешне безобидный, известен свирепостью и крайней прожорливостью.
                </p>
                <p className="card-text text-justify">
                    В Эсквариат набирают лучших офицеров и солдат: как отличившихся в бою, так и показавших разного рода
                    выдающиеся способности. После повышения к званию солдата и младшего офицера добавляется приставка
                    "эсквайр", а звание высшего офицера полностью заменяется на этот термин. Таким образом, лейтенант
                    становится эсквайр-лейтенантом, а майор, поковник или даже адмирал – просто эсквайром.
                </p>
                <p className="card-text text-justify">
                    Младший эсквайр считается старшим относительно военнослужащего или полицейского в том же звании без
                    приставки "эсквайр-", а старший эсквайр старше по званию любого другого военнослужащего Приоритета,
                    вне зависимости от должности.
                    Внутренняя субординация старших эсквайров известна только членам Эсквариата и как правило не
                    разглашается.
                </p>
                <p className="card-text text-justify">
                    Старшие эсквайры имеют право брать под контроль любое военное подразделение страны и обладают очень
                    широкими
                    полномочиями в управлении войсками. Как правило они пользуются этим правом во время военных действий
                    или
                    при защите отдаленных колоний от пиратов. Отсутствие бюрократических преград, в совокупности
                    с опытом и выучкой Эсквариата, делают его невероятно гибким и эффективным механизмом.
                </p>
                <p className="card-text text-justify">
                    Кроме этого, у Эсквариата есть свой отдельный военный флот и тактический резерв для экстренных
                    ситуаций.
                    Он используется для специальных операций внутри и за пределами страны: как правило против
                    баронов <TextInlineLink name={"bs_state_free_worlds"} text={"Самборы"} /> или
                    синдиката <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатый змей"} />. Размеры флота
                    засекречены, но достоверно известно,
                    что он состоит из нескольких соединений, среди которых 77-й погранфлот «Веллингтон» (участвовавший
                    в <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Тайбеннском контакте"} />), 1-й
                    отдельный Хеллесианский флот «Стражи Хель»,
                    печально известный по <TextInlineLink name={"bs_planet_tiora"} text={"Тиорийскому инциденту"} />, и
                    588-й космоштурмовой полк «Гётеборгские ведьмы», они же Астероидное крыло ССО Приоритета.
                </p>
                <p className="card-text text-justify">
                    В открытых источниках больше всего информации можно найти именно про «Ведьм». Их специализация –
                    штурм укрепленных баз в астероидных поясах и на небольших планетоидах, как раз таких, которые часто
                    используются пиратами. Подразделение известно в военных кругах галактики в первую очередь умением
                    незаметно перемещать авианосцы по Самборе,
                    не попадаясь в поле зрения ни одного сканера, и уничтожать целые пиратские крепости, не прибегая к
                    тяжелой огневой поддержке. Несмотря на напряжение между Кидонией и Агатоном, Эсквариат всегда был в
                    теплых отношениях с <TextInlineLink name={"bs_comp_cms"} text={"СБК"} />.
                    Они неоднократно прикрывали <TextInlineLink name={"bs_comp_cms"} text={"агатонских коммандос"} />,
                    в том числе <TextInlineLink name={"bs_char_esora_eve"} text={"Еву Эсору"} />, во время операций в
                    густонаселенных регионах <TextInlineLink name={"bs_space_samborae_sector"}
                        text={"Свободных миров"} /> (область
                    метрополий <TextInlineLink name={"bs_planet_new_havana"} text={"Новая Гавана"} />, <TextInlineLink
                        name={"bs_planet_samborae"} text={"Хнаасор"} /> и [ДАННЫЕ УДАЛЕНЫ]).
                </p>
                <blockquote><p className="card-text text-justify">Также хочу отметить исключительные профессионализм и
                    героизм наших кидонианских коллег из 588-го полка Эсквариата. Их отвага и непревзойденный уровень
                    пилотирования спасли жизни лейтенанту Эсоре и личному составу велиморского гарнизона.</p><small>Из
                        отчетов СБК канцлеру Киндрейсу</small></blockquote>
                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <p className="card-text text-justify">
                    Щедрое финансирование также позволяет Эсквариату вести научные исследования, не всегда сопряженные
                    с обороной страны. Например, именно в недрах этой организации были разработаны многие технологии,
                    которые сейчас защищают <TextInlineLink name={"bs_tech_grn_2"} text={"маяки гиперсвязи"} /> от
                    несанкционированного доступа. Самоуничтожение с последующим ядерным взрывом
                    и выбросом антиматерии – это идея эсквайров. Со временем подобная свобода отразилась и на принципах
                    вербовки офицеров: текущий научный руководитель Эсквариата, <TextInlineLink
                        name={"bs_char_colbert_simone"} text={"Симона Колберт"} />, никогда не служила в армии и начала свою
                    карьеру в оборонной сфере именно с Эсквариата.
                    Ее, как и многих других талантливых молодых людей, завербовали из-за познаний в научной сфере, а
                    обучение военному делу прошло после поступления на службу. Для таких целей в <TextInlineLink
                        name={"bs_state_cydonian_priority_science"}
                        text={"Хеллесианской академии торгового и военного флота"} />, элитном учебном завединии Приоритета,
                    есть отдельный учебный центр «Халкида».
                </p>
                <p className="card-text text-justify">
                    Разумеется, подобное положение дел приводит ко внутренней дискриминации, когда боевые офицеры
                    саботируют приказы эсквайров научного крыла.
                </p>
                <p className="card-text text-justify">
                    Самая важная особенность Эсквариата – право проводить военные операции любого масштаба без
                    одобрения <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
                        text={"Приумвирата"} /> (парламента Приоритета).
                    При этом место и цель операций не регламентируются и зависят от приказов монарха. Военное положение
                    или другие бюрократические
                    процедуры Эсквариату для таких операций не требуются. Единственное условие: Парламентская комиссия
                    оборонных операций
                    должна быть уведомлена обо всех применениях Эсквариатом боевого оружия, даже для самозащиты.
                </p>
                <p className="card-text text-justify">
                    Подобные широкие полномочия стали причиной множества разногласий между монархами и парламентом.
                    Комиссия неоднократно вводила санкции
                    против отдельных офицеров, а однажды даже инициировала импичмент монарха (королевы Иветты Шеридан,
                    за 19 лет <TextInlineLink name={"bs_timeline_light_travel_times"} text={"до Таманрасетт"} />).
                </p>

                <h6>Выдержки из исторического справочника «Галактика до Таманрасетт» за авторством Семиона Даластара,
                    422 г. <TextInlineLink name={"bs_timeline_light_travel_times"} text={"после Таманрасетт"} />:</h6>
                <blockquote><p className="card-text text-justify">...таким образом, именно Эсквариат взял на себя все
                    тяготы подготовки к борьбе с витрувианами
                    и в тяжелых боях собрал разведданные, которые спасли немало жизней после Таманрасетт...</p><small>Глава
                        2: Затишье перед бурей</small></blockquote>
                <blockquote><p className="card-text text-justify">...Адель ван Глория не доверяла военным чинам
                    более низкого ранга и большую часть информации о готовящемся вторжении держала внутри узких кругов
                    Эсквариата.
                    Можно по-разному относиться к столь спорному решению: у королевы, безусловно, было больше опыта в
                    общении с этими людьми, чем у нас, ее потомков.
                    Но я все же считаю, что это была катастрофическая ошибка.</p><small>Глава 3: Предвестники</small>
                </blockquote>

            </>
    }
}
