import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Naskarae() {
    return {
        "date": <span>-</span>,
        "owned": <span>под вопросом</span>,
        "habitats": <span>0</span>,
        "people": <span>-</span>,
        "moons": <span>-</span>,
        "g": <span>0.32 земной</span>,
        "atmosphere": <span>нет</span>,
        "biosphere": <span>нет</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Рассвета"} /></span>,
        "type": <span>поле астероидов</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_naskarae_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Наскара</span> – бывший
                    стратегический мир в секторе Рассвета. Уничтожен во время <TextInlineLink
                        name={"bs_timeline_the_shark_revolt"} text={"Восстания Акулы"} />: по неизвестной
                    причине сдетонировал весь антиматериальный арсенал, размещенный военным флотом землян.
                </p>
            </>
    }
}

