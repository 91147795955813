import { CrumbsBlackSunGallery } from "../../../../components/Bread_Crumbs";
import { GalleryCard } from "../Gallery";
import wiki from "../../../../components/cms/wiki.json";


export function Gallery_space() {
    document.title = "Галерея Кидонианское солнца"
    return (<>
        <div className="row" key="crumbs">
            <CrumbsBlackSunGallery name={"Галерея космических объектов"} />
        </div>
        <h3 className="title-bg">Космос Кидонианского солнца</h3>
        <div className="row">
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"tiara_minor_500"} big={"tiara_minor"} link={wiki["bs_space_tiara_minor_nebulae"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_space_tiara_minor_nebulae"].link}>Туманность Малой короны</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"tiara_minor_2_500"} big={"tiara_minor_2"} link={wiki["bs_space_tiara_minor_nebulae"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_space_tiara_minor_nebulae"].link}>Туманность Малой короны (2)</a>
                    </h5>} />
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <GalleryCard small={"ackron_nebulae"} big={"ackron_nebulae"} link={wiki["bs_space_ackron_nebulae"].link}
                    text={<h5 className="ordinary"><a href={wiki["bs_space_ackron_nebulae"].link}>Акронская небула</a>
                    </h5>} />
            </div>
        </div>
    </>)
}
