import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tallesian_principality() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_talla"} /></span>,
        "form": <span>конституционная монархия</span>,
        "currency": <span>земной империум, кидонианский шарм, агатонский терос</span>,
        "langs": <span>таллесианский, <TextInlineLink name={"bs_lang_earth"} text={"земной"} /></span>,
        "leader": <span>князь</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sectors": <span>{"<1"}</span>,
        "worlds": <span>60 (13 метрополий)</span>,
        "people": <span>земляне, таллесианцы</span>,
        "imgs": [{ "short": "bs_wiki_tallesian_principality_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Княжество Таллесианское</span> –
                    средних размеров государство в Старом космосе, у границы <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархии"} /> и <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Айлирэнской Доминации"} />.</p>
            </>
    }
}

