import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Aethurian_republic() {
    return {
        "capital": <span><TextPopup
            name={"bs_popups_planet_aethuria"} /></span>,
        "form": <span>президентская республика</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонский терос"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"земной"} /></span>,
        "leader": <span>президент</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sectors": <span>1</span>,
        "worlds": <span>23 (3 метрополии)</span>,
        "people": <span><TextInlineLink name={"bs_people_aethurians"} text={"этурийцы"} />, <TextInlineLink
            name={"bs_people_agathonians"} text={"агатонцы"} />, <TextInlineLink name={"bs_people_earthlings"}
                text={"земляне"} /></span>,
        "imgs": [{ "short": "bs_wiki_aethurian_republic_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify">
                    <span className="strong">Этурийская республика</span> – государство
                    на краю Демократического космоса со столицей на Этурии. Граничит с <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"бывшими земными территориями"} /> и <TextInlineLink
                        name={"bs_state_urtm"}
                        text={"Объединенными республиками"} />.
                    Через Этурию проходят многие торговые
                    пути, потому что она идеально расположилась в самом центре населенной галактики. Путь
                    от <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"} /> до <TextInlineLink
                        name={"bs_planet_agathon"}
                        text={"Агатона"} /> через
                    Этурию не самый короткий, зато очень удобно
                    разгрузить тут свои товары, чтобы отправить их по разным странам центра <TextInlineLink
                        name={"bs_maps_oecumene"} text={"ойкумены"} /> с минимальными затратами на логистику.
                </p>
                <p className="card-text text-justify">
                    Республика появилась на карте сразу после <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Галактической"} />, когда ослабевшие
                    земляне были не в силах
                    удержать свои прежние завоевания. Сами этурийцы перешли на сторону <TextInlineLink
                        name={"bs_planet_agathon"} text={"Агатона"} /> еще до начала войны и
                    активно выступали за отделение от Монархии. За это земной флот неоднократно устраивал облавы на
                    этурийских бизнесменов и нарушал логистику торговых путей. Военную силу тогда старались не применять
                    из-за близости агатонских территорий.
                </p>
                <p className="card-text text-justify">
                    Получив независимость, Этурия расцвела за счет множества <TextInlineLink name={"bs_tech_hyperrun"}
                        text={"торговых путей"} />,
                    идущих через нее: начиная с небольших (как Киренайский путь) и заканчивая трассами галактического
                    масштаба (Земля-Агатон-Тернерианский предел).
                </p>
                <p className="card-text text-justify">
                    На 1 год <TextInlineLink name={"bs_timeline"} text={"ДТ"} /> Этурийская республика считалась эталоном
                    пост-колониального государства, сумевшего
                    сбросить оковы монархизма.
                </p>
            </>
    }
}

