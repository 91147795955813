import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function War_300() {
    document.title = "Трехсотлетняя война || Кидонианка и Кидонианское солнце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Трехсотлетняя война"} />

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Трехсотлетняя война</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_war_300"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right" /> <strong>Даты: </strong>
                                            <span>~2000-1700 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right" /> <strong>Место:</strong>
                                            <span><TextInlineLink name={"bs_space_brienne_cluster"}
                                                text={"Кластер Бриэнн"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right" /> <strong>Участники:</strong>
                                    <span><TextInlineLink name={"bs_state_cydonian_priority"} />, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"} /></span>
                                </li>
                            </ul>
                            <p><strong>Связанные
                                статьи:</strong> <>{GetConnections("bs_timeline_war_300").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">История</h3>
                    <p className='card-text text-justify'><span className='strong'>Трехсотлетняя война</span> – очень
                        древний конфилкт между <TextInlineLink name={"bs_planet_earth"}
                            text={"Землей"} /> и <TextInlineLink
                            name={"bs_planet_cydonia"} text={"Кидонией"} />. На тот момент земная армия могла бы
                        сломить сопротивление кидонианцев за несколько недель, но из-за несовершенства и дороговизны
                        светового транспорта отправить сразу и много солдат было невозможно. Поэтому бойцы отправлялись
                        на передовую небольшими группами, проводя десятки лет в анабиозе.</p><p
                            className='card-text text-justify'>Многих деморализовывало такое положение вещей, поэтому на той
                        стороне бои длились не дольше пары дней, после чего десант принимал сторону кидонианцев. Были даже
                        целые отряды, которые пошли в армию именно с целью получить бесплатный транспорт в далекую колонию и
                        там дезертировать.</p><p className='card-text text-justify'>На Земле слабо понимали положение вещей,
                            поэтому раз в десять-двадцать лет поднималась новая волна патриотических настроений, которую ловко
                            использовали ушлые чиновники, любящие осваивать военный бюджет. Они обещали скорую победу с помощью
                            новейшего оружия, сулили богатства от новоприобретенных земель и на фоне этого незаметно богатели.
                            Некоторые даже сами умудрялись отправиться с очередной группой высадки и, так сказать, пропасть без
                            вести в бескрайних кидонианских лесах.</p><p className='card-text text-justify'>Все это было
                                возможно по одной простой причине: мало кто знает, что завоевывать планеты очень дорого, а доставка
                                ресурсов с них обойдется еще дороже завоевания. Но когда нужно сплотить народ и отвлечь его от
                                загрязнения земной атмосферы, проблем с перенаселением и отсутствием качественной еды, идея показать
                                всей галактике свою мощь служит отличным громоотводом от гражданского недовольства.</p><p
                                    className='card-text text-justify'>«Вот сейчас завоюем Кидонию, установим там дружественный режим и
                        как заживем!»</p><p className='card-text text-justify'>Сегодня кидонианцы отмечают дату окончания
                            Трехсотлетней войны как День независимости или <TextInlineLink name={"bs_universe_doab"}
                                text={"День всех бомб"} />.</p>
                </div>
            </div>
        </>
    )
}
