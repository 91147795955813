import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Republic_of_tiara_minor() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "form": <span>парламентская республика</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонский терос"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"}
            text={"земной"} /></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sectors": <span>1</span>,
        "worlds": <span>14 (1 метрополия)</span>,
        "people": <span>агатонцы</span>,
        "imgs": [{ "short": "bs_wiki_republic_of_tiara_minor_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Республика Малой короны</span>
                    – небольшая страна на окраине Туманности Малой короны со столицей на Агатоне. Из
                    всех <TextInlineLink name={"bs_state_urtm"} text={"Объединенных"} /> самая большая и важная.
                    Считается главным
                    оплотом
                    республиканского строя в галактике.
                </p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p className="card-text text-justify"><span
                    className="strong">Терос</span> – агатонская валюта, ходит по всему
                    <TextInlineLink name={"bs_space_democratic_space"} text={"Демократическому космосу"} />, официально
                    принимается по всей галактике и считается резервной во многих государствах (не только с
                    республиканской формой правления). Одна из самых стабильных валют в галактике.</p>
            </>
    }
}

