import { TextInlineLink, TextPopup } from "../../../components/cms/wiki_functions";
import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { Timeline } from 'antd';

export function BlackSunTimelinePage() {
    document.title = "История мира || Кидонианка и Кидонианское солнце"
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-clock-rotate-left" />  История мира</span>} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <h2 className="title-bg">История мира</h2>
                    </div>
                    <section className="resume">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 id={"high-light-travel-times"} className="title-bg">Высокая <TextInlineLink
                                        name={"bs_timeline_light_travel_times"} text={"световая эпоха"} /><small>~2800 -
                                            0 лет до <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Таманрасетт"} /></small></h5>
                                    <blockquote>
                                        <p className="card-text text-justify">
                                            «ПТ» и «ДТ». «После Таманрасетт» и «До Таманрасетт»... Почему историю
                                            человечества принято делить на две неравные части? И что такое эта
                                            Таманрасетт? Или кто? Давайте начнем с самого важного:
                                            Таманрасетт была (да и сейчас есть) планетой глубоко в Тернерианском
                                            пределе. И события, которые однажды
                                            случились на ее орбите, разделили наш мир на до и после. В одну секунду ты
                                            живешь в простой и понятной галактике,
                                            а в другую... впрочем, вы и без меня знаете, в какой галактике мы теперь
                                            живем. Стало ли лучше? Пожалуй, с этим вопросом я опоздала лет на 700.
                                        </p>
                                        <small>Из лекций профессора истории Мириам Тарсон, 839 г. ПТ</small>
                                    </blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>11.5 месяцев</strong>,
                                                children: (
                                                    <>
                                                        <p>Текущий момент (<TextInlineLink
                                                            name={"book_cydonian_autumn"} text={"«Кидонианская осень»"} />, Часть I:
                                                            День всех бомб)</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">14 - 11.5 месяцев <TextInlineLink
                                        name={"book_tamanrasett_contact"} text={"ДТ"} /> <small>(Революция в
                                            Монархии, <TextInlineLink name={"book_cydonian_girl"}
                                                text={"«Кидонианка»"} />, <TextInlineLink
                                                name={"book_hyperbeacons"} />)</small></h6>

                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>11.5 месяцев:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-lock" />,
                                                children: (
                                                    <>Трибунал отправляет <TextInlineLink
                                                        name={"bs_char_esora_eve"} text={"Еву Эсору"} /> на каторгу
                                                        на <TextInlineLink name={"bs_planet_alkiviad"} text={"Алкивиад"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>12 месяцев:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <p>первый официально <TextInlineLink
                                                            name={"bs_timeline_tajbennae_incident"}
                                                            text={"подтвержденный контакт"} /> людей (<TextInlineLink
                                                                name={"bs_spec_humans"} text={"Homo sapiens"} />) и
                                                            витрувианов <TextInlineLink name={"bs_spec_vitruvianus"}
                                                                text={"(Homo vitruvianus)"} /> (<TextInlineLink
                                                                name={"book_hyperbeacons"}
                                                                text={"«Похитители маяков»"} />).<br /><TextInlineLink
                                                                name={"bs_char_the_whip"} text={"Бич"} /> получает доступ
                                                            к <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"}
                                                                text={"Северной звезде Нираданхи"} /> и находит нового
                                                            союзника в борьбе с <TextInlineLink name={"bs_char_shimmering_devil"}
                                                                text={"Мерцающим дьяволом"} /></p>
                                                        <p>
                                                            признание независимости Михъельма.
                                                            Конец <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                text={"революции в Монархии"} /> (<TextInlineLink
                                                                name={"book_cydonian_girl"} text={"«Кидонианка»"} />)
                                                        </p>
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>13 месяцев:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>массовое исчезновение
                                                        жителей <TextInlineLink name={"bs_planet_exile_1"}
                                                            text={"Последнего Исхода"} /> (<TextInlineLink
                                                            name={"book_hyperbeacons"} text={"«Похитители маяков»"} />)
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>14 месяцев:</strong>,
                                                children: (
                                                    <>
                                                        <p>
                                                            Начало <TextInlineLink
                                                                name={"bs_timeline_the_shark_revolt"}
                                                                text={"революции в Монархии"} /> (<TextInlineLink
                                                                name={"book_cydonian_girl"} text={"«Кидонианка»"} />)
                                                        </p>
                                                        <p>
                                                            Встреча <TextInlineLink
                                                                name={"bs_char_de_levine_rosalie"} text={"Розали"} /> и <TextInlineLink
                                                                name={"bs_char_de_karma_ivar"} text={"де Кармы"} />
                                                        </p>
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">25 лет - 14 месяцев <TextInlineLink
                                        name={"bs_timeline"} text={"ДТ"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>3 года</strong>,
                                                dot: <i className="fas fa-book" />,
                                                color: 'grey',
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"} /> завершает 4-е издание <TextInlineLink
                                                            name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                            text={"«Путеводителя по Млечному Пути для перелетных птиц»"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>7.5 лет</strong>,
                                                children: (
                                                    <>
                                                        К <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} text={"Ивару"} /> и <TextInlineLink
                                                            name={"bs_char_browdy_adam"}
                                                            text={"Адаму"} /> присоединяется <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"} text={"Гэри Бьекен"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>10 лет</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> нанимает <TextInlineLink
                                                            name={"bs_char_browdy_adam"} text={"Адама Броуди"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>11 лет</strong>,
                                                color: 'grey',
                                                dot: <i className="fas fa-book" />,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"} /> выпускает первую версию <TextInlineLink
                                                            name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                            text={"«Путеводителя по Млечному Пути для перелетных птиц»"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>13 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <TextInlineLink name={"bs_planet_tiora"}
                                                            text={"Тиорийский инцидент"} />, <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> уволен с позором из кидонианского
                                                        флота. <br />Он становится фрилансером в команде <TextInlineLink
                                                            name={"bs_char_lenisaad_victor"} text={"Виктора Ленисаада"} />, тем
                                                        самым примыкая к <TextInlineLink name={"bs_comp_free_trade_league"}
                                                            text={"Вольным торговцам"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>14 лет</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> – один из самых молодых адмиралов
                                                        кидонианского флота
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>19 лет</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_van_gloria_adel"} text={"Адель ван Глория"} /> избрана
                                                        королевой Приоритета после смещения <TextPopup
                                                            name={"bs_char_ivetta_sheridan"} text={"Иветты Шеридан"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>19 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        Война <TextInlineLink
                                                            name={"bs_state_cydonian_priority"}
                                                            text={"Приоритета"} /> и <TextInlineLink
                                                            name={"bs_comp_feathered_serpent"}
                                                            text={"Пернатого змея"} /> за <TextInlineLink
                                                            name={"bs_space_samborian_traverse"} text={"Самборианский траверс"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>24 года</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"}
                                                            text={"Гэри Бьекен"} /> присоединяется к <TextInlineLink
                                                            name={"bs_comp_bonecollectors"} text={"Собирателям костей"} />
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">37 лет - 25 лет <TextInlineLink name={"bs_timeline"}
                                        text={"ДТ"} /> - <TextInlineLink
                                            name={"bs_timeline_galactic_war"} text={"Первая Галактическая война"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>25 лет</strong>,
                                                color: 'green',
                                                children: (
                                                    <>
                                                        <p>Конец <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(Первой) Галактической войны"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>26 лет</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        <p>Родилась <TextInlineLink
                                                            name={"bs_char_de_levine_rosalie"} text={"Розалия де Левин"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>26 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <p><TextInlineLink name={"bs_ships_nyorun_dreams"}
                                                            text={"«Грёзы Ньёрун»"} /> уничтожает
                                                            биосферу на <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"}
                                                                text={"Мертвом Исгариоте"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>38 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <p>Начало <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(Первой) Галактической войны"} /></p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h6 className="title-bg">~2800 - 37 лет <TextInlineLink name={"bs_timeline"}
                                        text={"ДТ"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>41 год</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родилась <TextInlineLink
                                                            name={"bs_char_esora_eve"} text={"Ева Эсора"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>46 лет</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родился <TextInlineLink
                                                            name={"bs_char_browdy_adam"} text={"Адам Броуди"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>54 года</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родился <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"} text={"Гэри Бьекен"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>60 лет</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родилась <TextInlineLink
                                                            name={"bs_char_skarsgard_vivien"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>62 года</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родился <TextInlineLink
                                                            name={"bs_char_skarsgard_oliver"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>68 лет</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родилась <TextInlineLink
                                                            name={"bs_char_ursula_adams"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>71 год</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родилась <TextInlineLink
                                                            name={"bs_char_van_gloria_adel"} text={"Адель ван Глория"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>73 года</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user" />,
                                                children: (
                                                    <>
                                                        Родился <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>1200 лет</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe" />,
                                                children: (
                                                    <>
                                                        Заселен <TextInlineLink
                                                            name={"bs_planet_avvaline"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>1823 года</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_ackron_conspiracy"}
                                                            text={"Акронский заговор"} /> привел к власти в <TextInlineLink
                                                            name={"bs_state_ajliraenian_domination"} text={"Доминации"} /> династию
                                                        Яветов
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2000-1700 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_war_300"} text={"Трехсотлетняя война"} /> между
                                                        землянами и кидонианцами
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2100 лет</strong>,
                                                children: (
                                                    <>
                                                        Первые упоминания <TextInlineLink
                                                            name={"bs_planet_arville"} text={"Арвилль"} /> в журналах <TextInlineLink
                                                            name={"bs_tech_hiveship"} text={"кораблей-ульев"} /> <TextInlineLink
                                                            name={"bs_people_alamarsie"} text={"аламарси"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2800 лет</strong>,
                                                children: (
                                                    <>
                                                        Первый задокументированный
                                                        контакт <TextInlineLink name={"bs_people_alamarsie"}
                                                            text={"аламарси"} /> с <TextInlineLink
                                                            name={"bs_char_the_whip"} text={"Бичом"} />
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h5 id={"light-era-dawn"} className="title-bg">Заря Световой эпохи <small>~5500 –
                                        ~2800 лет до <TextInlineLink name={"book_tamanrasett_contact"}
                                            text={"Таманрасетт"} /></small></h5>

                                    <div className="">
                                        <h6 id={"late-colonies-time"} className="title-bg">~4800 – ~2800 лет
                                            до <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Таманрасетт"} /> - Время поздних колоний</h6>
                                        <blockquote><p className="card-text text-justify">
                                            Называть давно ушедшую эпоху временем ПОЗДНИХ колоний – это сущее безумие.
                                            Ведь как тогда назвать планету, заселенную вчера? Суперпоздняя колония?
                                            Позднейшая? Хотя разумеется я понимаю, о чем идет речь: колониями принято
                                            считать далекие миры, к которым тяжело добраться. В наше время, да и в
                                            Высокую эпоху, такой проблемы уже не было.
                                            Поздравляю, теперь и вы понимаете смысл названия. Хотя от этого оно не стало
                                            менее странным, правда?
                                        </p><small>Из лекций профессора истории Мириам Тарсон, 887 г. ПТ</small>
                                        </blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label: <strong>~3600 лет</strong>,
                                                    children: (
                                                        <>
                                                            <p>Автоматические терраформеры начали
                                                                превращение <TextInlineLink name={"bs_planet_tamanrasett"}
                                                                    text={"Таманрасетт"} /> в планету с
                                                                землеподобной биосферой</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~3800 лет</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe" />,
                                                    children: (
                                                        <>
                                                            <p>Земные колонисты основали независимое
                                                                поселение на <TextInlineLink name={"bs_planet_agathon"}
                                                                    text={"Агатоне"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4000 лет</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_alkanarra_tristan"}
                                                                text={"Тристан Завоеватель"} /> объединил большую часть земных колоний
                                                                в <TextInlineLink name={"bs_space_the_old_space"}
                                                                    text={"Старом космосе"} /> в первое <TextInlineLink
                                                                    name={"bs_state_the_great_monarchy"}
                                                                    text={"полноценное межзвездное государство"} />. Потратив на это
                                                                целую жизнь, он умер в возрасте почти 200 лет. Его потомки
                                                                в ходе гражданской войны обретают неограниченную власть над Землей.
                                                                Начинается процесс трансформации относительно либерального общества
                                                                Земли в деспотичное государство, позже известное как Великая Монархия
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4500 лет</strong>,
                                                    children: (
                                                        <>
                                                            <p>Изобретен первый прототип современной (не
                                                                артурианской) <TextInlineLink name={"bs_tech_grn"} text={"ИнтерСети"} />,
                                                                хотя до полноценной общегалактической связи еще очень далеко</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4730 лет</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe" />,
                                                    children: (
                                                        <>
                                                            <p>Заселена <TextInlineLink
                                                                name={"bs_planet_helena"} text={"Хелена-у-Тэллаусы"} />
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4750 лет</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe" />,
                                                    children: (
                                                        <>
                                                            <p>Заселен <TextInlineLink
                                                                name={"bs_planet_ajliraen"} /></p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className="">
                                        <h6 id={"first-colonies-time"} className="title-bg">~5500 – ~4800 лет
                                            до <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Таманрасетт"} /> - Время первых колоний</h6>
                                        <blockquote><p className="card-text text-justify">
                                            Этот период истории начинается с изобретения первого стабильного светового
                                            двигателя. Человечество
                                            вступает в эпоху активной экспансии, но технологии все еще слишком
                                            несовершенны и заселение новых миров
                                            идет крайне медленно, зачастую трагично. Концом Времени первых колоний
                                            принято считать заселение Кидонии:
                                            в этот момент экспансия достигла масштабов и скорости, которые позволили
                                            Homo sapiens начать
                                            настоящее покорение галактики.
                                        </p><small>Юлиан Харари, «Homo sapiens: правдивая история выходцев со Старой
                                            Земли», 265 г. ПТ</small></blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label: <strong>~4800 лет</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe" />,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_brienne_de_estrada"} text={"Бриэнна де Эстрада"} /> довела
                                                                потрепанный колонизационный корабль до Кидонии, но первая попытка
                                                                построить аванпост убила почти все население. Новоиспеченные кидонианцы
                                                                просят помощи у <TextInlineLink name={"bs_people_alamarsie"}
                                                                    text={"аламарси"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4900 лет</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_arthur_the_founder"}
                                                                text={"Артур Основатель"} /> объединяет <TextInlineLink
                                                                    name={"bs_people_alamarsie"} text={"аламарси"} /> и вскоре исчезает при
                                                                загадочных обстоятельствах</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~5100 лет</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe" />,
                                                    children: (
                                                        <>

                                                            <p>Неизвестные переселенцы добираются на
                                                                досветовой скорости с <TextInlineLink name={"bs_planet_earth"}
                                                                    text={"Земли"} /> на <TextInlineLink
                                                                    name={"bs_planet_esghariot_en_nuvnish"} text={"Мертвый Исгариот"} />,
                                                                основывая <TextInlineLink name={"bs_space_breeze_sector"}
                                                                    text={"Колонию Бриз"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~5500 лет</strong>,
                                                    children: (
                                                        <>
                                                            <p>Предположительно изобретен
                                                                первый <TextInlineLink name={"bs_tech_engine_overlight"}
                                                                    text={"световой двигатель"} />, примерное
                                                                начало <TextInlineLink name={"bs_timeline_light_travel_times"}
                                                                    text={"Эпохи сверхсветовых перелетов"} /></p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <h5 id={"underlight-age"} className="title-bg">Досветовая эпоха <small>0 - ~5500лет
                                        до <TextInlineLink name={"book_tamanrasett_contact"}
                                            text={"Таманрасетт"} /></small></h5>
                                    <blockquote><p className="card-text text-justify">
                                        Досветовая эпоха – длинный промежуток времени. В нее умещается все, что
                                        произошло на Старой Земле с момента появления людей как вида
                                        и до дня, когда первый человек, рискуя всем, разогнался быстрее света. Воистину,
                                        это была эпоха безумцев и смельчаков, готовых
                                        на все ради открытия новых горизонтов. Жаль, что большая часть знаний о событиях
                                        тех времен навсегда утеряна в
                                        пламени многочисленных катаклизмов, пережитых нашей прародиной.
                                    </p><small>Юлиан Харари, «Homo sapiens: правдивая история выходцев со Старой Земли»,
                                        265 г. ПТ</small></blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>~5600 лет</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe" />,
                                                children: (
                                                    <>
                                                        <p>Основано поселение на <TextInlineLink
                                                            name={"bs_planet_michelm"} text={"Михъельме"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~5800 лет</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe" />,
                                                children: (
                                                    <>
                                                        <p>Переселенцы с <TextInlineLink
                                                            name={"bs_planet_earth"} text={"Земли"} /> основывают колонию
                                                            в <TextInlineLink name={"bs_planet_sleeping_suns_maison"}
                                                                text={"Поместье Спящих солнц"} />, на Новой Патагонии и
                                                            Эллате</p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~6000 лет</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt" />,
                                                children: (
                                                    <>
                                                        <p>Некто прозванный <TextInlineLink
                                                            name={"bs_char_shimmering_devil"}
                                                            text={"Мерцающим дьяволом"} /> уничтожает цивилизацию <TextInlineLink
                                                                name={"bs_char_the_whip"} text={"Бича"} /> с помощью <TextInlineLink
                                                                name={"bs_weaponry_the_black_sun"} text={"Черного солнца"} />. Само
                                                            солнце попадает в руки Бича</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </div>
    )
}
