import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Plato() {
    return {
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>гражданское <TextInlineLink name={"bs_ships_ship_classes"} text={"инженерное судно"} /></span>,
        "type": <span><TextInlineLink name={"bs_ships_redeyed"} /></span>,
        "size": <>800x140x60м</>,
        "defence": <span>противоастероидная броня из <TextInlineLink name={"bs_tech_space_steel"}
            text={"корабельной стали"} />,
            гасители помех, клетка Фарадея</span>,
        "text":
            <>
                <h3 className="title-bg">О корабле</h3>
                <div style={{ "maxWidth": "320px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                    <img style={{ "maxWidth": "300px" }} alt="«Платон»" src={imgs["bs_ship_plato"].link} fetchpriority="low" />
                </div>
                <p className="card-text text-justify"><span className="strong">ИРФ-1121.07 «Платон»</span> –
                    корабль, на котором <TextInlineLink name={"bs_char_ursula_adams"} text={"Урсула Адамс"} /> провела
                    большую часть детства.
                    Принадлежал частной фирме, в которой работали ее родители. Машина сдавалась в аренду вместе с
                    экипажем для
                    разведки планетоидов в <TextInlineLink name={"bs_space_vikoveera"}
                        text={"Виковире"} />, <TextInlineLink
                        name={"bs_space_breeze_sector"} text={"Бризе"} /> и соседних секторах.
                </p>
            </>
    }
}
