import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Javet_erbees() {
    return {
        "name": "Эрбесар Явет",
        "born": <span>44 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "age": <span>43</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлирэн"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"} /></span>,
        "fullname": <span>Эрбесар Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"} /></span>,
        "sister": <span><TextInlineLink name={"bs_char_javet_eijenora"} /></span>,
        "work": <span>Второй наследный принц <TextInlineLink name={"bs_state_ajliraenian_domination"}
            text={"Айлирэнской Доминации"} /></span>,
        "imgs": [
            { "short": "bs_char_javet_erbees_1_jpg_500", "big": "bs_char_javet_erbees_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Эрбесар</span> – младший из наследников Бейсариона Явета на престол <
                        TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Айлирэнской Доминации"} />. Всю
                    жизнь он наблюдал за старшей сестрой, Эльсинорой, и пытался следовать ее примеру.
                    С детства принц обучался менеджменту, экономике и многим другим дисциплинам, помогающим управлять
                    империей, но понимал: императором он вряд ли станет. Поэтому Эрбесар, чтобы не потерять
                    благосклонность
                    семьи, принял решение во всем помогать старшей сестре и стать ее верным протеже.
                </p>
                <p className="card-text text-justify">
                    Официально у него не было должности при дворе и мало кто вообще ждал от младшего принца заметных
                    свершений. Эрбесар в основном занимался поручениями императора и принцессы, выполняя для них разную
                    административную работу. Его не признали годным для престола даже после того, как Эльсинора
                    дважды впала в немилось и, по словам отца, открыто предала интересы трона и всю Доминацию.
                </p>
            </>
    }
}
