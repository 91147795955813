import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function The_great_monarchy() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "form": <span>конституционная монархия</span>,
        "currency": <span><TextInlineLink name={"bs_currency_imperium"} text={"земной империум"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"земной"} /></span>,
        "leader": <span>Монарх <TextInlineLink name={"bs_char_alkanarra_siel"} /></span>,
        "region": <TextInlineLink name={"bs_space_the_old_space"} />,
        "sectors": <span>6</span>,
        "worlds": <span>976 (32 <TextInlineLink name={"bs_universe_planet_types"} text={"метрополии"} />)</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"земляне"} />, михъельмцы и др.</span>,
        "imgs": [{ "short": "bs_wiki_the_great_monarchy_map_ru", "big": "bs_wiki_oecumene_map_ru" }],
        "text":
            <>

                <p className='card-text text-justify'>
                    Стоит оговориться: данные выше указаны на момент начала <TextInlineLink
                        name={"bs_timeline_the_shark_revolt"}
                        text={"Михъельмского восстания"} />, поскольку
                    на момент его конца никаких точных цифр о Монархии сказать уже было нельзя.
                </p>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'>
                    Государство, прозванное в будущем Великой Монархией, начало свою историю еще до световых перелетов.
                    На тот момент оно представляло из себя федерацию полузависимых планет, разбросанных по Старому
                    космосу
                    в пределах <TextInlineLink name={"bs_space_sector_zero"} text={"Нулевого"} /> и соседних секторов.
                    Земля не имела полного контроля над ними, поскольку
                    не существовало ни достаточно быстрого транспорта, ни связи. Новости приходили с опозданием на
                    десятилетия,
                    а в некоторые места и вовсе на целые века.
                </p>
                <p className='card-text text-justify'>
                    В таких условиях множество миров навсегда теряли контакт с прародиной человечества, но некоторые все
                    же
                    создавали торговые маршруты, чтобы обмениваться ценными находками и технологиями. Так появилась
                    людская <TextInlineLink name={"bs_maps_oecumene"} /> под эгидой еще существовавшего, пусть и сильно
                    реформированного ООН.
                </p>
                <p className='card-text text-justify'>
                    Со временем связи укреплялись и земляне начинали требовать большего контроля, а самое главное -
                    ресурсов.
                    Родина человечества постепенно погрязала в мусоре, бетоне и трущобах. Экономика приходила в упадок и
                    многие видели решение всех проблем в других мирах. Но не в переселении туда (что было невозможно для
                    десятков миллиардов людей), а в потоке материалов и сублимированной еды. Простая логика
                    подсказывала,
                    что
                    если все колонии будут каждый год отправлять на Землю по кораблю с ресурсами, то через несколько
                    десятилетий
                    поток станет стабильным и будет только возрастать. Таким образом земляне смогут питаться более
                    качественно
                    и выделять ресурсы под расчистку планеты от свалок, создание новых колоний и другие полезные дела.
                </p>
                <p className='card-text text-justify'>
                    Нетрудно догадаться, что сами колонии не желали становиться сырьевыми придатками для далекого мира,
                    который новые поколения колонистов видели только на картинках. Тем более что в колониях хватало
                    своих проблем, уникальных для каждой планеты.
                    Одна за одной, они обрывали тонкие
                    ниточки межпланетной связи и космос стал глух к требованиям земных правителей.
                </p>
                <p className='card-text text-justify'>
                    Это спровоцировало череду гражданских войн между рудиментарными земными государствами и привело к
                    власти
                    диктаторов-милитаристов, которые подмяли под себя большую часть планеты. Они объявили колонистов
                    предателями и собирались вернуть «свое» силой. Потребовалось много времени, но консолидированные
                    вокруг
                    идеи реваншизма земляне сумели создать достаточно эффективный световой двигатель <TextInlineLink
                        name={"bs_tech_engine_overlight"} text={"эйнштейновского"} /> типа
                    (старую добрую алькубьерку). С этого момента (ок. 5500 <TextInlineLink name={"bs_timeline"}
                        text={"ДТ"} />) и ведется
                    отсчет <TextInlineLink name={"bs_timeline_light_travel_times"} text={"Световой эпохи"} />,
                    поскольку
                    предыдущие
                    прототипы двигателей веками лишь убивали своих создателей или были просто неэффективными.
                </p>
                <p className='card-text text-justify'>
                    Так агрессия и милитаризм неожиданно дали толчок развитию человечества. И начали череду войн в
                    Старом
                    космосе, которая не только принесла много горя галактике, но и распространила световые двигатели
                    по всем людским колониям.
                </p>
                <p className='card-text text-justify'>
                    Следующие века прошли под знаком вялотекущих конфликтов, которые периодически переходили в острую
                    фазу.
                    Лишь <TextInlineLink name={"bs_char_alkanarra_tristan"} />, прозванный Завоеателем, сумел возвести
                    межзвездное насилие на абсолютно
                    новый уровень.
                    Он пришел к власти около 4200 года ДТ на фоне новых земных междоусобиц. Пришел не просто так, а с
                    планом,
                    надежным, как этурийские часы. Земле срочно требовалось спасение от перенаселения и угробленной
                    экологии,
                    которую не могли восстановить даже самые продвинутые технологии (а по меркам какого-нибудь 21го века
                    -
                    вообще
                    божественные).
                </p>
                <p className='card-text text-justify'>
                    План Тристана состоял в создании крупного быстрого флота, оснащенного по последнему слову техники
                    и нового рода войск: космопехоты. Лучших земных бойцов научили десантироваться прямо с орбиты и
                    вооружили
                    ручными рельсотронами - пушками, которых в колониях еще не было.
                </p>
                <p className='card-text text-justify'>
                    Пользуясь преимуществом более быстрых световых двигателей, недавно изобретенных на Земле, Тристан
                    начал проводить молниеносные дерзкие операции и брать под контроль целые планеты за считанные годы.
                    Ближайшие колонии не были способны сопротивляться противнику, который успевал начать и закончить
                    вторжение быстрее, чем весть об этом дойдет до других миров.
                </p>
                <p className='card-text text-justify'>
                    Так Тристан сумел установить земной флаг на десятках миров и сотнях более мелких планетоидов, но
                    сил выбраться за пределы Старого космоса ему не хватило: такие миры как Кидония и Айлирэн были в
                    полной безопасности.
                </p>
                <p className='card-text text-justify'>
                    Несмотря на успех новой тактики и продвинутого оружия, захватить планету оказалось легче, чем
                    удержать. В те времена полное подчинение мира было долгим, дорогим,
                    и довольно скучным – только истинные фанатики своего дела могли <span
                        className='strike'>дожить</span> дождаться завершения.
                    Поэтому Тристан не занимался полной оккупацией и ассимиляцией, а лишь показывал, что будет, если
                    сопротивляться землянам. Его требованием была регулярная поставка ресурсов и пищи на Землю. Взамен
                    жители прародины обещали не совершать новых налетов. По сути, Тристан занимался банальным рэкетом.
                    Он делал это, понимая, что в галактике нет силы, сопоставимой с Землей: нищие жители перенаселенных
                    трущоб с
                    радостью отправлялись на войну просто ради солдатского пайка. Им было плевать, где и в кого
                    стрелять. Противостояли же им малонаселенные колонии, в которых люди стремились к образованию,
                    саморазвитию и максимальному комфорту - мало кто готовился защищать свой мир от налета
                    превосходящего
                    противника и еще меньше были готовы делать это годами.
                </p>
                <p className='card-text text-justify'>
                    Со временем Тристану удалось создать единое, пусть и очень размытое, государственное образование. Он
                    умер в
                    возрасте почти 200 лет и не успел увидеть подлинного расцвета своей империи. На тот момент она была
                    лишь сборищем автономий, которые вяло подчинялись центру и отправляли туда некоторое количество
                    ресурсов. Реальные формы правления и титулы правителей разнились от планеты к
                    планете, а некоторые
                    местные руководители даже не всегда понимали, кому именно подчиняются и что там вообще происходит на
                    этой вашей Земле.
                </p>
                <p className='card-text text-justify'>
                    Подобный порядок сохранялся очень
                    долго из-за несовершенства технологий перемещения и больших проблем с акклиматизацией в новых мирах,
                    которая портила солдатам здоровье и боевой дух.
                </p>
                <p className='card-text text-justify'>
                    Тем не менее, землянам понравилось чувствовать себя жителями столицы целой межзвездной империи. Они
                    поддержали потомков Тристана и позволили им взять в свои руки почти неограниченную власть. И в
                    течение следующих веков она лишь росла и укреплялась. Вместе с ней укреплялись и межпланетные связи:
                    Земля тратила колоссальные ресурсы в совершенствование световых двигателей и оружия, превращаясь во
                    все более деспотичную диктатуру. В какой-то момент ее мощь и власть стали столь высоки, что потомки
                    Тристана, семья Алканарра, объявили себя самым настоящими Монархами, а Землю - столицей Монархии. И
                    не обычной, а Великой, поскольку раньше никто не создавал полноценной межзвездной империи.
                </p>
                <p className='card-text text-justify'>
                    Нетрудно догадаться, что после этого войны лишь разгорелись с новой силой: Земля
                    сражалась с <TextInlineLink name={"bs_planet_agathon"} text={"Агатоном"} />, <TextInlineLink
                        name={"bs_planet_cydonia"} text={"Кидонией"} />, <TextInlineLink
                        name={"bs_planet_ajliraen"} text={"Айлирэном"} /> и множеством других миров, упорно не желавших
                    признавать власть прародины людей.
                    Эти сражения не были столь успешными, как покорение Старого космоса: расстояние оказалось слишком
                    большим,
                    а число бунтующих колоний год от года росло. В какой-то момент ресурсы даже такой гигантской империи
                    стали заканчиваться и чем меньше их было, тем сильнее бунтовали уже близлежащие планеты.
                </p>
                <p className='card-text text-justify'>
                    Нестабильность привела к тому, что около 1700 года ДТ земляне проиграли <TextInlineLink
                        name={"bs_timeline_war_300"} text={"Трехсотлетнюю войну"} /> кидонианцам
                    и после этого окончательно отказались от любых попыток выйти за пределы Старого космоса.
                </p>
                <p className='card-text text-justify'>
                    Первым крупным конфликтом, в который с тех пор встряла Земля, была <TextInlineLink
                        name={"bs_timeline_galactic_war"} text={"Галактическая"} />. Эта война
                    запустила
                    процесс распада построенного династией Алканарра общества и привела сначала к колоссальным
                    территориальным
                    потерям, а затем и ко <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                        text={"Второму Михъельмскому восстанию"} />. Именно оно
                    добило иссохшую империю.
                </p>
                <p className='card-text text-justify'>
                    Восстание вспыхнуло в секторе Акулы примерно за 14 месяцев ДТ (во время «Кидонианки») и за считанные
                    дни превратилось в
                    тотальный демарш по всей стране. Безумно жестокие репрессии землян не помогли и всего за несколько
                    недель Великая Монархия развалилась.
                </p>
                <p className='card-text text-justify'>
                    «Кидонианка» закончилась тем, что <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Сиель"} />,
                    последний из Алканарра, отрекся от престола и позволил
                    всем мирам самостоятельно решать свою судьбу. С тех пор (12 месяцев ДТ) Великая Монархия перестала
                    существовать.
                </p>
                <h3 className="title-bg">Интересные факты</h3>
                <p className='card-text text-justify'>
                    Армия Монархии разделялась на несколько независимых подразделений, называемых Эшелонами (с большой
                    буквы). В последние дни страны существовали Паучий Эшелон сектора Акулы, <TextPopup
                        name={"bs_popups_army_echelon_of_the_fives"} /> разбросанный по стране в качестве военной полиции,
                    Ночной и Рассветный Эшелоны,
                    Нулевой Эшелон для обороны Земли, Эшелон Кита на границе с Демкосмосом и Эшелон Цефея у айлирэнских
                    границ.
                </p>
                <p className="card-text text-justify">
                    Откуда взялось такое название доподлинно неизвестно. Самая правдоподобная теория гласит, что
                    из-за проблем с навигацией на заре световых времен, армии отправлялись на театр военных действий
                    не армадой, а вереницей кораблей. Они формировали подобие поезда, двигаясь по следам идущих впереди
                    машин, чтобы не сбиться с курса. В противном случае существовал большой шанс потерять направление и
                    опоздать на поле боя на недели, а то и месяцы.
                </p>
                <p className="card-text text-justify">
                    Тогда военные флоты готовились всего для одного или нескольких сражений, поскольку полет был долгим,
                    а война за планету вообще могла идти много лет. Поэтому им давались имена сообразно тому, куда
                    Эшелон отправляется. Так появились Пауки, Скорпионы, Лебеди и множество других формирований, из
                    которых лишь единицы дожили до заката построенной ими империи.
                </p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p
                    className="card-text text-justify"><span className="strong">Земной империум</span> или <span
                        className="strong">нулевой</span> (в честь Нулевого солнца) –
                    валюта Великой Монархии, а затем
                    и нового земного государства, пришедшего ей на смену.
                    Очень
                    волатильный и
                    опасный актив. После Восстания Акулы обрушился больше чем в четыре раза.
                </p>
                <p className='card-text text-justify'>
                    Нулевой начал существовать еще в досветовые времена, как единое средство обмена между заселенными
                    звездными системами. Разумеется у него не было никакой привязки к золоту или другим вещам,
                    поскольку новые
                    миры содержали множество разного сверкающего барахла. Поэтому веками межзвездная валюта не
                    колебалась,
                    оставаясь стабильной единицей, и лишь местные валюты разных планет изменяли свой локальный курс по
                    отношению к нулевому.
                </p>
            </>
    }
}

