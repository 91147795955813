import { CrumbsUniverse } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Bird_of_passage_guide_to_milky_way } from "./bird_of_passage_guide_to_milky_way";
import { Gemini } from "./gemini";
import { Religion_arthurians } from "./religion_arthurians";
import { Planet_types } from "./planet_types";
import { Languages } from "./languages";
import { Doab } from "./doab";
import { Bs_univ_cydonian_autumn } from "./cydonian_autumn";
import { Currencies } from "./currencies";
import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import { Biome } from "./biome";
import { Nomad_syndrome } from "./nomad_syndrome";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "bird-of-passage-guide-to-milky-way": Bird_of_passage_guide_to_milky_way,
    "gemini": Gemini,
    "currencies": Currencies,
    "cydonian-autumn": Bs_univ_cydonian_autumn,
    "all-bombs-day": Doab,
    "languages": Languages,
    "planet-types": Planet_types,
    "religion-arthurians": Religion_arthurians,
    "biome": Biome,
    "nomad-syndrome": Nomad_syndrome,
}


/*
return {
        "top": "",
        "text":
            <>
            </>
    }
 */

export function Universe(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_world"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Кидонианское солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsUniverse name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h1>{name}</h1></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            {data["top"]}
                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}



































