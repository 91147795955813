import { CrumbsBlackSunMaps } from "../../../../components/Bread_Crumbs";
import { TextInlineLink } from "../../../../components/cms/wiki_functions";
import imgs from '../../../../components/cms/images_repo.json';

export function BlackSunWorldMapOecumene() {
    document.title = "Карта мира Кидонианки и Кидонианское солнца"
    return (
        <>
            <div className="row" key="crumbs">
                <CrumbsBlackSunMaps name={"Ойкумена Homo sapiens до Таманрасетт"} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12 well">
                    <p className="card-text text-justify"><strong>Ойкумена</strong> или <strong>Человеческая ойкумена</strong> – часть Вселенной, которую освоили для себя земляне
                        или их потомки (читай – люди). Некогда термин выдумали древние греки для описания знакомой им части мира,
                        и он удивительным образом подходит для этой же цели в рамках галактики.</p>
                    <p className="card-text text-justify">
                        Важнейшие государства <TextInlineLink name={"bs_timeline"} text={"этого периода истории"} />:
                        <ul>
                            <li><TextInlineLink name={"bs_state_galactic_democratic_alliance"} /></li>
                            <li><TextInlineLink name={"bs_state_ajliraenian_domination"} /></li>
                            <li><TextInlineLink name={"bs_state_cydonian_priority"} /></li>
                            <li><TextInlineLink name={"bs_state_free_worlds"} /></li>
                            <li><TextInlineLink name={"bs_state_the_great_monarchy"} text={<s>Великая Монархия</s>} /> и ее потомки, образовавшиеся после <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Восстания Акулы"} /></li>
                            <li><TextInlineLink name={"bs_state_tallesian_principality"} /></li>
                            <li><TextInlineLink name={"bs_state_urtm"} /></li>
                            <li><TextInlineLink name={"bs_state_aethurian_republic"} /></li>
                            <li><TextInlineLink name={"bs_space_breeze_sector"} text={"Республиканский протекторат Бриз"} /></li>
                            <li>Империя Элат</li>
                        </ul>

                    </p>
                </div>
            </div>
            <div className="row">
                <div style={{ "width": "100%", "backgroundImage": "url(" + imgs["bs_wiki_oecumene_map_ru"].link + ")" }}></div>
                <a data-caption="Ойкумена Homo sapiens до Таманрасетт" data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ru"].link}>
                    <img className="img-thumbnail-v2" alt="" src={imgs["bs_wiki_oecumene_map_ru"].link} />
                </a>
            </div>
        </>
    )
}